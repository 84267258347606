import { render, staticRenderFns } from "./ProductBrowser.vue?vue&type=template&id=368a4994&scoped=true&"
import script from "./ProductBrowser.vue?vue&type=script&lang=js&"
export * from "./ProductBrowser.vue?vue&type=script&lang=js&"
import style0 from "./ProductBrowser.vue?vue&type=style&index=0&id=368a4994&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368a4994",
  null
  
)

export default component.exports