<template>
  <el-form class="contiguous-booking">
    <inventory-query
      :filter="{
        sku: variant.sku
      }"
      @data="onInventory"
    />

    <!-- Booking Type Selection -->
    <custom-el-form-item
      v-if="allowedRateTypes.length > 1"
      label="Booking"
      label-class="font-semibold text-md"
      class="margin-bottom-xl"
      no-click-focus
    >
      <div class="margin-top-xs">
        <div class="flex -m-3">
          <el-radio-group v-model="selectedRateType" @change="change">
            <el-radio
              v-for="rateType in allowedRateTypes"
              :key="`type-${rateType.type}`"
              class="block p-3 radio-inline-block themed"
              :label="rateType"
              @change="selectedRateType = $event"
            >
              <div class="inline-block">
                <template v-if="rateType.type === RateType.IMPRESSIONS.value">
                  <div>By impressions (recommended)</div>
                  <div class="text-dark-silver font-size-msm margin-top-xs">
                    Cost per 1,000 (CPM) impressions:
                    {{ rateType.minPrice | currency }}
                  </div>
                </template>
                <template v-else>
                  <div>By time block</div>
                  <div class="text-dark-silver font-size-msm margin-top-xs">
                    Cost per day: {{ rateType.minPrice | currency }}
                  </div>
                </template>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </custom-el-form-item>

    <transition name="fadeDown">
      <div v-if="selectedRateType">
        <div class="booking-type mt-5">
          <transition-group name="fade" mode="out-in" class="animate-position">
            <!-- Booking By Impressions -->
            <div
              v-if="selectedRateType.type === RateType.IMPRESSIONS.value"
              :key="'booking-impressions'"
            >
              <custom-el-form-item
                label-class="font-semibold text-md"
                class="pad-top"
              >
                <template #label>
                  Number of Impressions (Booked by CPM)
                  <tooltip-icon info tooltip-while-enabled>
                    <template #enabled>
                      <div>
                        <div>
                          The number of thousands of impression you would like
                          to book.
                        </div>
                        <div>
                          For example, entering 10 here will order 10 CPMs or
                          10,000 impressions
                        </div>
                      </div>
                    </template>
                  </tooltip-icon>
                </template>
                <el-input
                  v-mask.number="{
                    allowEmpty: true,
                    newman: 'dan',
                    min: 0,
                    precision: 0
                  }"
                  :value="numberOfImpressions"
                  type="text"
                  class="w-32"
                  placeholder="0"
                  @input="changeImpressions"
                >
                  <template #suffix>,000</template>
                </el-input>
              </custom-el-form-item>
            </div>

            <!-- Booking Per Order -->
            <div
              v-if="skuInventory && hasImpressionsSelected"
              :key="'booking-inventory-calendar'"
            >
              <div
                class="start-end-date transition-all"
                :style="{
                  maxHeight: showAnimatedDatePicker ? '35em' : 0,
                  opacity: showAnimatedDatePicker ? 1 : 0
                }"
              >
                <available-flyte-picker
                  v-if="showAnimatedDatePicker"
                  :count="1"
                  :config.sync="flytePickerConfig"
                  :value="[startDate, endDate]"
                  :quantity-limit="product.collection.schedule.quantityLimit"
                  :insertion-dates="availableDates"
                  :allow-end-date="allowEndDate"
                  class="w-full"
                  @input="changeDateRange"
                  @date-render="onInventoryRenderDate"
                >
                  <template #hover="{ date }">
                    <booking-date-popover
                      has-cart
                      :sku-date="getSkuDate(date.date)"
                      :variant="variant"
                      :is-cart="isCart"
                    />
                  </template>
                </available-flyte-picker>
              </div>
            </div>
            <div
              v-else-if="hasImpressionsSelected"
              :key="'booking-inventory-calendar-loading'"
            >
              <loader-box :height="20" />
            </div>
          </transition-group>
        </div>
      </div>
    </transition>
  </el-form>
</template>

<script>
import moment from 'moment';
import { date as dateFormat, datetimeDb } from '@/utils/filters';
import { RateType, SkuStatus } from '@/constants';

import AvailableFlytePicker from '@/components/AdShop/AvailableFlytePicker';
import BookingDatePopover from '@/components/AdShop/Booking/BookingDatePopover';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import InventoryBookingMixin from '@/components/AdShop/Booking/InventoryBookingMixin';
import InventoryQuery from '@/components/Queries/Booking/InventoryQuery';
import TooltipIcon from '@/components/Core/TooltipIcon';

export default {
  components: {
    TooltipIcon,
    AvailableFlytePicker,
    BookingDatePopover,
    CustomElFormItem,
    InventoryQuery
  },
  mixins: [InventoryBookingMixin],
  props: {
    availableDates: {
      type: Array,
      required: true
    },
    rateTypes: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showTotal: false,
      RateType,
      selectedRateType: null,
      startDate: null,
      endDate: null,
      numberOfImpressions: null,
      showAnimatedDatePicker: false,
      flytePickerConfig: {
        mode: 'range'
      }
    };
  },

  computed: {
    isReady() {
      if (this.startDate && this.endDate) {
        return this.hasImpressionsSelected;
      }

      return false;
    },

    isBookedByImpressions() {
      return this.selectedRateType.type === RateType.IMPRESSIONS.value;
    },

    hasImpressionsSelected() {
      if (this.isBookedByImpressions) {
        return this.numberOfImpressions > 0;
      } else {
        return true;
      }
    },

    /** DEV-5406 (see comments)
     * For Digital ads, when booking by impressions we run into conflicts in inventory tracking with a per order booking, so we must limit
     * how a buyer can book relative to the Quantity available set on each variant */
    allowedRateTypes() {
      if (this.rateTypes.length > 1) {
        let rateTypes = [];

        for (let rateType of this.rateTypes) {
          // For Unlimited inventory variants, only allow booking by impressions
          if (!this.variant.quantity) {
            if (rateType.type === RateType.IMPRESSIONS.value) {
              rateTypes.push(rateType);
            }
          } else {
            // For limited inventory variants only allow booking by order (not by impressions)
            if (rateType.type !== RateType.IMPRESSIONS.value) {
              rateTypes.push(rateType);
            }
          }
        }

        return rateTypes;
      } else {
        return this.rateTypes;
      }
    },

    /**
     * Date group object representing the users current contiguous date group selection.
     *
     * If the user is purchasing by impressions the quantity is the number of requested impressions
     * divided by 1000.
     */
    productDate() {
      let quantity = 1;

      if (this.selectedRateType.type === RateType.IMPRESSIONS.value) {
        quantity = this.numberOfImpressions || 0;
      }

      let estimatedImpressions = this.estimateImpressions(
        this.startDate,
        this.endDate,
        this.numberOfImpressions * 1000
      );

      return {
        start_date: datetimeDb(moment(this.startDate).startOf('day')),
        end_date: datetimeDb(moment(this.endDate).endOf('day')),
        type: this.selectedRateType.type,
        quantity,
        estimatedImpressions
      };
    }
  },

  watch: {
    dates() {
      if (this.dates.length === 0) {
        this.startDate = null;
        this.endDate = null;
        this.numberOfImpressions = null;
      }
    }
  },

  mounted() {
    this.selectedRateType = this.allowedRateTypes[0];

    if (!this.isBookedByImpressions) {
      this.showAnimatedDatePicker = true;
    }
  },

  methods: {
    changeImpressions(impressions) {
      impressions = +impressions.replace(/,/g, '');

      // Only trigger a change when value will actually change for smoother animation / behavior
      if (
        impressions !== this.numberOfImpressions &&
        (this.numberOfImpressions || impressions)
      ) {
        this.numberOfImpressions = impressions;

        this.$nextTick(() => {
          this.showAnimatedDatePicker = this.numberOfImpressions > 0;
        });

        this.change();
      }
    },

    changeDateRange(date) {
      if (!this.$can('overbook_ads')) {
        let startSkuDate = this.getSkuDate(date[0]);

        if (startSkuDate && startSkuDate.status.id === SkuStatus.SOLD_OUT.id) {
          return this.$message.error(dateFormat(date[0]) + ' is Sold Out');
        }
      }

      this.startDate = date[0];
      this.endDate = date[1];

      this.change();
    },

    change() {
      if (this.isReady) {
        this.$emit('update:dates', [this.productDate]);
      }
    },

    allowEndDate(startDate, endDate) {
      if (!this.$can('overbook_ads')) {
        let skuDate = this.getSkuDate(endDate);
        if (skuDate) {
          if (skuDate.status.id === SkuStatus.SOLD_OUT.id) {
            // a -1 response means all dates from this point are not allowed
            return -1;
          }
        }
      }

      return true;
    }
  }
};
</script>
