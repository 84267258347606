<template>
  <div class="simple-cart-body">
    <el-table :data="cart.productVariantGroups" :show-header="false">
      <div slot="empty" class="empty-cart">
        <h3>Your cart is empty. Get started by adding items to your cart.</h3>
      </div>

      <!-- Image -->
      <el-table-column width="70">
        <cart-image-column slot-scope="{ row }" :product-variant-group="row" />
      </el-table-column>

      <!-- Description -->
      <el-table-column width="180">
        <template slot-scope="scope">
          <p>
            <strong>{{ scope.row.productVariant.product.name }}</strong>
          </p>
          <p class="text-dark-silver">
            {{ formatCountDates(scope.row) }} Selected
          </p>
        </template>
      </el-table-column>

      <!-- Price -->
      <el-table-column class="p-0" align="center">
        <cart-price-column slot-scope="{ row }" :product-date="row" />
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import CartMixin from '@/components/AdShop/Mixins/CartMixin';
import CartImageColumn from './Partials/CartImageColumn';
import CartPriceColumn from './Partials/CartPriceColumn';

export default {
  name: 'SimpleCartBody',

  components: {
    CartImageColumn,
    CartPriceColumn
  },

  mixins: [CartMixin],

  props: {
    cart: { type: Object, default: null }
  }
};
</script>
