var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ad-shop-cart"},[_c('cart-mutation',{staticClass:"flex justify-between w-full",attrs:{"cart-id":_vm.cart.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var isSavingCart = ref.isSaving;
      var updateProductVariantGroup = ref.updateProductVariantGroup;
      var deleteProductVariantGroup = ref.deleteProductVariantGroup;
return [_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex mb-8"},[_c('h1',[_vm._v("Your Cart")])]),_c('el-table',{staticClass:"el-table-slim",attrs:{"data":_vm.cart.productVariantGroups,"row-class-name":_vm.getRowClassName}},[_c('div',{staticClass:"m-8",attrs:{"slot":"empty"},slot:"empty"},[_c('h3',[_vm._v(" Your cart is empty. Get started by adding items to your cart. ")])]),(_vm.hasError)?_c('el-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('el-popover',{attrs:{"trigger":"hover"}},[_vm._l((scope.row.errors),function(error){return _c('div',{key:("error-" + (error.id))},[_vm._v(" "+_vm._s(error.message)+" ")])}),_c('template',{slot:"reference"},[(scope.row.errors.length > 0)?_c('icon',{staticClass:"icon-lg",attrs:{"icon":_vm.attention}}):_vm._e()],1)],2)}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"align":"left","label":"Item","width":"175"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{staticClass:"w-full",attrs:{"alt":"Primary Photo","src":scope.row.productVariant.product.primaryPhoto &&
                    scope.row.productVariant.product.primaryPhoto.url}})]}}],null,true)}),_c('el-table-column',{attrs:{"min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(scope.row.productVariant.product.name)+" ")]),_c('p',[_vm._v(_vm._s(scope.row.productVariant.name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Fulfillment","min-width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [(row.fulfillmentMethod != null)?_c('cart-fulfillment-method',{attrs:{"deadline-days":row.fulfillmentMethod.deadline_days,"first-due-date":_vm.getFirstDueDate(row),"name":row.fulfillmentMethod.display_name}}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Quantity","min-width":"125"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!_vm.isContiguous(scope.row))?_c('el-popover',{attrs:{"content":"this is content, this is content, this is content","placement":"bottom","trigger":"click"},on:{"hide":function($event){return _vm.saveModifications(
                    updateProductVariantGroup,
                    deleteProductVariantGroup,
                    scope.row.id
                  )}}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[(_vm.updatingDate && _vm.updatingDate.id === scope.row.id)?_c('loading-icon'):[_c('a',{staticClass:"themed"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.getCountDates(scope.row))+" Dates")])]),_c('div',[_vm._v(" "+_vm._s(_vm.getCountOrders(scope.row))+" "+_vm._s(scope.row.productVariant.unit.name || 'Orders')+" ")]),(_vm.isOverbooked[scope.row.id])?_c('div',{staticClass:"text-red font-bold"},[_c('icon',{attrs:{"icon":_vm.overbookedIcon}}),_vm._v(" Overbooked ")],1):_vm._e()])]],2),[_c('non-contiguous-booking',{attrs:{"is-cart":"","cart":_vm.cart,"variant":scope.row.productVariant,"available-dates":_vm.getBookableDates(
                        scope.row.productVariant.product.collection.schedule
                          .upcomingDates,
                        scope.row.fulfillmentMethod
                      ),"dates":(_vm.modifications[scope.row.id] &&
                        _vm.modifications[scope.row.id].dates) ||
                        scope.row.dates},on:{"overbooked":function($event){
                        var _obj;
_vm.isOverbooked = Object.assign({}, _vm.isOverbooked,
                        ( _obj = {}, _obj[scope.row.id] = $event, _obj ))},"update:dates":function($event){return _vm.handleDatesUpdate(scope.row.id, $event)}}})]],2):_vm._e(),(_vm.isContiguous(scope.row))?_vm._l((scope.row.dates),function(productDate){return _c('div',{key:("date-" + (productDate.id))},[_c('div',[_c('div',[(productDate.type === _vm.RateType.IMPRESSIONS.value)?[_vm._v(" "+_vm._s(productDate.quantity * 1000)+" impressions ")]:[_vm._v(" "+_vm._s(_vm.getNumberOfDays(productDate))+" days ")]],2),_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(productDate.start_date))+" to "+_vm._s(_vm._f("date")(productDate.end_date))+" ")]),_c('inventory-booking-mixin',{attrs:{"is-cart":"","cart":_vm.cart,"variant":scope.row.productVariant,"dates":scope.row.dates},on:{"overbooked":function($event){
                      var _obj;
_vm.isOverbooked = Object.assign({}, _vm.isOverbooked,
                          ( _obj = {}, _obj[scope.row.id] = $event, _obj ))}}})],1),(_vm.isOverbooked[scope.row.id])?_c('div',{staticClass:"text-red font-bold"},[_c('icon',{attrs:{"icon":_vm.overbookedIcon}}),_vm._v(" Overbooked ")],1):_vm._e()])}):_vm._e()]}}],null,true)}),_c('el-table-column',{attrs:{"label":"Price","min-width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var row = ref.row;
return [(_vm.updatingDate && _vm.updatingDate.id === row.id)?_c('loading-icon'):_c('cart-price-column',{attrs:{"product-date":row}})]}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.deletingItem && _vm.deletingItem.id === scope.row.id)?_c('loading-icon'):_c('div',{staticClass:"cursor-pointer hover:text-red",on:{"click":function($event){return _vm.deleteItem(deleteProductVariantGroup, scope.row.id)}}},[_c('icon',{staticClass:"icon-lg",attrs:{"icon":_vm.trashEmpty}})],1)]}}],null,true)})],1),(_vm.isConnectAccountDialogShown)?_c('el-dialog',{attrs:{"append-to-body":true,"visible":_vm.isConnectAccountDialogShown,"title":"Connect Account"},on:{"update:visible":function($event){_vm.isConnectAccountDialogShown=$event}}},[_c('connect-account',{on:{"signedIn":function($event){_vm.isConnectAccountDialogShown = false}}})],1):_vm._e()],1),_c('summary-side-bar',{staticClass:"ml-5 flex-grow w-full",attrs:{"cart":_vm.cart}},[(_vm.hasBuyerToCheckout)?_c('el-button',{staticClass:"themed w-full",attrs:{"disabled":_vm.cart.productVariantGroups.length === 0,"type":"primary"},on:{"click":_vm.proceedToCheckout}},[_vm._v(" Proceed to Checkout ")]):_c('div',[_c('el-alert',{staticClass:"text-center",attrs:{"type":"error","title":"","closable":false}},[_vm._v(" No Customer Selected ")]),_c('div',{staticClass:"mt-5"},[_vm._v(" You are currently previewing the Ad Shop. To place an order for a new or existing customer, "),_c('router-link',{attrs:{"to":{ name: 'supplier.customers' }}},[_vm._v(" start here ")]),_vm._v(" . ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }