<template>
  <img class="product-image" :src="photoSrc" />
</template>

<script>
import { optimizedFile } from '@/utils/helpers';

export default {
  props: {
    productVariantGroup: { type: Object, default: null }
  },

  computed: {
    photoSrc() {
      return optimizedFile(
        this.productVariantGroup.productVariant.product.primaryPhoto
      );
    }
  }
};
</script>
