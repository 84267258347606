<template>
  <div class="ad-shop-cart">
    <cart-mutation :cart-id="cart.id" class="flex justify-between w-full">
      <template
        v-slot="{
          isSaving: isSavingCart,
          updateProductVariantGroup,
          deleteProductVariantGroup
        }"
      >
        <div class="flex flex-col">
          <div class="flex mb-8">
            <h1>Your Cart</h1>
          </div>

          <!-- Product Variant Group Table -->
          <el-table
            class="el-table-slim"
            :data="cart.productVariantGroups"
            :row-class-name="getRowClassName"
          >
            <div slot="empty" class="m-8">
              <h3>
                Your cart is empty. Get started by adding items to your cart.
              </h3>
            </div>

            <!-- Error Icon -->
            <el-table-column v-if="hasError" width="50">
              <el-popover slot-scope="scope" trigger="hover">
                <div
                  v-for="error in scope.row.errors"
                  :key="`error-${error.id}`"
                >
                  {{ error.message }}
                </div>
                <template slot="reference">
                  <icon
                    v-if="scope.row.errors.length > 0"
                    :icon="attention"
                    class="icon-lg"
                  >
                  </icon>
                </template>
              </el-popover>
            </el-table-column>

            <!-- Image -->
            <el-table-column align="left" label="Item" width="175">
              <template slot-scope="scope">
                <img
                  alt="Primary Photo"
                  :src="
                    scope.row.productVariant.product.primaryPhoto &&
                      scope.row.productVariant.product.primaryPhoto.url
                  "
                  class="w-full"
                />
              </template>
            </el-table-column>

            <!-- Description -->
            <el-table-column min-width="150">
              <template slot-scope="scope">
                <p class="font-semibold">
                  {{ scope.row.productVariant.product.name }}
                </p>
                <p>{{ scope.row.productVariant.name }}</p>
              </template>
            </el-table-column>

            <!-- Fulfillment Method -->
            <el-table-column label="Fulfillment" min-width="250">
              <template v-slot="{ row }">
                <cart-fulfillment-method
                  v-if="row.fulfillmentMethod != null"
                  :deadline-days="row.fulfillmentMethod.deadline_days"
                  :first-due-date="getFirstDueDate(row)"
                  :name="row.fulfillmentMethod.display_name"
                />
              </template>
            </el-table-column>

            <!-- Quantity -->
            <el-table-column label="Quantity" min-width="125">
              <!-- Not Contiguous Quantity Display -->
              <template slot-scope="scope">
                <el-popover
                  v-if="!isContiguous(scope.row)"
                  content="this is content, this is content, this is content"
                  placement="bottom"
                  trigger="click"
                  @hide="
                    saveModifications(
                      updateProductVariantGroup,
                      deleteProductVariantGroup,
                      scope.row.id
                    )
                  "
                >
                  <!-- Count of Dates and Orders (Click to Show Popover)  -->
                  <div slot="reference">
                    <loading-icon
                      v-if="updatingDate && updatingDate.id === scope.row.id"
                    />
                    <template v-else>
                      <a class="themed">
                        <div>
                          <strong>{{ getCountDates(scope.row) }} Dates</strong>
                        </div>
                        <div>
                          {{ getCountOrders(scope.row) }}
                          {{ scope.row.productVariant.unit.name || 'Orders' }}
                        </div>
                        <div
                          v-if="isOverbooked[scope.row.id]"
                          class="text-red font-bold"
                        >
                          <icon :icon="overbookedIcon" />
                          Overbooked
                        </div>
                      </a>
                    </template>
                  </div>

                  <!-- Popover Content: Date Staging for ProductVariantGroup  -->
                  <template>
                    <non-contiguous-booking
                      is-cart
                      :cart="cart"
                      :variant="scope.row.productVariant"
                      :available-dates="
                        getBookableDates(
                          scope.row.productVariant.product.collection.schedule
                            .upcomingDates,
                          scope.row.fulfillmentMethod
                        )
                      "
                      :dates="
                        (modifications[scope.row.id] &&
                          modifications[scope.row.id].dates) ||
                          scope.row.dates
                      "
                      @overbooked="
                        isOverbooked = {
                          ...isOverbooked,
                          [scope.row.id]: $event
                        }
                      "
                      @update:dates="handleDatesUpdate(scope.row.id, $event)"
                    />
                  </template>
                </el-popover>

                <!-- Contiguous Quantity Display -->
                <template v-if="isContiguous(scope.row)">
                  <div
                    v-for="productDate in scope.row.dates"
                    :key="`date-${productDate.id}`"
                  >
                    <div>
                      <div>
                        <template
                          v-if="productDate.type === RateType.IMPRESSIONS.value"
                        >
                          {{ productDate.quantity * 1000 }} impressions
                        </template>

                        <template v-else>
                          {{ getNumberOfDays(productDate) }} days
                        </template>
                      </div>
                      <div>
                        {{ productDate.start_date | date }} to
                        {{ productDate.end_date | date }}
                      </div>
                      <!-- need this to check if the variant is overbooked -->
                      <inventory-booking-mixin
                        is-cart
                        :cart="cart"
                        :variant="scope.row.productVariant"
                        :dates="scope.row.dates"
                        @overbooked="
                          isOverbooked = {
                            ...isOverbooked,
                            [scope.row.id]: $event
                          }
                        "
                      />
                    </div>
                    <div
                      v-if="isOverbooked[scope.row.id]"
                      class="text-red font-bold"
                    >
                      <icon :icon="overbookedIcon" />
                      Overbooked
                    </div>
                  </div>
                </template>
              </template>
            </el-table-column>

            <!-- Price -->
            <el-table-column label="Price" min-width="110">
              <template slot-scope="{ row }">
                <loading-icon
                  v-if="updatingDate && updatingDate.id === row.id"
                />
                <cart-price-column v-else :product-date="row" />
              </template>
            </el-table-column>

            <!-- Trash Button -->
            <el-table-column align="center" width="50">
              <template slot-scope="scope">
                <loading-icon
                  v-if="deletingItem && deletingItem.id === scope.row.id"
                />
                <div
                  v-else
                  class="cursor-pointer hover:text-red"
                  @click="deleteItem(deleteProductVariantGroup, scope.row.id)"
                >
                  <icon class="icon-lg" :icon="trashEmpty" />
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- Sign in or Create Account Dialog -->
          <el-dialog
            v-if="isConnectAccountDialogShown"
            :append-to-body="true"
            :visible.sync="isConnectAccountDialogShown"
            title="Connect Account"
          >
            <connect-account @signedIn="isConnectAccountDialogShown = false" />
          </el-dialog>
        </div>
        <summary-side-bar :cart="cart" class="ml-5 flex-grow w-full">
          <el-button
            v-if="hasBuyerToCheckout"
            :disabled="cart.productVariantGroups.length === 0"
            class="themed w-full"
            type="primary"
            @click="proceedToCheckout"
          >
            Proceed to Checkout
          </el-button>
          <div v-else>
            <el-alert
              type="error"
              title=""
              :closable="false"
              class="text-center"
            >
              No Customer Selected
            </el-alert>

            <div class="mt-5">
              You are currently previewing the Ad Shop. To place an order for a
              new or existing customer,
              <router-link :to="{ name: 'supplier.customers' }">
                start here
              </router-link>
              .
            </div>
          </div>
        </summary-side-bar>
      </template>
    </cart-mutation>
  </div>
</template>

<script>
import moment from 'moment';

import { attention, trashEmpty } from '@/vendor/icons';

import BookableDatesMixin from '@/components/AdShop/Cart/BookableDatesMixin';
import CartFulfillmentMethod from '@/components/AdShop/Cart/CartFulfillmentMethod';
import CartMixin from '@/components/AdShop/Mixins/CartMixin';
import CartPriceColumn from './Partials/CartPriceColumn';
import ConnectAccount from '@/components/AdShop/Cart/ConnectAccount';
import LoadingIcon from '@/components/Core/Loading/LoadingIcon';
import NonContiguousBooking from '@/components/AdShop/Booking/NonContiguousBooking';
import SummarySideBar from './SummarySideBar';
import { CartMutation } from '@/components/Mutations';
import { RateType } from '@/constants/rate';
import InventoryBookingMixin from '@/components/AdShop/Booking/InventoryBookingMixin';

export default {
  components: {
    InventoryBookingMixin,
    CartFulfillmentMethod,
    CartMutation,
    CartPriceColumn,
    ConnectAccount,
    LoadingIcon,
    NonContiguousBooking,
    SummarySideBar
  },

  mixins: [CartMixin, BookableDatesMixin],

  props: {
    cart: { type: Object, required: true }
  },

  data() {
    return {
      RateType,
      deletingItem: null,
      hasError: false,
      isConnectAccountDialogShown: false,
      modifications: {},
      isOverbooked: {},
      updatingDate: false,

      // Icons
      overbookedIcon: attention,
      attention,
      trashEmpty
    };
  },

  computed: {
    hasBuyerToCheckout() {
      return !this.isSupplier || this.takeoverBuyerId;
    },
    isSupplier() {
      return this.$store.getters['auth/isSupplier'];
    },
    takeoverBuyerId() {
      return this.$store.getters['adShop/takeoverBuyerId'];
    }
  },

  methods: {
    async deleteItem(deleteProductVariantGroup, id) {
      this.deletingItem = { id };
      await deleteProductVariantGroup(id);
      this.deletingItem = null;
    },

    getCountOrders(productVariantGroup) {
      return productVariantGroup.dates.reduce(
        (sum, group) => sum + group.quantity,
        0
      );
    },

    getRowClassName({ row }) {
      if (row.errors.length > 0) {
        this.hasError = true;
        return 'error-row';
      }

      return '';
    },

    getFirstDueDate(productVariantGroup) {
      const earliestRunDate = productVariantGroup.dates.reduce(
        (earliest, group) => {
          const startDate = moment(group.start_date);

          if (earliest === null) {
            return startDate;
          }

          if (startDate.isBefore(earliest)) {
            return startDate;
          }

          return earliest;
        },
        null
      );

      const deadlineDays = productVariantGroup.fulfillmentMethod.deadline_days;
      return moment(earliestRunDate).subtract(deadlineDays, 'days');
    },

    handleDatesUpdate(productVariantGroupId, dates) {
      const oldModifications = this.modifications[productVariantGroupId] || {};
      const newModifications = {
        ...oldModifications,
        dates
      };

      this.$set(this.modifications, productVariantGroupId, newModifications);
    },

    async saveModifications(
      updateProductVariantGroup,
      deleteProductVariantGroup,
      productVariantGroupId
    ) {
      this.updatingDate = { id: productVariantGroupId };
      const modifications = { ...this.modifications[productVariantGroupId] };

      if (Object.keys(modifications).length === 0) {
        this.updatingDate = false;
        return; // nothing to save
      }

      // if there are no date groups left on the product variant group then delete it and return
      if (modifications.dates.length === 0) {
        await deleteProductVariantGroup(productVariantGroupId);
        this.$delete(this.modifications, productVariantGroupId);
        this.updatingDate = false;
        return;
      }

      // remove extra attributes from dates
      modifications.dates = modifications.dates.map(g => ({
        type: g.type,
        start_date: g.start_date,
        end_date: g.end_date,
        quantity: g.quantity
      }));

      if (typeof modifications === 'undefined') {
        this.updatingDate = false;
        return;
      }

      await updateProductVariantGroup(productVariantGroupId, modifications);

      this.$delete(this.modifications, productVariantGroupId);

      this.updatingDate = false;
    },

    getNumberOfDays(productDate) {
      const startDate = moment(productDate.start_date);
      const endDate = moment(productDate.end_date).add(1, 'second');
      return endDate.diff(startDate, 'days');
    },

    proceedToCheckout() {
      // if user is not a logged in, give them the option to login or create an account
      if (!this.$store.getters['auth/isAuthenticated']) {
        this.isConnectAccountDialogShown = true;
        return;
      }

      // Do not allow checkout w/o a buyer
      if (!this.hasBuyerToCheckout) {
        return this.$message.error('There is no Customer to check out');
      }

      // if user is a buyer, advance to payment page.
      this.$router.push({
        name: 'adShop.payment',
        params: {
          shopSlug: this.$store.getters['adShop/slug'],
          cart_id: this.$route.params.cart_id
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

.el-table .error-row {
  background-color: $color-light-red;
}
</style>
