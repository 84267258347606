<script>
import {
  getInventory,
  getInventoryFromAdShop
} from '@/graphql/booking/getInventory.graphql';

import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],
  props: {
    supplierId: {
      type: String,
      default: null
    },
    filter: {
      type: Object,
      default: null
    }
  },

  query() {
    return {
      query: this.supplierId ? getInventory : getInventoryFromAdShop,
      variables: {
        slug:
          this.$route.params.shopSlug ||
          this.$store.getters['auth/adShopSlug'] ||
          undefined,
        supplier_id: this.supplierId || undefined,
        filter: this.filter
      }
    };
  }
};
</script>
