<template>
  <div>
    <div class="text-center">
      <icon
        class="text-4xl text-blue mb-2 text-center"
        :icon="flytedeskLogoIcon"
      />
    </div>
    <div class="subtitle text-center">
      Connect to your flytedesk account to book a campaign with this supplier.
      Your account will allow you to track campaign progress, upload creatives,
      and view verifications.
    </div>
    <login-form class="w-full" @authenticated="$emit('signedIn')" />
    <div class="mt-8 text-center">
      <p>
        Don't have an account?
        <a @click="$emit('signUpRequest')">Sign up for flytedesk</a>
      </p>
      <p>
        <router-link :to="{ name: 'auth.forgotPassword' }">
          Forgot Password?
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { flytedeskLogo as flytedeskLogoIcon } from '@/vendor/icons';
import LoginForm from '@/components/Auth/LoginForm';

export default {
  components: { LoginForm },
  data() {
    return {
      flytedeskLogoIcon
    };
  }
};
</script>
