<template>
  <div class="relative max-h-50vh box-shadow--lg rounded text-dark-gray">
    <h3 class="flex justify-center pt-5">
      Cart Summary
    </h3>
    <cart-query>
      <div slot-scope="{ isLoading, data: cart }">
        <div class="px-4">
          <el-button
            type="primary"
            class="margin-top-lg w-full themed"
            @click="handleClick"
          >
            Review Cart
          </el-button>
        </div>
        <div class="overflow-y-auto max-h-30vh p-4 pt-2">
          <simple-cart-body v-if="cart" :cart="cart" />
        </div>
        <div class="mt-2 bg-white box-shadow--lg p-4 rounded rounded-t-none">
          <simple-cart-summary v-if="cart" :cart="cart" />
          <el-button
            type="primary"
            class="margin-top-lg w-full themed"
            @click="handleClick"
          >
            Review Cart
          </el-button>
        </div>
      </div>
    </cart-query>
  </div>
</template>

<script>
import SimpleCartBody from './SimpleCartBody';
import SimpleCartSummary from './SimpleCartSummary';
import { CartQuery } from '@/components/Queries';

export default {
  components: {
    CartQuery,
    SimpleCartBody,
    SimpleCartSummary
  },

  methods: {
    handleClick() {
      this.$emit('review');

      this.$router.push({
        name: 'adShop.cart',
        params: {
          cart_id: this.$route.params.cart_id
        }
      });
    }
  }
};
</script>
