<template>
  <div
    class="flex items-center justify-between bg-white text-dark-gray shadow px-7 w-full"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'HeaderLayout'
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

a.navbar-item {
  border-bottom: 2px transparent solid;

  &:hover,
  &.is-active {
    color: $color-blue;
    border-bottom: 2px $color-blue solid;
  }
}
</style>
