<template>
  <div>
    <ad-shop-query :buyer-id="buyerId">
      <template slot-scope="{ isLoading, data: adShop }">
        <div v-if="adShop" class="result">
          <transition name="fade" mode="out-in">
            <sign-in-flow
              v-if="activeFlow === FLOW_SIGN_IN"
              @signUpRequest="activeFlow = FLOW_CREATE_ACCOUNT"
              @signedIn="$emit('signedIn')"
            />
            <create-account-flow
              v-else-if="activeFlow === FLOW_CREATE_ACCOUNT"
              :supplier-id="adShop.supplier.id"
              @signInRequest="activeFlow = FLOW_SIGN_IN"
              @signedIn="$emit('signedIn')"
            />
            <div v-else class="text-center">
              <div
                v-if="adShop.profilePhotoFile"
                class="result adshop-profile-photo"
              >
                <img
                  alt="Profile Photo"
                  class="profile-photo"
                  :src="adShop.profilePhotoFile.url"
                />
              </div>
              <p class="connect-info">
                In order to finish booking, you'll need to create an account
                with our partner site flytedesk. This will allow you to track
                the progress of your ads, upload artwork, and view
                verifications.
              </p>
              <div class="flex items-center justify-center">
                <el-button
                  class="button-white-blue mr-5 w-1/4"
                  @click="activeFlow = FLOW_SIGN_IN"
                >
                  I already have an account
                </el-button>
                <el-button
                  class="button-white-blue mr-5 w-1/4"
                  @click="activeFlow = FLOW_CREATE_ACCOUNT"
                >
                  Create Account
                </el-button>
              </div>
              <p class="flytedesk-power">
                Powered by
                <icon class="flytedesk-logo" :icon="flytedeskLogoIcon" />
              </p>
            </div>
          </transition>
        </div>
        <loading-page v-else :failed="!isLoading">
          <template slot="failed-text">Ad Shop Settings not found</template>
        </loading-page>
      </template>
    </ad-shop-query>
  </div>
</template>

<script>
import { flytedeskLogo as flytedeskLogoIcon } from '@/vendor/icons';
import CreateAccountFlow from '@/components/AdShop/Cart/CreateAccountFlow';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import SignInFlow from '@/components/AdShop/Cart/SignInFlow';
import { AdShopQuery } from '@/components/Queries';

const FLOW_SIGN_IN = 'SIGN_IN';
const FLOW_CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export default {
  name: 'ConnectAccount',

  components: {
    AdShopQuery,
    CreateAccountFlow,
    LoadingPage,
    SignInFlow
  },

  data() {
    return {
      FLOW_SIGN_IN,
      FLOW_CREATE_ACCOUNT,
      flytedeskLogoIcon,

      activeFlow: null
    };
  },

  computed: {
    buyerId() {
      return this.$store.getters['auth/buyerId'];
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.connect-info {
  margin: 1em;
}

.flytedesk-logo {
  color: $color-blue;
  font-size: 1.3em;
  margin-left: 0.3em;
}

.flytedesk-power {
  font-size: 1em;
  margin-top: 1em;
}

.profile-photo {
  max-width: 6em;
}
</style>
