<template>
  <transition name="fadeDown" appear>
    <div
      v-if="isAdShopEnabled && adShopTermsNotification"
      class="ad-shop-terms-notification mx-4 mt-4"
    >
      <div class="notifications">
        <flyte-sticky-notification warning>
          <span slot="text"
            >Please accept the updated ad shop terms and conditions.</span
          >
          <el-button
            slot="button"
            class="button-blue"
            @click="showTermsDialog = true"
          >
            View Terms
          </el-button>
        </flyte-sticky-notification>
      </div>

      <ad-shop-terms-dialog
        v-if="showTermsDialog && adShopTermsNotification"
        :terms-notification="adShopTermsNotification"
        @close="showTermsDialog = false"
      />

      <user-notifications-subscription @data="notifications = $event" />
    </div>
  </transition>
</template>
<script>
import FlyteStickyNotification from '@/components/Core/FlyteStickyNotification';
import AdShopTermsDialog from '@/components/Core/AdShopTermsDialog';
import { find } from 'lodash';
import { UserNotificationsSubscription } from '@/components/Queries';

export default {
  components: {
    UserNotificationsSubscription,
    AdShopTermsDialog,
    FlyteStickyNotification
  },

  data() {
    return {
      showTermsDialog: false,
      notifications: null
    };
  },

  computed: {
    supplier() {
      return this.$store.getters['auth/user'];
    },
    isAdShopEnabled() {
      if (this.supplier) {
        const adShop = this.supplier.adShop;

        if (adShop) {
          return adShop.is_enabled;
        }
      }

      return false;
    },
    adShopTermsNotification() {
      return find(this.notifications, {
        ref: 'adshop-terms-and-conditions'
      });
    }
  }
};
</script>
