<template>
  <div class="page-cart">
    <cart-query>
      <div slot-scope="{ data: cart }">
        <ad-shop-cart v-if="cart" :cart="cart" />
        <div v-else>
          Loading Cart...
        </div>
      </div>
    </cart-query>
  </div>
</template>

<script>
import CartQuery from '@/components/Queries/AdShop/CartQuery';
import AdShopCart from '@/components/AdShop/Cart/AdShopCart';

export default {
  components: {
    CartQuery,
    AdShopCart
  }
};
</script>
