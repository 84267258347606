<template>
  <inventory-query
    v-if="variant"
    :filter="{ sku: variant.sku }"
    @data="onInventory"
  />
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import { dateDb } from '@/utils/filters';
import { RateType, SkuStatus } from '@/constants';

import InventoryQuery from '@/components/Queries/Booking/InventoryQuery';

export default {
  components: {
    InventoryQuery
  },
  props: {
    // If this component is rendered in the context of the cart
    // then the selected dates (ie: dates prop) are the actual dates
    // in the cart
    isCart: Boolean,
    // The cart
    cart: {
      type: Object,
      default: null
    },
    // Booking for this variant
    variant: {
      type: Object,
      default: null
    },
    // Either the selected dates or the dates already in the cart (depending on the isCart prop)
    dates: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      skuInventory: null
    };
  },

  computed: {
    canOverbook() {
      return this.$can('overbook_ads');
    },

    cartVariantLineItems() {
      return this.cart.productVariantGroups.filter(
        pvg => pvg.productVariant.id === this.variant.id
      );
    },

    skuInventoryMap() {
      let map = {};

      for (let skuDate of this.skuInventory) {
        map[skuDate.date] = skuDate;
      }

      return map;
    },

    datesMap() {
      let map = {};

      for (let date of this.dates) {
        let currDate = moment(date.start_date);

        do {
          map[dateDb(currDate)] = date;
          currDate.add(1, 'day');
        } while (currDate.isSameOrBefore(date.end_date, 'date'));
      }

      return map;
    }
  },

  watch: {
    dates(newValue, oldValue) {
      if (this.cart && this.isCart && !isEqual(newValue, oldValue)) {
        this.checkForOverbooked();
      }
    }
  },

  beforeUpdate() {
    if (!this.$el.className.match(/inventory-booking-mixin/)) {
      this.$el.className += ' inventory-booking-mixin';
    }
  },

  methods: {
    onInventory(data) {
      // The inventory Query may have different structures depending on if we are looking up the inventory by Ad Shop or by Supplier directly
      this.skuInventory = (data.supplier || data.adShop.supplier).skuInventory;

      this.checkForOverbooked();
    },

    checkForOverbooked() {
      if (this.cart) {
        for (let cartGroup of this.cart.productVariantGroups) {
          for (let cartDate of cartGroup.dates) {
            if (this.isCartDateOverbooked(cartDate)) {
              return this.$emit('overbooked', true);
            }
          }
        }
      }

      this.$emit('overbooked', false);
    },

    /**
     * Checks if the product Date in the cart is overbooked. If it is a contiguous schedule, will check
     * all dates in the range for overbookings
     */
    isCartDateOverbooked(cartDate) {
      let currDate = moment(cartDate.start_date);
      let endDate = moment(cartDate.end_date);

      do {
        if (this.isDateOverbooked(currDate)) {
          return true;
        }

        currDate.add(1, 'day');
      } while (currDate.isSameOrBefore(endDate, 'date'));

      return false;
    },

    /**
     * Checks if the date is overbooked based on the SKU inventory
     */
    isDateOverbooked(date) {
      let skuDate = this.getSkuDate(date);

      if (skuDate) {
        return SkuStatus.OVERBOOKED.id === skuDate.status.id;
      }

      return true;
    },

    /**
     * Checks if selecting this date will overbook it
     * (or if it is already selected, checks if it is currently being overbooked)
     */
    isSelectedDateOverbooked(date) {
      let skuDate = this.getSkuDate(date);

      if (skuDate) {
        return [
          SkuStatus.OVERBOOKED.id,
          SkuStatus.SOLD_OUT.id,
          SkuStatus.OVER_SELECTED.id
        ].includes(skuDate.status.id);
      }

      return true;
    },

    getCartDateBooking(date) {
      let quantity = 0;
      let impressions = 0;

      if (this.cart && date) {
        // XXX: For quick comparison, all dates have times in the cart
        let dateStr = dateDb(date);
        let dateTimeStr = dateStr + ' 00:00:00';

        for (let lineItem of this.cartVariantLineItems) {
          let liDate = lineItem.dates.find(vd => {
            return dateTimeStr >= vd.start_date && dateTimeStr <= vd.end_date;
          });

          if (liDate) {
            if (liDate.type === RateType.IMPRESSIONS.value) {
              if (liDate.estimated_impressions) {
                let estimated = liDate.estimated_impressions[dateStr];
                impressions += (estimated && estimated.impressions) || 0;
              }

              quantity += 1;
            } else {
              quantity += liDate.quantity;
            }
          }
        }
      }

      return { quantity, impressions };
    },

    getSkuDate(date) {
      if (date && this.skuInventory) {
        let dateStr = dateDb(date);

        let skuDate = this.skuInventoryMap[dateStr] || { date: dateStr };

        let selectedDate = this.datesMap[dateStr];

        let quantitySelected = 0;
        let impressionsSelected = 0;

        if (selectedDate) {
          if (selectedDate.type === RateType.IMPRESSIONS.value) {
            quantitySelected = 1;
            let estimatedMap =
              selectedDate.estimatedImpressions ||
              selectedDate.estimated_impressions;

            let estimated = estimatedMap && estimatedMap[dateStr];
            impressionsSelected = estimated ? estimated.impressions : 0;
          } else {
            quantitySelected = selectedDate.quantity;
          }
        }

        let cartDateBooking;

        // If this is the cart, then the selected quantities are what is in the cart
        if (this.isCart) {
          cartDateBooking = {
            quantity: quantitySelected,
            impressions: impressionsSelected
          };
        } else {
          // If this is not the cart, then our selected quantities are a pre-cart selection
          // so grab actual cart quantities separately
          cartDateBooking = this.getCartDateBooking(date);
        }

        skuDate = {
          ...skuDate,
          quantitySelected,
          impressionsSelected,
          quantityInCart: cartDateBooking.quantity,
          impressionsInCart: cartDateBooking.impressions
        };

        skuDate.status = this.getSkuDateStatus(skuDate);

        return skuDate;
      }

      return null;
    },

    /**
     * Calculates the Current Inventory status for the SKU Date
     * */
    getSkuDateStatus(skuDate) {
      if (skuDate && skuDate.id) {
        if (
          skuDate.quantityInCart &&
          skuDate.available_quantity - skuDate.quantityInCart < 0
        ) {
          return SkuStatus.OVERBOOKED;
        }

        if (skuDate.available_quantity <= 0) {
          return SkuStatus.SOLD_OUT;
        }

        if (skuDate.available_quantity - skuDate.quantityInCart === 0) {
          return SkuStatus.FULL_RESERVED;
        }

        if (
          !this.isCart &&
          skuDate.available_quantity -
            skuDate.quantityInCart -
            skuDate.quantitySelected <
            0
        ) {
          return SkuStatus.OVER_SELECTED;
        }

        if (skuDate.available_impressions > 0) {
          if (skuDate.available_impressions - skuDate.impressionsInCart < 0) {
            return SkuStatus.OVERBOOKED_IMPRESSIONS;
          }

          if (
            !this.isCart &&
            skuDate.available_impressions -
              skuDate.impressionsInCart -
              skuDate.impressionsSelected <
              0
          ) {
            return SkuStatus.OVER_SELECTED_IMPRESSIONS;
          }
        }

        return SkuStatus.AVAILABLE;
      }

      return SkuStatus.UNAVAILABLE;
    },

    /**
     * Relatively distribute the impressions across the given date range based on total impression availability on each day
     * in the date range
     *
     * @param startDate
     * @param endDate
     * @param impressions
     * @returns {{}}
     */
    estimateImpressions(startDate, endDate, impressions) {
      let currDate = moment(startDate);
      let totalDays = moment(endDate).diff(currDate, 'days') + 1;

      let totalAvailableImpressions = 0;

      let impressionMap = {};

      // Loop through all days in range
      while (currDate.isSameOrBefore(endDate)) {
        let dateStr = dateDb(currDate);

        let skuDate = this.skuInventoryMap[dateStr];
        let cartDate = this.getCartDateBooking(currDate);

        // The total remaining available impressions on this date (0 if no availability)
        let available = Math.max(
          ((skuDate && skuDate.available_impressions) || 0) -
            cartDate.impressions,
          0
        );

        impressionMap[dateStr] = {
          available
        };

        totalAvailableImpressions += available;

        // move to next day
        currDate.add(1, 'day');
      }

      // The number of impressions past the total availability
      let remainingImpressions = Math.max(
        0,
        impressions - totalAvailableImpressions
      );

      // Relatively distribute the # of impressions requested across the date range
      for (let dateStr of Object.keys(impressionMap)) {
        // Calculate the % of impressions to apply on each date based on current availability on the date
        // If there are no impressions available, then we only use remaining impressions
        let percent =
          totalAvailableImpressions > 0
            ? (impressionMap[dateStr].available || 0) /
              totalAvailableImpressions
            : 0;

        // Calculate the estimated # of impressions that will run on this day relative to the estimated
        // available impressions across the whole selected date range
        // If there are more impressions requests than available, evenly distribute them among all the days
        impressionMap[dateStr].impressions = Math.round(
          Math.min(totalAvailableImpressions, impressions) * percent +
            remainingImpressions / totalDays
        );
      }

      return impressionMap;
    },

    isOverSelected(date) {
      let skuDate = this.getSkuDate(date);

      if (skuDate) {
        return (
          skuDate.status === SkuStatus.OVER_SELECTED_IMPRESSIONS ||
          skuDate.status === SkuStatus.OVER_SELECTED
        );
      }
    },

    onInventoryRenderDate(date) {
      let skuDate = this.getSkuDate(date.date);

      if (skuDate) {
        switch (skuDate.status.id) {
          case SkuStatus.OVER_SELECTED.id:
          case SkuStatus.OVER_SELECTED_IMPRESSIONS.id:
            date.el.className += ' is-over-selected';
            break;

          case SkuStatus.OVERBOOKED_IMPRESSIONS.id:
            date.el.className += ' is-overbooked-impressions is-over-selected';
            break;

          case SkuStatus.SOLD_OUT.id:
          case SkuStatus.OVERBOOKED.id:
          case SkuStatus.FULL_RESERVED.id:
            date.el.className += ' is-sold-out';
            break;

          case SkuStatus.AVAILABLE.id:
            date.el.className += ' is-available';
            break;
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables.scss';

.inventory-booking-mixin {
  z-index: 1000;

  .flyte-picker-component {
    .flatpickr-calendar {
      .flatpickr-day {
        &,
        &.selected {
          &.is-available {
            &::after {
              border: 1px solid $color-blue;
            }
          }

          &.is-over-selected {
            &::after {
              color: white !important;
              background: $color-yellow !important;
              border: none !important;
            }

            &.startRange,
            &.endRange,
            &.inRange {
              color: white;

              &::before {
                background: lighten($color-yellow, 20%);
              }
            }
          }

          &.is-sold-out {
            color: white !important;

            &::after {
              color: white !important;
              background: $color-red !important;
              border: none !important;
            }

            &.startRange,
            &.endRange,
            &.inRange {
              color: white;

              &::before {
                background: lighten($color-red, 20%);
              }
            }

            &.selected {
              &::after {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}
</style>
