<template>
  <div class="max-w-2xl mx-auto mt-8">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'LayoutCart'
};
</script>
