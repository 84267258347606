<template>
  <div class="reps">
    <h3>{{ title }} Reps</h3>
    <div class="rep-list mt-5">
      <div v-if="currentReps">
        <transition-group
          tag="ul"
          name="fade"
          class="rep-list animate-position"
        >
          <li v-for="rep in currentReps" :key="`rep-${rep.id}`">
            <loader-box v-if="!rep.email" :height="2" />
            <div v-else class="flex">
              <div class="flex-grow">
                <span v-if="rep.name">{{ rep.name }}</span>
                <span v-else>{{ rep.email }}</span>
              </div>
              <a class="flex-shrink" @click="$emit('remove', rep.id)">
                <icon
                  :icon="isSavingId === rep.id ? arrowsCw : trashIcon"
                  :spin="isSavingId === rep.id"
                  class="icon-lg is-pulled-right themed-color"
                />
              </a>
            </div>
            <hr class="my-3" />
          </li>

          <li
            v-if="computedAvailableReps.length !== 0"
            :key="'add-new-rep'"
            class="mt-5"
          >
            <h3>Add New</h3>
            <div class="flex add-rep mt-5">
              <el-form
                ref="repPickerForm"
                class="flex-grow"
                :model="repPickerForm"
                :rules="repPickerRules"
              >
                <el-form-item prop="selectedRepUserId" class="mb-0">
                  <el-select
                    v-model="repPickerForm.selectedRepUserId"
                    placeholder="Select Rep"
                    class="themed w-full"
                  >
                    <el-option
                      v-for="rep in computedAvailableReps"
                      :key="`rep-${rep.id}`"
                      :label="rep.name || rep.email"
                      :value="rep.id"
                    />
                  </el-select>
                </el-form-item>
              </el-form>

              <el-button
                class="button-blue themed ml-3 flex-shrink-0 w-32"
                type="primary"
                @click="addRep(repPickerForm.selectedRepUserId)"
              >
                Add
              </el-button>
            </div>
          </li>
        </transition-group>
      </div>
      <div v-else>
        No Reps currently assigned to this Customer
      </div>
    </div>
  </div>
</template>

<script>
import { differenceBy, find } from 'lodash';
import { LoaderBox } from '@/components/Core/Loaders';

import { arrowsCw, trashEmpty as trashIcon } from '@/vendor/icons/index';

export default {
  components: {
    LoaderBox
  },

  props: {
    availableReps: {
      type: Array,
      required: true
    },
    currentReps: {
      type: Array,
      default: () => []
    },
    isSavingId: {
      type: [String, Number],
      default: null
    },
    title: {
      type: String,
      default: 'Account'
    }
  },

  data() {
    var validateRep = (rule, value, callback) => {
      // Did they input a rep?
      if (!value) {
        callback(new Error('Please select a Rep'));
      }

      // Is the select rep already in our currentReps list?
      let currentRepExists = find(this.currentReps, { id: value });
      if (currentRepExists) {
        callback(
          new Error(
            'That Representative has already been added to this ' +
              (this.title ? this.title : 'Customer')
          )
        );
      } else {
        callback();
      }
    };
    return {
      repPickerForm: {
        selectedRepUserId: null
      },
      repPickerRules: {
        selectedRepUserId: [{ validator: validateRep, trigger: 'blur' }]
      },

      // Icons
      arrowsCw,
      trashIcon
    };
  },

  computed: {
    computedAvailableReps() {
      return differenceBy(this.availableReps, this.currentReps, 'id');
    }
  },

  methods: {
    addRep(userId) {
      this.$refs['repPickerForm'].validate(valid => {
        if (valid) {
          this.$emit('add', userId);

          // Reset the Select drop down
          this.repPickerForm.selectedRepUserId = null;
        }
      });
    }
  }
};
</script>
