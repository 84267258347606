<template>
  <div class="form-credit-payment">
    <el-form ref="ccForm" :model="form" :rules="rules">
      <h4 class="font-semibold">Credit Card Information</h4>
      <span>
        Credit cards will be charged at the end of the month for all ads that
        ran during the previous month. Invoices generated will be on Net 30
        terms.
      </span>
      <el-alert
        v-if="cardError"
        class="mt-3"
        :title="cardError"
        type="error"
        :closable="false"
      >
      </el-alert>

      <div id="card-element" class="my-3">
        <!-- A Stripe Element will be inserted here. -->
      </div>

      <h3 class="mt-8 text-md">Billing Address</h3>
      <br />
      <div>
        <div class="flex -m-3">
          <div class="block p-3 flex-1">
            <custom-el-form-item label="Country">
              <el-select v-model="form.country" class="themed w-full">
                <el-option value="US" label="United States"></el-option>
              </el-select>
            </custom-el-form-item>
          </div>
        </div>
      </div>

      <div>
        <div class="flex -m-3">
          <div class="block p-3 flex-1">
            <custom-el-form-item label="Name">
              <el-input v-model="form.name" class="themed" />
            </custom-el-form-item>
          </div>
        </div>
      </div>

      <div>
        <div class="flex -m-3">
          <div class="block p-3 flex-1">
            <custom-el-form-item label="Street Address" prop="address">
              <el-input v-model="form.address" class="themed" />
            </custom-el-form-item>
          </div>

          <div class="block p-3 flex-1">
            <custom-el-form-item label="PO Box/Apt #">
              <el-input v-model="form.address2" class="themed" />
            </custom-el-form-item>
          </div>
        </div>
      </div>

      <div>
        <div class="flex -m-3">
          <div class="block p-3 flex-1">
            <custom-el-form-item label="City" prop="city">
              <el-input v-model="form.city" class="themed" />
            </custom-el-form-item>
          </div>
        </div>
      </div>

      <div>
        <div class="flex -m-3">
          <div class="block p-3 flex-1">
            <custom-el-form-item label="State" prop="state">
              <select-state v-model="form.state" class="themed w-full" />
            </custom-el-form-item>
          </div>

          <div class="block p-3 flex-1">
            <custom-el-form-item label="Zip/Postal Code" prop="zip_code">
              <el-input v-model="form.zip_code" v-mask.zipcode class="themed" />
            </custom-el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import SelectState from '@/components/Core/SelectState.vue';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    SelectState,
    CustomElFormItem
  },

  props: {
    address: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    let address = this.address || {};

    return {
      stripe: null,
      card: null,
      cardError: null,
      cardToken: null,

      form: {
        name: address.name || '',
        address: address.address || '',
        address2: address.address2 || '',
        city: address.city || '',
        state: address.state || '',
        zip_code: address.zip_code || '',
        country: address.country || ''
      }
    };
  },

  computed: {
    stripeParams() {
      return {
        address_country: this.form.country,
        name: this.form.name,
        address_line1: this.form.address,
        address_line2: this.form.address2,
        address_city: this.form.city,
        address_state: this.form.state,
        address_zip: this.form.zip_code
      };
    },
    rules() {
      return {
        address: [
          { required: true, message: 'Address is required', trigger: 'change' }
        ],
        city: [
          { required: true, message: 'City is required', trigger: 'change' }
        ],
        state: [
          { required: true, message: 'State is required', trigger: 'change' }
        ],
        zip_code: [
          { required: true, message: 'Zip Code is required', trigger: 'change' }
        ]
      };
    }
  },

  mounted() {
    if (window.Stripe) {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_CLIENT_KEY);
      const elements = this.stripe.elements();

      // Create an instance of the card Element.
      this.card = elements.create('card', {
        hidePostalCode: true
      });

      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');

      this.card.addEventListener('change', ({ error }) => {
        if (error) {
          this.cardError = error.message;
        } else {
          this.cardError = null;
        }
      });
    }
  },

  beforeDestroy() {
    this.card.destroy();
  },

  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        this.$refs.ccForm.validate(async valid => {
          if (valid) {
            resolve(await this.submitStripe());
          } else {
            reject('Please complete the all the required fields');
          }
        });
      });
    },
    async submitStripe() {
      const result = await this.stripe.createToken(
        this.card,
        this.stripeParams
      );

      if (result.error) {
        // Inform the customer that there was an error.
        return {
          error: result.error.message
        };
      }

      // Send the token to your server.
      return {
        stripeToken: result.token,
        form: this.form
      };
    }
  }
};
</script>

<style>
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
