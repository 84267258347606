<script>
import { ScheduleTypeValue } from '@/constants';
import { get } from 'lodash';

export default {
  name: 'CartMixin',

  methods: {
    getCountDates(productVariantGroup) {
      return productVariantGroup.dates.length;
    },

    productVariantSchedule(productVariantGroup) {
      return get(productVariantGroup, [
        'productVariant',
        'product',
        'collection',
        'schedule'
      ]);
    },

    isContiguous(productVariantGroup) {
      let schedule = this.productVariantSchedule(productVariantGroup);
      return schedule.scheduleType.value === ScheduleTypeValue.CONTIGUOUS;
    },

    formatCountDates(productVariantGroup) {
      const count = productVariantGroup.dates.length;
      if (count > 1) {
        return `${count} Dates`;
      }

      return `${count} Date`;
    },

    hasAdjustedPrice(pricedItem) {
      return pricedItem.total_base_price !== pricedItem.total_adjusted_price;
    }
  }
};
</script>
