<template>
  <div class="max-w-full">
    <doughnut-chart
      v-if="mediaKitData"
      class="media-mix-pie-chart flex relative"
      :chart-data="mediaKitData"
    >
      <template v-slot:tooltip="{ tooltipModel }">
        <custom-chart-tooltip :tooltip-model="tooltipModel" />
      </template>
    </doughnut-chart>
  </div>
</template>

<script>
import { ChartColors } from '@/constants';
import CustomChartTooltip from './CustomChartTooltip';
import DoughnutChart from './DoughnutChart';

export default {
  components: {
    CustomChartTooltip,
    DoughnutChart
  },
  props: {
    size: {
      type: Number,
      default: 150
    },
    cart: {
      type: Object,
      required: true
    }
  },

  computed: {
    mediaKitData() {
      if (this.cart && this.cart.summary) {
        return this.formatMediaMixDataset(this.cart.summary);
      }
      return null;
    }
  },

  methods: {
    formatMediumName(medium) {
      return medium
        .trim()
        .toUpperCase()
        .replace(/ /g, '_');
    },

    formatMediaMixDataset(summary) {
      let index = 0;
      let labels = [];
      let data = [];
      let backgroundColor = [];
      let media = summary.mediums;

      for (const key in media) {
        labels.push('% ' + media[key].value);
        data.push(
          ((media[key].price / summary.totals.adjustedPrice) * 100).toFixed(2)
        );

        // Straight color assignment priority taken from ChartColors constant
        backgroundColor.push(ChartColors[index]);
        index >= ChartColors.length - 1 ? (index = 0) : index++;
      }

      return {
        labels,
        datasets: [
          {
            data,
            hoverBorderWidth: 10,
            backgroundColor,
            borderColor: backgroundColor,
            hoverBackgroundColor: backgroundColor,
            hoverBorderColor: backgroundColor
          }
        ]
      };
    }
  }
};
</script>
