<template>
  <the-default-layout class="themed-wrapper">
    <template slot="header">
      <transition
        appear
        mode="out-in"
        :name="isSupplier ? '' : 'fade'"
        :duration="100"
      >
        <the-sticky-adshop-account-bar v-if="isStickyHeader" />
        <buyer-nav-bar
          v-else-if="isBuyer"
          :link-to="{ name: 'adShop', props: { shopSlug } }"
        />
        <the-account-bar
          v-else-if="isSupplier"
          class="bg-blue"
          :link-to="{
            name: 'supplier.dashboard'
          }"
        >
          <span slot="title">publisher</span>
          <div class="flex items-center">
            <notifications-panel-icon />
            <account-info-button button-class="button-white-blue" />
          </div>
        </the-account-bar>
        <the-guest-account-bar v-else />
      </transition>
    </template>
    <the-nav-bar v-if="isSupplier" slot="sidebar" :nav="navMenu" />
    <ad-shop-terms-notification slot="content-top" />
    <div class="layout-adshop relative pb-20">
      <shop-header />
      <adshop-styled-component />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </the-default-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import AccountInfoButton from '@/components/Auth/AccountInfoButton';
import AdshopStyledComponent from './AdshopStyledComponent';
import AdShopTermsNotification from '@/components/Core/AdShopTermsNotification';
import BuyerNavBar from '@/modules/Buyer/TheBuyerAccountBar';
import supplierMenu from '@/navigation/supplier';
import ShopHeader from '@/components/AdShop/ShopHeader/ShopHeader';
import TheAccountBar from '@/components/Core/Layout/TheAccountBar';
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';
import TheGuestAccountBar from '@/components/Core/Layout/TheGuestAccountBar';
import TheNavBar from '@/components/Core/Layout/TheNavBar';
import TheStickyAdshopAccountBar from '@/components/Core/Layout/TheStickyAdshopAccountBar';

export default {
  components: {
    AccountInfoButton,
    AdshopStyledComponent,
    BuyerNavBar,
    AdShopTermsNotification,
    TheGuestAccountBar,
    TheDefaultLayout,
    TheAccountBar,
    TheNavBar,
    TheStickyAdshopAccountBar,
    ShopHeader
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit('adShop/setSlug', to.params.shopSlug);
    next();
  },
  data() {
    return {
      navMenu: supplierMenu(this.$store, this.$router)
    };
  },
  computed: {
    ...mapGetters({
      isSupplier: 'auth/isSupplier',
      isBuyer: 'auth/isBuyer',
      offsetTop: 'common/getContentOffsetTop',
      scrollY: 'common/getContentScrollY',
      shopSlug: 'adShop/slug'
    }),
    isStickyHeader() {
      return this.scrollY > this.offsetTop + 90;
    }
  }
};
</script>
