<template>
  <div class="text-info flex flex-col">
    <transition name="fade" mode="out-in" :duration="200">
      <div v-if="!editing" key="text-info" class="text-md">
        <router-link class="shop-name" :to="shopNameLink"
          >{{ adShop.shop_name }}
        </router-link>
        <text-editor-renderer
          v-if="adShop.description"
          :content="adShop.description"
        />
        <div v-if="adShop.email || adShop.phone" class="contact-info text-left">
          <div v-if="adShop.email" class="flex">
            <icon class="icon-lg mr-2" :style="iconStyle" :icon="mailIcon" />
            {{ adShop.email }}
          </div>
          <div v-if="adShop.phone" class="flex mt-3">
            <icon class="icon-lg mr-2" :style="iconStyle" :icon="phoneIcon" />
            {{ adShop.phone }}
          </div>
        </div>

        <div class="mt-5">
          <a class="flex align-center" @click="copyShareLink">
            <icon class="icon-lg mr-2" :icon="shareIcon" />
            Copy shareable link to page
          </a>
        </div>
      </div>
      <div v-else :key="'text-info-editing'" class="editing">
        <el-form label-width="8em" label-position="left">
          <el-form-item label="Theme Color">
            <el-color-picker
              v-model="editable.theme_color"
              v-mask.hexColor
              name="colorPicker"
              :predefine="[colors.blue]"
              @change="onChange"
            />
          </el-form-item>

          <el-form-item label="Shop Name">
            <el-input
              v-model="editable.shop_name"
              auto-complete="off"
              @change="onChange"
            />
          </el-form-item>

          <el-form-item label="Description">
            <text-editor
              v-if="$feature('wysiwyg-editor')"
              v-model="editable.description"
              class="description-box"
              @input="onChange"
            />
            <el-input
              v-else
              v-model="editable.description"
              class="description-box"
              type="textarea"
              @change="onChange"
            />
          </el-form-item>

          <el-form-item label="Email">
            <el-input
              v-model="editable.email"
              v-mask.email
              class="with-icon"
              @change="onChange"
            >
              <icon
                slot="prefix"
                class="icon-lg"
                :style="formIconStyle"
                :icon="mailIcon"
              />
            </el-input>
          </el-form-item>

          <el-form-item label="Phone">
            <el-input
              v-model="editable.phone"
              v-mask.phone
              class="with-icon"
              @change="onChange"
            >
              <icon
                slot="prefix"
                class="icon-lg"
                :style="formIconStyle"
                :icon="phoneIcon"
              />
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  link as shareIcon,
  mail as mailIcon,
  phone as phoneIcon
} from '@/vendor/icons';
import { colors } from '@/tailwind';
import TextEditor from '@/components/Core/Form/TextEditor';
import TextEditorRenderer from '@/components/Core/Form/TextEditorRenderer';
import { copyToClipboard } from '@/utils/helpers';

export default {
  components: {
    TextEditorRenderer,
    TextEditor
  },
  props: {
    adShop: {
      type: Object,
      required: true
    },
    editing: Boolean
  },
  data() {
    let themeColor = this.adShop.theme_color.replace('#', '') || '5FAEE1';

    return {
      mailIcon,
      phoneIcon,
      shareIcon,
      editable: {
        theme_color: '#' + themeColor,
        shop_name: this.adShop.shop_name,
        description: this.adShop.description,
        email: this.adShop.email,
        phone: this.adShop.phone
      },
      colors
    };
  },
  computed: {
    iconStyle() {
      return {
        color: this.editable.theme_color
      };
    },
    formIconStyle() {
      return {
        'margin-left': '0.5em',
        'font-size': '1.2em',
        color: this.editable.theme_color
      };
    },
    shopNameLink() {
      return {
        name: 'adShop',
        params: {
          shopSlug: this.$store.getters['adShop/slug']
        }
      };
    }
  },
  methods: {
    onChange() {
      this.$emit('change', {
        ...this.editable,
        theme_color: this.editable.theme_color.replace('#', '')
      });
    },
    copyShareLink() {
      copyToClipboard(
        location.href.replace(this.$route.params.cart_id, 'share')
      );
      this.$message.success('Shareable link copied to clipboard!');
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.shop-name {
  font-weight: bolder;
  font-size: 1.8em;
  color: $color-dark-gray;
}

.description {
  line-height: 1.25em;
  color: $color-dark-silver;
}

.contact-info {
  margin-top: 0.2em;

  .column {
    display: inline-block;
    white-space: nowrap;
  }

  .icon {
    font-size: 1.2em;
    margin-right: 0.4em;
  }
}

.editing {
  .el-color-picker {
    width: 100%;

    /deep/ .el-color-picker__trigger {
      width: 100%;
    }
  }

  .el-form-item {
    & + .el-form-item {
      margin-top: 1em;
    }

    /deep/ .el-form-item__label {
      font-weight: bold;
      color: $color-dark-gray;
    }
  }

  .el-input.with-icon {
    /deep/ .el-input__inner {
      padding-left: 3em;
    }
  }
}
</style>
