<template>
  <div v-if="showHeader" class="header-photo relative bg-cover">
    <div
      class="background-photo relative w-full h-0 overflow-hidden"
      :class="photoFile ? 'bg-white' : 'bg-dark-silver'"
    >
      <img
        v-if="photoFile"
        :src="photoFile.url"
        class="w-full absolute top-0 left-0"
      />

      <template v-if="editable">
        <div class="flex flex-wrap p-4 w-full">
          <ad-shop-link
            :ad-shop-slug="adShopSlug"
            class="shadow-20 w-100 self-start flex-shrink"
          />

          <div class="flex justify-end flex-1">
            <edit-styling-button
              :is-disabled="!$can('pub_edit_styling')"
              :active="editing"
              @edit="$emit('edit-styling')"
              @cancel="$emit('cancel')"
              @publish-changes="$emit('publish-changes')"
            />
            <el-button
              :disabled="!$can('pub_edit_settings')"
              class="border-none shadow button-circle button-white-blue z-15 ml-2 relative"
              circle
              @click="$emit('edit-settings')"
            >
              <icon :icon="cogIcon" class="icon-lg" />
            </el-button>
          </div>
        </div>
        <div
          class="edit-photo-container overlay flex items-center justify-center pointer-events-none"
        >
          <photo-upload-button
            class="z-15 pointer-events-auto"
            :is-disabled="!$can('pub_edit_styling')"
            :photo-exists="!!photoFile"
            @editing="$emit('edit-styling')"
            @change="$emit('change', $event)"
            @click-remove-photo="$emit('click-remove-photo')"
          />
        </div>
        <transition name="fade" :duration="200">
          <div v-if="editing" class="overlay overlay-shadow z-10"></div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
import { colors } from '@/tailwind';
import { cog as cogIcon } from '@/vendor/icons/index';
import AdShopLink from '@/components/AdShop/ShopHeader/HeaderPhoto/AdShopLink';
import EditStylingButton from '@/components/AdShop/ShopHeader/HeaderPhoto/EditStylingButton';
import PhotoUploadButton from '@/components/AdShop/ShopHeader/HeaderPhoto/PhotoUploadButton';

/* HeaderPhoto
 *
 * Props:
 *   'theme-color'
 *   'ad-shop-slug' used to create Ad Shop Link
 *   'photo-url' used as background image
 *   'editable' to display editing UI
 *   'editing' to display Cancel and Publish Changes button
 *
 * Emits:
 *   'edit-style' on pencil FAB click
 *   'edit-settings' on cog FAB click
 *   'click-upload-photo' on upload photo action
 *   'click-remove-photo' on remove photo action
 *   'publish-changes' when Publish Changes button clicked
 *   'cancel' when Cancel button clicked
 */
export default {
  components: { PhotoUploadButton, EditStylingButton, AdShopLink },
  props: {
    themeColor: {
      type: String,
      default: () => colors.blue
    },
    photoFile: { type: Object, default: null },
    editable: Boolean,
    editing: Boolean
  },
  data() {
    return {
      cogIcon
    };
  },

  computed: {
    adShopSlug() {
      return this.$store.getters['adShop/slug'];
    },
    showHeader() {
      return this.photoFile || this.editable;
    }
  }
};
</script>

<style lang="scss" scoped>
.background-photo {
  padding-bottom: 23.44%;
}
</style>
