<template>
  <div class="customer-reps-picker">
    <customer-reps-query
      :loading.sync="isLoadingCustomerReps"
      :buyer-id="buyerId"
      @data="customerReps = $event.buyerReps"
    />

    <campaign-reps-query
      :loading.sync="isLoadingCampaignReps"
      :buyer-id="buyerId"
      :campaign-id="campaign.id"
      @data="
        campaignReps =
          $event.customer.buyer.campaign && $event.customer.buyer.campaign.reps
      "
    />

    <transition name="fade" mode="out-in">
      <loader-box v-if="isLoading" class="mt-3 w-1/2 ml-auto mr-auto" />
      <div v-else-if="customerReps && campaignReps">
        <campaign-rep-mutation :show-error-message="false">
          <template slot-scope="{ addCampaignRep, removeCampaignRep }">
            <div class="py-12 px-6">
              <rep-picker
                title="Campaign"
                class="mx-auto w-1/2"
                :available-reps="customerReps"
                :current-reps="campaignReps"
                :is-saving-id="isSavingId"
                @add="
                  addCampaignRep({ ...campaign, reps: campaignReps }, $event)
                "
                @remove="onRemove(removeCampaignRep, $event)"
              />
            </div>
          </template>
        </campaign-rep-mutation>
      </div>
    </transition>
  </div>
</template>

<script>
import RepPicker from '@/components/Supplier/Common/RepPicker';
import { CampaignRepMutation } from '@/components/Mutations';
import { CampaignRepsQuery, CustomerRepsQuery } from '@/components/Queries';
import { LoaderBox } from '@/components/Core/Loaders';

export default {
  components: {
    CampaignRepMutation,
    CampaignRepsQuery,
    CustomerRepsQuery,
    LoaderBox,
    RepPicker
  },
  props: {
    buyerId: {
      type: String,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      customerReps: null,
      campaignReps: null,
      isLoadingCustomerReps: false,
      isLoadingCampaignReps: false,
      isSavingId: null
    };
  },
  computed: {
    isLoading() {
      return this.isLoadingCampaignReps || this.isLoadingCustomerReps;
    }
  },
  methods: {
    async onRemove(removeCampaignRep, repId) {
      this.isSavingId = repId;

      await removeCampaignRep(this.campaign.id, repId);
    }
  }
};
</script>
