<template>
  <div
    class="flex justify-between items-center rounded shadow-gray-1 p-3 mb-4 border-reset border-l-4"
    :class="`border-${notificationColor}`"
  >
    <div class="flexbox-row">
      <icon
        :icon="icon ? icon : notificationIcon"
        :class="[`text-${notificationColor}`, iconClass]"
      />
      <span v-if="text" class="text-md">
        {{ text }}
      </span>
      <span v-else class="text-md">
        <slot name="text"></slot>
      </span>
    </div>
    <slot name="button"></slot>
  </div>
</template>

<script>
import { attention, cancel, check, exclamationCircle } from '@/vendor/icons';

export default {
  name: 'FlyteStickyNotification',

  props: {
    warning: Boolean,
    success: Boolean,
    info: Boolean,
    error: Boolean,
    text: {
      type: String,
      default: null
    },
    icon: {
      type: Object,
      default: null
    },
    iconClass: {
      type: String,
      default: 'mr-3 icon-lg'
    }
  },

  computed: {
    notificationColor() {
      if (this.success) {
        return 'green';
      } else if (this.info) {
        return 'blue';
      } else if (this.error) {
        return 'red';
      } else {
        return 'yellow';
      }
    },

    notificationIcon() {
      if (this.success) {
        return check;
      } else if (this.info) {
        return exclamationCircle;
      } else if (this.error) {
        return cancel;
      } else {
        return attention;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sticky-notification {
  // border-left-style: solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  .status-icon {
    font-size: 20px;
  }
}
</style>
