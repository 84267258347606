<template>
  <div class="flex mt-1 mb-1">
    <span class="w-1/2 font-semibold mr-4">
      <slot></slot>
    </span>
    <span class="w-1/2 dark-grey">
      <slot name="value">
        {{ value }}
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    }
  }
};
</script>
