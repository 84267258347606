<template>
  <div class="flex justify-center text-center">
    <div class="flex-col justify-center mx-auto w-3/4">
      <template v-if="takeoverBuyerId">
        <h2 class="mb-4">
          Order {{ submittedOrder.ref }} Booked Successfully!
        </h2>
        <p>
          If your artwork is ready to upload, you can do that now by clicking
          below to view your Orders page.
        </p>

        <customer-reps-picker
          :buyer-id="takeoverBuyerId"
          :campaign="submittedOrder.campaign"
        />

        <div class="mt-6">
          <!-- A Supplier sees a link to return to customers page -->
          <div>
            <router-link :to="{ name: 'supplier.customers' }">
              <el-button class="themed is-plain w-56" type="plain">
                Return to Customers
              </el-button>
            </router-link>
          </div>

          <div class="mt-3">
            <router-link :to="{ name: 'supplier.orders' }">
              <el-button class="button-blue w-56">
                View Orders
              </el-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-else>
        <h2 class="mb-4">
          Campaign {{ submittedOrder.campaign.ref }} Booked Successfully!
        </h2>
        <p>
          If your artwork is ready to upload, you can do that now by clicking
          below to view your Campaigns page.
        </p>

        <div class="mt-6">
          <!-- A buyer sees a link to view upcoming campaigns -->
          <router-link :to="{ name: 'buyer.dashboard' }">
            <el-button class="button-blue themed" type="primary">
              View Campaigns
            </el-button>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CustomerRepsPicker from '@/components/AdShop/Cart/CustomerRepsPicker';

export default {
  components: {
    CustomerRepsPicker
  },
  computed: {
    takeoverBuyerId() {
      return this.$store.getters['adShop/takeoverBuyerId'];
    },
    submittedOrder() {
      return this.$store.getters[`adShop/submittedOrder`];
    }
  },
  mounted() {
    // Make sure the cart is cleared!
    this.$store.commit('adShop/setAdShopCartId', null);
    setTimeout(() => this.$store.dispatch('auth/reset'), 2000);
  }
};
</script>
