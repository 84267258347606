<template>
  <div class="ad-shop-link">
    <el-input ref="elInput" :value="adShopUrl" readonly @click.native="copyUrl">
      <template slot="prepend">Ad Shop Link:</template>
      <el-popover
        slot="append"
        v-model="showCopiedMessage"
        popper-class="copied-popover"
        trigger="manual"
      >
        Copied!
        <el-button slot="reference" class="copy-button button-blue">
          Copy
        </el-button>
      </el-popover>
    </el-input>
  </div>
</template>

<script>
import { find } from 'lodash';
import { copyToClipboard } from '@/utils/helpers';

export default {
  name: 'AdShopLink',
  props: {
    adShopSlug: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showCopiedMessage: false
    };
  },
  computed: {
    adShopUrl() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'adShop',
          params: {
            shopSlug: this.adShopSlug
          }
        }).href
      );
    },

    inputElement() {
      return find(this.$refs.elInput.$el.children, { nodeName: 'INPUT' });
    }
  },
  methods: {
    copyUrl() {
      copyToClipboard(this.adShopUrl);
      this.inputElement.select();

      this.showCopiedMessage = true;
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
.copied-popover {
  width: auto;
  min-width: auto;
  font-weight: bold;
}
</style>

<style scoped lang="scss">
@import '~@/scss/_variables';

.ad-shop-link /deep/ {
  .el-input-group__prepend {
    background-color: white;
    border: none;
    color: $color-dark-gray;
    font-weight: bold;
    padding-right: 0.5em;
  }

  .el-input__inner {
    background-color: white;
    border: none;
    color: $color-dark-gray;
    padding-left: 0.5em;
  }

  .el-input-group__append {
    border: none;
    background-color: $color-blue;
    color: white;
  }
}
</style>
