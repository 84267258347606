<template>
  <transition appear name="fadeDown">
    <div class="adshop-terms-dialogs">
      <el-dialog
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible="true"
        center
        title="Accept Ad Shop Terms and Conditions"
        width="50%"
        @update:visible="$emit('close')"
      >
        <div class="overflow-y-scroll h-half-screen">
          <h2 class="has-content-centered">Flytedesk, inc.</h2>
          <h3>Ad Shop – General Terms and Services Agreement</h3>
          This Ad Shop Services Agreement (the “Agreement”) is made effective
          upon enabling the Ad Shop by and between flytedesk, inc., a Colorado
          corporation (“flytedesk”) and the publisher identified on the
          flytedesk member account (“Publisher”). The parties agree as follows:
          <br /><br />
          <ol class="pl-4">
            <li>
              <h3>AD SHOP SERVICES.</h3>
              <ol class="pl-4">
                <li>
                  <b>Ad Shop.</b> During the Term, and subject to the terms and
                  conditions of this Agreement, flytedesk will provide Publisher
                  with the services identified herein. The services shall
                  consist of the web site hosted by flytedesk and operated by
                  Publisher (the “Ad Shop”) that serves as a customized campaign
                  booking and management tool that allows advertisers to
                  purchase ad space.
                </li>
                <li>
                  <b>Publisher Portal and Verification Services.</b> flytedesk
                  will provide Publisher with access to the flytedesk publisher
                  portal (the “Portal”) so that Publisher may make selections
                  regarding the flytedesk services Publisher wishes to enable
                  and so that Publisher may access the verification tools (the
                  “Verification Services”) offered through the Portal (together
                  with the Ad Shop, the Professional Services and the Portal,
                  the “Services”).
                </li>
                <li>
                  <b>Non-Exclusivity.</b> During the Term, Publisher may use the
                  Ad Shop as its non-exclusive method of selling ad space or
                  booking advertising campaigns for its publications, digital
                  offerings, or other media inventory maintained by Publisher
                  (“Inventory”), unless otherwise agreed upon.
                </li>
              </ol>
            </li>
            <br /><br />
            <li>
              <h3>PUBLISHER OBLIGATIONS</h3>
              <ol class="pl-4">
                <li>
                  <b>Account Security.</b> Publisher must create a
                  password-protected account in order to use the Services.
                  Publisher accounts are personal to each Publisher, and may not
                  be shared with or serviced by third parties. Publisher may
                  invite additional users to access the Services as part of
                  Publisher’s account. Publisher is responsible for protecting
                  the security of its publisher account and for any access to or
                  use of it, whether or not specifically authorized by
                  Publisher. Publisher agrees to use industry-standard security
                  practices to protect its publisher account credentials, and to
                  notify flytedesk immediately of any unauthorized access or use
                  or other security breach; flytedesk disclaims all liability,
                  whether to Publisher or any third party, that arises based on
                  Publisher’s breach of this Section 2.1.
                </li>
                <li>
                  <b>Requirements and Restrictions.</b> All publishers using
                  Services must comply with the following requirements and
                  restrictions. Flytedesk retains the right to suspend or
                  terminate Publisher’s publisher account or this Agreement if
                  Publisher breaches any of these obligations; breach may also
                  subject Publisher to federal, state, or local legal
                  consequences.
                  <ol class="pl-4">
                    <li>
                      <b>Permitted Use Only.</b> Publisher will only use the
                      services for its own benefit.
                    </li>
                    <li>
                      <b>Prohibited Acts.</b> Publisher will not, directly or
                      indirectly: (i) use automated tools such as bots or
                      spiders to access or use the Services or to store, copy,
                      modify, distribute, or resell any Service Content; (ii)
                      circumvent or disable any digital rights management, usage
                      rules, or other security features of the Services; (iii)
                      interfere with the Services’ proper working, overburden
                      any flytedesk infrastructure, or otherwise harm their
                      integrity, performance, or availability; (iv) remove,
                      alter, or obscure any copyright, trademark or other
                      proprietary notices on Service Content; or (v) circumvent
                      the Services’ functionality (e.g., by auto-spawning pages,
                      hijacking an end user’s device, generating spontaneous
                      advertisement refreshes) or engage in any form of fraud or
                      deception.
                    </li>

                    <li>
                      <b>Data From Children Under 13.</b> Publisher shall not
                      use the Services to collect or send to flytedesk “personal
                      information” of children as defined under applicable law,
                      including but not limited to the U.S. Children’s Online
                      Privacy Protection Act, 15 U.S.C. §§ 6501–6506 (“COPPA”),
                      whether through (i) a child-directed application (whether
                      or not such expressly identified as such), or (ii) from an
                      individual that Publisher has knowledge is under 13.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <br /><br />
            <h3>PAYMENTS</h3>
            General. All payments will be made in U.S. dollars unless we have
            agreed in writing to use an alternate currency. We and you will each
            bear our own costs except as otherwise specified in this Agreement.
            Revenue Share. Publisher will pay to flytedesk a Revenue Share based
            on Advertising Revenue generated through the use of Services.
            “Revenue Share” means, five percent (5%) of all Advertising Orders
            processed through any form of payment made directly to the
            Publisher, of the gross Advertising Revenue paid or payable to
            Publisher, plus any applicable credit card fees, unless otherwise
            agreed upon. “Advertising Revenue” means amounts paid by advertisers
            either with a credit card through the flytedesk payment processing
            services offered as part of the Ad Shop Services, or through any
            other form of payment made directly to Publisher in connection with
            the Ad Shop Services. Payment. The flytedesk system will be the
            system of record for all transactions completed through the Ad Shop
            with respect to the revenue share. flytedesk will provide Publisher
            with monthly statements summarizing the transactions completed
            during the previous month and the associated Advertising Revenue
            collected by flytedesk and Publisher (each an “Ad Shop Statement”).
            The Ad Shop Statement will include the amount either: (i) owed to
            flytedesk by publisher (for months when the Revenue Share owed to
            flytedesk is more than the aggregate Advertising Revenue collected
            by flytedesk), or (ii) owed to Publisher by flytedesk (for months
            when the Revenue Share owed to flytedesk is less than the aggregate
            Advertising Revenue collected by flytedesk). All payments due under
            any Ad Shop Statement shall be due within thirty (30) days of the
            date of such Ad Shop Statement. Disputes and Errors. If Publisher
            disputes in good faith the amount of any payments due hereunder,
            Publisher must notify flytedesk in writing within thirty (30) days
            from the date of the applicable invoice; failure to do so shall be
            deemed Publisher’s acceptance of the amount invoiced and an
            irrevocable waiver of Publisher’s right to dispute the Fees due. If
            flytedesk identifies a payment error affecting Publisher (whether
            over- or under-payment), then flytedesk must notify Publisher in
            writing within thirty (30) days from the date of the applicable
            invoice. Then flytedesk will pay Publisher (or, if applicable,
            require Publisher to refund to flytedesk) the amount at issue within
            30 days. INTELLECTUAL PROPERTY OWNERSHIP. As between the parties,
            and subject to the licenses expressly granted in this Agreement:
            flytedesk and its licensors own and will retain all right, title,
            and interest in and to the Services, including all related
            information and software (and improvements and updates).
            CONFIDENTIALITY. Each of the parties agrees not to disclose the
            other’s Confidential Information, and to use it only to fulfill its
            obligations or exercise its rights under this Agreement.
            “Confidential Information” means information identified as
            “confidential” or “proprietary,” or that should reasonably be
            understood to be confidential.
            <br /><br />
            <h3>TERM AND TERMINATION.</h3>
            Term. This Agreement will commence on the Effective Date and, unless
            earlier terminated in accordance with Section 6.2, shall remain in
            effect for a period of one (1) year (the “Initial Term”).
            Thereafter, this Agreement shall automatically renew for successive
            one (1) year terms (each a “Renewal Term” and together with the
            Initial Term, the “Term”), unless either party notifies the other
            party of its desire to not renew the agreement at least sixty (60)
            prior to the end of the then then current Initial Term or Renewal
            Term. Termination. Either party may terminate this Agreement for
            breach if the breaching party has not cured such breach within
            thirty (30) days of the receipt of written notice thereof from the
            non-breaching party. Termination does not relieve either party of
            any obligation to pay amounts due and owing as of the termination
            date; that obligation survives termination. MARKETING. For the term
            of this agreement, Publisher hereby grants flytedesk a royalty-free,
            fully paid up, sub-licensable, transferable, nonexclusive, worldwide
            license to reproduce, display, distribute, and otherwise use, in
            connection with the Services, the trademarks, service marks, logos
            or other indicia of origin associated with Publisher and its
            publication(s) (the “Marks”), for the purpose of promoting Publisher
            and its publication(s) in flytedesk’s advertising, marketing,
            promotions and promotional materials. Publisher agrees that
            flytedesk may use the Marks on the flytedesk website to show
            Publisher’s participation and use of the Services; for other
            proposed uses of the Marks, flytedesk will request Publisher’s prior
            written approval, which approval will wholly be in the Publisher’s
            discretion.
            <br /><br />
            <h3>REPRESENTATIONS, WARRANTIES, AND COVENANTS.</h3>
            General. flytedesk and Publisher, each acting on its own behalf,
            each represent and warrant that: (a) it has and will maintain all
            necessary rights, power, licenses and authority to enter into this
            Agreement, to perform the acts required of it under this Agreement,
            and to permit the other party to perform its obligations
            contemplated under this Agreement; and (b) it is and will remain in
            compliance with all applicable laws, statutes, ordinances, and
            regulations (including but not limited to, any relevant data
            protection or privacy laws) in the performance of its obligations
            under this Agreement. By Publisher. Publisher further represents,
            warrants, and covenants that: Publisher’s performance under this
            Agreement does not and will not violate any contractual or other
            obligations between Publisher and any third party. By flytedesk.
            flytedesk further represents, warrants, and covenants that:
            flytedesk’s performance under this Agreement does not and will not
            violate any contractual or other obligations between flytedesk and
            any third party.
            <br /><br />
            <h3>INDEMNIFICATION.</h3>
            Mutual Indemnification. Each party will indemnify, defend and hold
            harmless the other party and its officers, directors, and employees
            from and against any Losses arising out of or related to: (a) claims
            that the Services infringe any third party’s intellectual property
            rights, privacy, rights of publicity, or other rights; or (b) a
            party’s failure to comply with applicable law. Indemnity
            Requirements. The indemnifying party reserves the right, at its
            expense, to provide the indemnified party with prompt written notice
            of its intention to assume the exclusive defense and control of any
            matter for which the indemnifying party is required to indemnify the
            indemnified party (absent which, the indemnified party shall control
            such defense at the indemnifying party’s cost), and the indemnified
            party agrees to reasonably cooperate with the indemnifying party’s
            defense of such claims at the indemnifying party’s expense. The
            indemnifying party shall not enter into any settlement for which
            indemnity is sought unless: (a) such settlement includes an
            unconditional release of the indemnified party and its affiliates
            from all liability on all claims; and (b) the indemnified party
            gives its prior written approval, which shall not be unreasonably
            withheld.
            <br /><br />
            <h3>DISCLAIMERS.</h3>
            EXCEPT AS EXPRESSLY PROVIDED IN SECTIONS 8 AND 9 HEREIN, AND AS SET
            FORTH IN THE SCOPE OF WORK, FLYTEDESK DISCLAIMS ALL WARRANTIES,
            WHETHER EXPRESS, IMPLIED OR STATUTORY, IN CONNECTION WITH THE
            PROVISION OR PUBLISHER’S USE OF THE SERVICES, ANY ADVERTISEMENT, AND
            ANY OTHER FLYTEDESK PRODUCTS OR SERVICES, IN EACH CASE TO THE
            FULLEST EXTENT PERMITTED BY LAW. WITHOUT LIMITING THE GENERALITY OF
            THE FOREGOING, THE SERVICES ARE PROVIDED “AS-IS” AND WITHOUT
            WARRANTIES OF ANY KIND; THIS INCLUDES, WITHOUT LIMITATION,
            WARRANTIES OF PERFORMANCE AND IMPLIED WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. FURTHER,
            FLYTEDESK DOES NOT REPRESENT OR WARRANT THAT THE SERVICES ARE OR
            WILL BE ACCURATE, COMPLETE, RELIABLE, CURRENT, ERROR-FREE,
            VIRUS-FREE, OR UNINTERRUPTED. SOME STATES DO NOT ALLOW EXCLUSION OF
            AN IMPLIED WARRANTY, SO THIS DISCLAIMER MAY NOT APPLY TO PUBLISHER.
            FLYTEDESK DOES NOT WARRANT OR GUARANTEE: (A) THE RESULTS OF USE OF
            THE SERVICES, INCLUDING THAT PUBLISHER WILL EARN ANY PARTICULAR
            AMOUNTS (OR ANY AMOUNTS AT ALL); OR (B) THE RESULTS OF CONSULTING,
            DEVELOPMENT, OR OTHER SERVICES PROVIDED BY FLYTEDESK. Without
            limiting the generality of the foregoing, Publisher acknowledges and
            agrees that flytedesk is not affiliated with or responsible for any
            third-party products or services displayed, distributed or otherwise
            promoted through the Services, including the advertisements and the
            products or services so advertised. flytedesk neither represents nor
            endorses the quality, accuracy, reliability, integrity or legality
            of any third-party products or services, nor the truth or accuracy
            of the description of any advertisements, links, content, advice,
            opinions, offers, proposals, statements, data, or other information
            from any third-party products or services that are displayed,
            distributed, or otherwise used on or in connection with the
            Services.
            <br /><br />
            <h3>LIMITATION OF LIABILITY.</h3>
            IN NO EVENT WILL FLYTEDESK BE LIABLE TO PUBLISHER OR ANY OTHER
            PERSON OR ENTITY, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
            OR OTHERWISE, FOR INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR
            CONSEQUENTIAL DAMAGES, LOST INCOME, REVENUE OR PROFITS, LOST OR
            DAMAGED DATA, OR OTHER COMMERCIAL OR ECONOMIC LOSS ARISING OUT OF
            THIS AGREEMENT, EVEN IF FLYTEDESK KNEW OR HAD BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. FLYTEDESK’S MAXIMUM AGGREGATE LIABILITY
            IN RESPECT OF ALL LOSSES ARISING OUT OF THIS AGREEMENT, WHETHER IN
            CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, WILL NOT EXCEED
            TEN THOUSAND DOLLARS ($10,000) USD. APPLICABLE LAW MAY NOT ALLOW THE
            LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
            PUBLISHER; IN SUCH CASES, FLYTEDESK’S LIABILITY WILL BE LIMITED TO
            THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW. THIS LIMITATION OF
            LIABILITY PROVISION IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE
            BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. FLYTEDESK WOULD NOT
            PROVIDE THE SERVICES WITHOUT SUCH LIMITATIONS, AND PUBLISHER AGREES
            THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS, AND
            EXCLUSIVE REMEDIES SPECIFIED IN THIS AGREEMENT ARE FAIR AND
            REASONABLE. BOTH PARTIES ACKNOWLEDGE AND AGREE THAT ANY CLAIM
            ARISING OUT OF OR RELATING TO THIS AGREEMENT MUST BE RAISED WITHIN 3
            YEARS FROM THE DATE OF ITS ACCRUAL, OR IT SHALL BE FOREVER WAIVED.
            NOTHING IN THIS AGREEMENT SHALL LIMIT OR EXCLUDE EITHER PARTY’S
            LIABILITY FOR: (A) DEATH OR PERSONAL INJURY CAUSED BY ITS NEGLIGENCE
            OR THE NEGLIGENCE OF ITS EMPLOYEES, AGENTS OR SUBCONTRACTORS; (B)
            FRAUD OR FRAUDULENT MISREPRESENTATION; OR (C) ANY OTHER LIABILITY
            THAT CANNOT BE EXCLUDED OR LIMITED BY LAW.
            <br /><br />
            <h3>GOVERNING LAW AND DISPUTE RESOLUTION.</h3>
            Governing Law. The parties agree that Colorado law governs this
            Agreement, without giving effect to its principles of conflicts of
            law. Dispute Resolution. Negotiation. In the event of any dispute
            arising out of or in connection with this Agreement, including
            without limitation its validity and interpretation, as a condition
            precedent to commencing any action in any court, the aggrieved party
            shall give the other party written notice of the matter which the
            aggrieved party considers to be in dispute, and if the dispute is
            not settled by negotiation between the senior executives of each of
            the Parties within thirty (30) days of receipt by the other party of
            such notice, then the dispute shall be submitted to mediation.
            Mediation. Mediation of any dispute shall be conducted promptly upon
            submission of the dispute to mediation and the Parties agree to
            participate in such mediation in good faith with a view to achieving
            a mutually satisfactory resolution of the dispute. Mediation shall
            be conducted by such mediator, mediation facility or organization as
            the Parties shall agree upon. If no such agreement is reached within
            forty-five (45) days of receipt by the other party of such notice,
            then the dispute shall be submitted to mediation by a mutually
            acceptable mediation service regularly rendering such services
            within the State of Colorado. Costs of mediation shall be shared
            equally by the Parties. The place of mediation shall be as the
            Parties shall agree upon. If no such agreement is reached within ten
            (10) days of the agreement or designation of a mediator or mediation
            service, then the place of mediation shall be established by the
            agreed upon or designated mediator or mediation service. Mediation
            of the dispute shall be completed within fifteen (15) days of its
            commencement unless the Parties extend such time by mutual
            agreement, or the mediator earlier declares the Parties to be at an
            impasse. In the event of such declaration or completion of mediation
            without resolution of the dispute, either party may submit the
            dispute to a court of competent jurisdiction.
            <br /><br />
            <h3>MISCELLANEOUS.</h3>
            This Agreement sets forth the entire agreement between the parties,
            and supersedes any and all prior agreements (whether written or
            oral) with respect to its subject matter. This Agreement may not be
            modified or amended without the express written agreement of the
            parties. Neither party can assign this Agreement without the other
            party’s prior written consent, and any attempt will be null and
            void. It is binding upon permitted successors and assigns, and there
            are no third-party rights unless expressly specified. The parties
            are independent contractors, not agents, representatives, or
            partners. Waiver of any breach of this Agreement will not constitute
            a waiver of any other breach, and will not act to reduce the rights
            of the waiving party. Sections 3 (with respect to any payment
            obligations then existing), 4.3, 5 through 7, and 9 through 14
            survive termination. Neither party will be liable for failure to
            perform due to a cause beyond such party’s reasonable control,
            respectively, including, without limitation, terrorism, fire, civil
            disturbance, war, rebellion, earthquake, flood and similar
            occurrences, provided that performance resumes as soon as
            commercially practicable after the cause no longer prevents
            performance. Headings are for convenience only and shall not affect
            interpretation. Notice to flytedesk shall be made via email to
            support@flytedesk.com or to the applicable flytedesk account
            manager, where applicable; notice to Publisher shall be made to the
            email address in Publisher’s publisher account. Emailed notice is
            effective as of the email date, absent receipt by the sender of a
            bounce back or error message or other direct indication of
            nonreceipt. The parties may execute this Agreement in one or more
            counterparts.
          </ol>
        </div>

        <template slot="footer">
          <template v-if="areTermsAccepted">
            <el-button
              slot="button"
              class="button-blue"
              @click="$emit('close')"
            >
              Close
            </el-button>
          </template>

          <template v-else>
            <ad-shop-mutation :ad-shop="adShop" class="w-full">
              <template slot-scope="{ isSaving, acceptAdShopTerms }">
                <notification-mutation class="flex">
                  <template slot-scope="{ toggleRead }">
                    <template v-if="!isSaving">
                      <el-button
                        slot="button"
                        class="button-red"
                        @click="showConfirmCancelDialog = true"
                      >
                        Reject
                      </el-button>
                      <el-button
                        slot="button"
                        class="button-green"
                        @click="
                          submitTermsAcceptance(
                            toggleRead,
                            acceptAdShopTerms,
                            true
                          )
                        "
                      >
                        Accept
                      </el-button>
                    </template>

                    <loading-button v-else />
                  </template>
                </notification-mutation>
              </template>
            </ad-shop-mutation>
          </template>
        </template>
      </el-dialog>

      <notification-mutation>
        <template slot-scope="{ toggleRead }">
          <ad-shop-mutation :ad-shop="adShop" class="w-full">
            <template slot-scope="{ isSaving, acceptAdShopTerms }">
              <confirm-dialog
                v-if="showConfirmCancelDialog"
                :is-saving="isSaving"
                :modal="false"
                confirm-class="button-red"
                cancel-class="button-white-blue"
                title="Are you sure?"
                @close="showConfirmCancelDialog = false"
                @confirm="
                  submitTermsAcceptance(toggleRead, acceptAdShopTerms, false)
                "
              >
                Your Ad Shop will be disabled if you reject the Terms and
                Conditions. Are you sure you want to Reject?
                <template slot="cancel-text">
                  Never mind
                </template>
              </confirm-dialog>
            </template>
          </ad-shop-mutation>
        </template>
      </notification-mutation>
    </div>
  </transition>
</template>

<script>
import { AdShopMutation, NotificationMutation } from '@/components/Mutations';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import LoadingButton from '@/components/Core/Loading/LoadingButton';

export default {
  components: {
    AdShopMutation,
    ConfirmDialog,
    LoadingButton,
    NotificationMutation
  },

  props: {
    termsNotification: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showConfirmCancelDialog: false
    };
  },

  computed: {
    supplier() {
      return this.$store.getters['auth/supplier'];
    },
    adShop() {
      return this.supplier.adShop;
    },
    areTermsAccepted() {
      if (!this.termsNotification) {
        return this.$store.getters['auth/areAdShopTermsAccepted'];
      }

      return false;
    }
  },

  methods: {
    async submitTermsAcceptance(toggleRead, acceptAdShopTerms, accepted) {
      if (this.termsNotification) {
        toggleRead(this.termsNotification, true);
      }

      acceptAdShopTerms(accepted);

      if (accepted) {
        this.$message.success('Your Ad Shop has been enabled!');
      } else {
        this.$message.warning('Your Ad Shop has been disabled!');
      }

      this.$emit('change', accepted);
      this.$emit('close');
    }
  }
};
</script>
