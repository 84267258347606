<template>
  <!-- "x-placement" and "x-arrow" are neccesary here to ensure no dynamic stlying is performed by the "el-tooltip__popper" class on the carte arrow-->
  <div class="bg-white el-tooltip__popper" x-placement="bottom">
    <div class="popper__arrow white-arrow" :style="arrowStyle" x-arrow></div>
    <slot />
    <div v-if="!$slots.default" class="flex flex-row text-left">
      <span class="w-4 flex-shrink-0" :style="labelStyle"> </span>
      <span class="ml-2 flex-shrink-0">{{ firstBodyLine }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tooltipModel: {
      type: Object,
      default: null
    }
  },
  computed: {
    arrowStyle() {
      return {
        left: `${this.tooltipModel.width / 2}px`,
        top: '-6px',
        transform: 'none !important'
      };
    },
    firstBodyLine() {
      return this.tooltipModel.body
        ? this.tooltipModel.body[0].lines[0].replace('%', '') + '%'
        : '';
    },
    labelStyle() {
      return {
        backgroundColor: this.tooltipModel.labelColors
          ? this.tooltipModel.labelColors[0].backgroundColor
          : 'white'
      };
    }
  }
};
</script>
