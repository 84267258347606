<template>
  <div class="profile-photo">
    <transition name="fade" mode="out-in" :duration="200">
      <router-link
        v-if="!editing"
        :to="{
          name: 'adShop',
          params: { shopSlug: this.$store.getters['adShop/slug'] }
        }"
      >
        <div
          :style="imageClass"
          class="bg-center bg-contain h-0 pb-full bg-no-repeat"
        ></div>
      </router-link>
      <div
        v-else
        key="edit-photo"
        :style="imageClass"
        :class="photoFile ? 'bg-white' : 'bg-dark-silver'"
        class="bg-center bg-contain bg-no-repeat h-0 pb-full relative"
      >
        <el-upload
          ref="uploader"
          class="profile-photo-upload"
          action="/this-should-not-happen"
          :show-file-list="false"
          :on-change="onChange"
          :auto-upload="false"
        >
          <div
            class="flex items-center justify-center absolute bg-black cursor-pointer hover:opacity-50 opacity-30 inset-0 z-10"
          >
            <div class="font-bold text-center text-white">{{ editText }}</div>
          </div>
          <div
            class="flex camera-icon items-center justify-center absolute bg-white -mr-2 -mt-2 top-0 right-0 rounded-circle shadow-20 text-blue w-8 h-8 z-20"
          >
            <icon :icon="cameraIcon" />
          </div>
        </el-upload>
      </div>
    </transition>
  </div>
</template>

<script>
import { camera as cameraIcon } from '@/vendor/icons';

export default {
  name: 'ProfilePhoto',
  props: {
    editing: Boolean,
    photoFile: { type: Object, default: null }
  },
  data() {
    return {
      cameraIcon
    };
  },
  computed: {
    imageClass() {
      return {
        'background-image': this.photoFile
          ? `url("${this.photoFile.url}")`
          : undefined
      };
    },
    editText() {
      if (this.photoFile) {
        return 'Update Profile Photo';
      } else {
        return 'Add Profile Photo';
      }
    }
  },

  methods: {
    onChange(file) {
      this.$emit('change', file);
    }
  }
};
</script>
