<template>
  <div>
    <p>
      <strong>{{ name }}</strong>
    </p>
    <p>Due: {{ deadlineDays }} days before run date</p>
    <p>First Due Date: {{ firstDueDate | date }}</p>
  </div>
</template>

<script>
export default {
  name: 'CartFulfillmentMethod',

  props: {
    name: {
      type: String,
      required: true
    },
    deadlineDays: {
      type: Number,
      required: true
    },
    firstDueDate: {
      type: [Date, Object],
      required: true
    }
  }
};
</script>
