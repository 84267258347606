<template>
  <header-layout :class="{ 'bg-blue': isSupplier }">
    <template>
      <ad-shop-query :buyer-id="buyerId">
        <template slot-scope="{ isLoading, data }">
          <div v-if="!isLoading" class="flex items-center justify-end">
            <router-link
              class="header-logo"
              :class="{ 'text-white hover:text-white': isSupplier }"
              :to="linkTo"
            >
              <div class="img-holder">
                <img
                  v-if="data.profilePhotoUrl"
                  alt="Profile"
                  :src="data.profilePhotoUrl"
                />
              </div>
              <span>{{ data.shop_name }}</span>
            </router-link>
          </div>
        </template>
      </ad-shop-query>
      <div class="flex items-center justify-end">
        <shop-toolbar
          :icon-class="isSupplier ? 'text-white' : 'text-dark-silver'"
        />
      </div>
    </template>
  </header-layout>
</template>

<script>
import HeaderLayout from './HeaderLayout';
import ShopToolbar from '@/components/AdShop/ShopHeader/ShopInfo/ShopToolbar';
import { AdShopQuery } from '@/components/Queries';
import { mapGetters } from 'vuex';

export default {
  components: {
    AdShopQuery,
    HeaderLayout,
    ShopToolbar
  },
  props: {
    linkTo: {
      type: Object,
      default: () => ({ name: 'home' })
    }
  },
  computed: {
    ...mapGetters({
      isSupplier: 'auth/isSupplier',
      buyerId: 'auth/buyerId'
    })
  }
};
</script>
