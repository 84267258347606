<template>
  <div>
    <p :class="{ 'line-through': hasAdjustedPrice(productDate) }">
      <strong>{{ productDate.total_base_price | currency }}</strong>
    </p>
    <p v-if="hasAdjustedPrice(productDate)">
      <strong>{{ productDate.total_adjusted_price | currency }}</strong>
    </p>
  </div>
</template>

<script>
import CartMixin from '@/components/AdShop/Mixins/CartMixin';

export default {
  mixins: [CartMixin],
  props: {
    productDate: { type: Object, default: null }
  }
};
</script>
