<template>
  <login-mutation
    class="w-1/3 border-bottom mb-4 min-w-25 pb-10"
    @data="onData"
    @error="onError"
  >
    <template v-slot="{ loginUser }">
      <div>
        <el-form
          ref="signinForm"
          :disabled="isLoggingIn"
          label-position="top"
          :rules="rules"
          :model="signinForm"
        >
          <custom-el-form-item
            class="mt-4"
            label-class="font-bold"
            label="Email"
            prop="email"
          >
            <el-input
              v-model="signinForm.email"
              placeholder="Email"
              name="email"
              @keyup.enter.native="$refs.passwordInput.focus()"
            />
          </custom-el-form-item>
          <custom-el-form-item
            class="mt-4"
            label-class="font-bold"
            label="Password"
            prop="password"
          >
            <el-input
              ref="passwordInput"
              v-model="signinForm.password"
              type="password"
              name="password"
              placeholder="Password"
              @keyup.enter.native="submitForm(loginUser)"
            />
          </custom-el-form-item>
        </el-form>

        <div class="mt-8">
          <el-button
            v-if="!isLoggingIn"
            class="button-blue w-full"
            @click="submitForm(loginUser)"
          >
            Sign In
          </el-button>
          <loading-button v-else class="w-full" />
        </div>

        <el-alert
          v-if="errorMessage"
          type="error"
          :title="errorMessage"
          class="mt-4"
          :closable="false"
        />
      </div>
    </template>
  </login-mutation>
</template>

<script>
import { validEmail } from '@/utils/filters';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { LoadingButton } from '@/components/Core/Loading';
import { LoginMutation } from '@/components/Mutations';

export default {
  components: {
    CustomElFormItem,
    LoadingButton,
    LoginMutation
  },

  data() {
    return {
      errorMessage: null,
      isLoggingIn: false,
      rules: {
        email: [{ required: true, validator: validEmail, trigger: 'blur' }],
        password: [
          {
            required: true,
            message: 'Please input a password',
            trigger: 'blur'
          }
        ]
      },
      showSignupDialog: false,
      signinForm: {
        email: '',
        password: ''
      }
    };
  },

  methods: {
    async onData(isSuccessful) {
      if (isSuccessful) {
        await this.$store.dispatch('auth/getUser');

        if (this.$store.getters['auth/isAuthenticated']) {
          this.$emit('authenticated');
          this.$refs.signinForm.resetFields();
        } else {
          this.errorMessage =
            'Unable to retrieve your account information. Please try again later.';
        }
      } else {
        this.errorMessage = 'Invalid credentials. Please try again.';
      }

      this.isLoggingIn = false;
    },
    onError(error) {
      this.isLoggingIn = false;
      this.errorMessage = error;
    },
    submitForm(loginUser) {
      this.$refs.signinForm.validate(valid => {
        if (valid) {
          this.isLoggingIn = true;
          this.errorMessage = null;
          loginUser(this.signinForm);
        }
      });
    }
  }
};
</script>
