<script>
import moment from 'moment';

export default {
  methods: {
    getBookableDates(dates, fulfillmentMethod) {
      if (!this.$store.getters['auth/isSupplier'] && fulfillmentMethod) {
        let earliestDate = moment()
          .add(fulfillmentMethod.deadline_days, 'days')
          .startOf('day')
          .subtract(1, 'minute');

        return dates.filter(date => {
          return moment(date).isAfter(earliestDate);
        });
      } else {
        return dates;
      }
    }
  }
};
</script>
