<template>
  <div ref="shopinfo" class="shop-info pt-8 max-w-2xl mx-auto">
    <div class="flex justify-between flex-start">
      <div class="flex-1 flex -m-5">
        <profile-photo
          v-if="showPhoto"
          class="m-5 w-1/4 transition-all"
          :editing="editing"
          :photo-file="adShop.profilePhotoFile"
          @change="$emit('change', { profilePhotoFile: $event })"
        />

        <text-info
          :editing="editing"
          class="w-3/4 m-5"
          :ad-shop="adShop"
          @change="$emit('change', $event)"
        />
      </div>
      <div>
        <div class="flex">
          <shop-toolbar />

          <template v-if="isSupplier">
            <!-- Buyer takeover warning -->
            <supplier-customer-query
              v-if="takeoverBuyerId"
              :buyer-id="takeoverBuyerId"
              @data="currentCustomer = $event.customer"
            />

            <div class="flex justify-end w-100 ml-5 relative">
              <div class="shopping-as text-dark-silver">Shopping As...</div>
              <transition mode="out-in" name="fade">
                <customers-autocomplete
                  v-if="isChoosingCustomer"
                  class="w-full"
                  :buyer-id="takeoverBuyerId"
                  @input="setCustomer"
                />
                <el-alert
                  v-else
                  :type="currentCustomer ? 'warning' : 'error'"
                  :closable="false"
                  title=""
                >
                  <div class="flex">
                    <div class="flex-grow">
                      <template v-if="currentCustomer">
                        {{ currentCustomer.buyer.buyer_company }}
                        ({{ currentCustomer.buyer.id }})
                      </template>
                      <template v-else>No Customer Selected</template>
                    </div>
                    <div>
                      <a @click="isChoosingCustomer = true">
                        <icon :icon="selectIcon" />
                        Change
                      </a>
                    </div>
                  </div>
                </el-alert>
              </transition>
            </div>
          </template>
        </div>
        <cart-query @data="onCartData" />
        <div v-if="existingOrder" class="mt-3">
          <el-alert title="" type="error">
            <div>
              Order #{{ existingOrder.ref }} has already been created for this
              cart.
            </div>
            <div>
              <a v-if="!isClearing" @click="clearCart"
                >Click here to start fresh</a
              >
              <a v-else>Please wait while we get you a new cart...</a>
            </div>
          </el-alert>
        </div>
      </div>
    </div>

    <hr />
  </div>
</template>

<script>
import ProfilePhoto from '@/components/AdShop/ShopHeader/ShopInfo/ProfilePhoto';
import ShopToolbar from '@/components/AdShop/ShopHeader/ShopInfo/ShopToolbar';
import TextInfo from '@/components/AdShop/ShopHeader/ShopInfo/TextInfo';
import { SupplierCustomerQuery } from '@/components/Queries';
import CartQuery from '@/components/Queries/AdShop/CartQuery';
import CustomersAutocomplete from '@/components/AdShop/CustomersAutocomplete';

import { arrowsCw as selectIcon } from '@/vendor/icons';

export default {
  components: {
    CustomersAutocomplete,
    CartQuery,
    ProfilePhoto,
    SupplierCustomerQuery,
    ShopToolbar,
    TextInfo
  },
  props: {
    adShop: {
      type: Object,
      required: true
    },
    editing: Boolean
  },
  data() {
    return {
      cart: null,
      isClearing: false,
      isChoosingCustomer: false,
      currentCustomer: null,

      // Icons
      selectIcon
    };
  },

  computed: {
    isSupplier() {
      return this.$store.getters['auth/isSupplier'];
    },
    showPhoto() {
      return this.editing || !!this.adShop.profilePhotoFile;
    },
    takeoverBuyerId() {
      return this.$store.getters['adShop/takeoverBuyerId'];
    },
    existingOrder() {
      return this.cart && this.cart.orders && this.cart.orders.length > 0
        ? this.cart.orders[0]
        : null;
    }
  },
  mounted() {
    const el = this.$refs.shopinfo;
    this.$store.commit('common/setContentOffset', el.offsetTop);
  },
  methods: {
    setCustomer(customer) {
      this.$store.commit('adShop/setTakeoverBuyerId', customer.buyer_id);
      this.isChoosingCustomer = false;
    },
    onCartData(cart) {
      this.cart = cart;
    },
    async clearCart() {
      this.isClearing = true;
      this.$store.commit('adShop/setSubmittedOrder', null);
      this.$store.commit('adShop/setAdShopCartId', null);
      this.$store.commit('adShop/setCampaignName', null);

      await this.$router.push({
        name: 'adShop',
        params: {
          shopSlug: this.$store.getters['adShop/slug']
        }
      });

      setTimeout(() => {
        this.$store.dispatch('auth/reset');
        this.isClearing = false;
      }, 2000);
    }
  }
};
</script>

<style scoped lang="scss">
.shopping-as {
  position: absolute;
  top: -1.7em;
  left: 0;
  font-size: 0.8em;
}
</style>
