<template>
  <div>
    <supplier-customer-list-query @data="onCustomersData" />

    <el-autocomplete
      ref="autocomplete"
      v-model="keywords"
      class="w-full"
      :debounce="100"
      :fetch-suggestions="getSuggestions"
      placeholder="Search Customers..."
      value-key="name"
      @select="onSelect"
    >
      <icon
        v-if="customers.length === 0"
        slot="suffix"
        :icon="loadingIcon"
        spin
      />

      <div slot-scope="{ item }">
        {{ item.name }}
        ({{ item.buyer_id }})
        <template v-if="item.email">- {{ item.email }}</template>
      </div>
    </el-autocomplete>
  </div>
</template>

<script>
import { arrowsCw as loadingIcon } from '@/vendor/icons';
import SupplierCustomerListQuery from '@/components/Queries/AdShop/SupplierCustomerListQuery';

export default {
  components: { SupplierCustomerListQuery },
  props: {
    buyerId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      keywords: '',
      customers: [],

      // Icons
      loadingIcon
    };
  },
  methods: {
    input() {
      return this.$refs.autocomplete;
    },
    onCustomersData({ supplier }) {
      this.customers = supplier.customerList;
    },
    onSelect(customer) {
      this.$emit('input', customer);
    },
    async getSuggestions(queryString, callback) {
      // Search by the current keywords (queryString might be old)
      let results = this.customers.filter(this.createFilter(this.keywords));

      // Highlight the first search result after they are rendered
      this.$nextTick(() => {
        if (this.input()) {
          this.input().highlight(0);
        }
      });

      callback(results);
    },
    createFilter(queryString) {
      return item => {
        if (!item.name) {
          return false;
        }

        if (!queryString) {
          return true;
        }

        let searchableString = `${item.name} (${item.buyer_id}) ${item.email}`.toLowerCase();

        return searchableString.indexOf(queryString.toLowerCase()) > -1;
      };
    }
  }
};
</script>
