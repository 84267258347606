<script>
import moment from 'moment';

export default {
  name: 'SummaryMixin',

  computed: {
    dateRange() {
      let summary = this.cart.summary;
      if (summary && summary.totals.startDate) {
        let startDate = moment(summary.totals.startDate);
        let endDate = moment(summary.totals.endDate);

        return (
          startDate.format('MM/DD/YY') +
          ' - ' +
          (endDate && endDate.isValid() ? endDate.format('MM/DD/YY') : '?')
        );
      } else {
        return false;
      }
    },

    totals() {
      if (this.cart.summary) {
        return this.cart.summary.totals;
      }

      return false;
    },

    totalEnrollment() {
      return this.totals && this.totals.enrollment;
    },

    totalViews() {
      return this.totals && this.totals.estimatedViews;
    }
  }
};
</script>
