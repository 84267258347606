<template>
  <div class="shop-toolbar flex flex-col align-end">
    <cart-query v-if="$route.params.cart_id">
      <template v-slot="{ data: cart }">
        <cart-mutation v-if="cart" :cart-id="cart.id">
          <template v-slot="{ isSaving, updateCartRateClass }">
            <div class="flex justify-end">
              <el-popover
                ref="cartPopover"
                class="flex items-center cursor-pointer"
                placement="bottom-end"
                trigger="hover"
                popper-class="no-padding"
              >
                <div slot="reference" class="flex items-center mr-6">
                  <div class="flex relative">
                    <icon
                      class="icon-xl"
                      :class="iconClass"
                      :icon="basketIcon"
                    />
                    <span
                      v-if="cart.productVariantGroups.length > 0"
                      id="count-badge"
                      class="flex min-w-1.5 min-h-1.5 justify-center items-center absolute -mr-4 -mt-3 right-0 top-0 rounded-circle text-white text-xs bg-green"
                    >
                      {{ cart.productVariantGroups.length | number(0) }}
                    </span>
                  </div>
                </div>
                <simple-cart @review="closePopover" />
              </el-popover>
              <div class="flex">
                <el-select
                  :value="cart.rate_class"
                  placeholder="Rate Class"
                  @input="updateCartRateClass"
                >
                  <el-option
                    v-for="item in RateClasses"
                    :key="`rc-${item.id}`"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </template>
        </cart-mutation>
      </template>
    </cart-query>
  </div>
</template>

<script>
import CartMutation from '@/components/Mutations/AdShop/CartMutation';
import CartQuery from '@/components/Queries/AdShop/CartQuery';
import SimpleCart from '@/components/AdShop/Cart/SimpleCart';
import { basket as basketIcon } from '@/vendor/icons';
import { RateClasses } from '@/constants';

/* ShopToolbar
 *
 * Contains shopping cart icon and rate class selection.
 */

export default {
  components: {
    CartQuery,
    CartMutation,
    SimpleCart
  },
  props: {
    iconClass: {
      type: String,
      default: 'text-dark-silver'
    }
  },
  data() {
    return {
      // Constants
      RateClasses,

      // Icons
      basketIcon
    };
  },

  methods: {
    closePopover() {
      this.$refs.cartPopover.doClose();
    }
  }
};
</script>
