<template>
  <the-account-bar
    class="bg-white"
    :link-to="{ name: 'buyer.dashboard' }"
    logo-class="text-blue"
  >
    <me-query @data="user = $event" />

    <notifications-panel-icon icon-class="text-blue" />

    <el-menu
      v-if="!$store.getters['auth/isNationalBuyer']"
      class="inline-menu border-none mx-5"
      :unique-opened="true"
      :collapse="true"
      mode="horizontal"
    >
      <el-submenu index="shop">
        <el-menu-item slot="title" index="shop-title">
          <router-link v-if="primaryShop" :to="primaryShop.route">
            Shop
          </router-link>
          <a v-else>Shop</a>
        </el-menu-item>
        <el-menu-item
          v-for="shop in shops"
          :key="`shop-${shop.slug}`"
          :index="'shop-' + shop.slug"
        >
          <router-link :to="shop.route">
            <img
              v-if="shop.image"
              alt="Ad Shop"
              class="h-3 w-3"
              :src="shop.image"
            />
            <div class="inline-block ml-3">{{ shop.title }}</div>
          </router-link>
        </el-menu-item>
        <el-menu-item
          v-if="!shops"
          key="shop-loading"
          disabled
          index="shop-loading"
          class="text-center"
        >
          Loading...
        </el-menu-item>
      </el-submenu>
      <el-menu-item index="manage">
        <router-link :to="{ name: 'buyer.dashboard' }">Manage</router-link>
      </el-menu-item>
    </el-menu>
    <account-info-button class="ml-3" />
  </the-account-bar>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

import AccountInfoButton from '@/components/Auth/AccountInfoButton';
import { MeQuery } from '@/components/Queries';
import TheAccountBar from '@/components/Core/Layout/TheAccountBar';

export default {
  components: {
    AccountInfoButton,
    MeQuery,
    TheAccountBar
  },
  data() {
    return {
      flytedeskLogo,
      user: null
    };
  },
  computed: {
    primaryShop() {
      return this.shops && this.shops.length > 0 && this.shops[0];
    },
    shops() {
      if (this.user && this.user.buyer) {
        return this.user.buyer.suppliers.map(supplier => ({
          route: {
            name: 'adShop',
            params: {
              shopSlug: supplier.adShop.slug
            }
          },
          slug: supplier.adShop.slug,
          title: supplier.adShop.shop_name,
          image: supplier.adShop.profilePhotoFile
            ? supplier.adShop.profilePhotoFile.url
            : null
        }));
      }
      return null;
    }
  }
};
</script>
