<template>
  <div class="booking-date-popover w-full">
    <h3 class="text-base">Availability For {{ skuDate.date | date }}</h3>

    <div class="mt-2 text-md">
      <div class="font-bold">Total Available</div>
      <div class="ml-3">
        <div>{{ textQuantityAvailable }} {{ unit }}</div>
        <div v-if="skuDate.available_impressions">
          {{ skuDate.available_impressions | number(0) }} Estimated Impressions
        </div>
      </div>
    </div>

    <div v-if="hasCart" class="mt-2 text-md">
      <div class="font-bold">In Cart</div>
      <div class="ml-3">
        <div>
          {{ skuDate.quantityInCart }}
          {{ unit }}
        </div>
        <div v-if="skuDate.impressionsInCart">
          {{ skuDate.impressionsInCart | number(0) }} Estimated Impressions
        </div>
      </div>
    </div>

    <div v-if="!isCart && !hideSelection" class="mt-2 text-md">
      <div class="font-bold">In Selection</div>
      <div class="ml-3">
        <div>{{ skuDate.quantitySelected }} {{ unit }}</div>
        <div v-if="skuDate.impressionsSelected">
          {{ skuDate.impressionsSelected | number(0) }} Estimated Impressions
        </div>
      </div>
    </div>

    <div class="mt-2">
      <status-icon :status="skuDate.status.id" :icons="SkuStatus" inline />
      <div
        v-if="hasCart && skuDate.status === SkuStatus.OVERBOOKED"
        class="text-sm text-red"
      >
        ({{ unit }} in cart reserved for you)
      </div>
    </div>
  </div>
</template>

<script>
import { SkuStatus } from '@/constants';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';

const SKU_UNLIMITED = 999999;

export default {
  components: { StatusIcon },
  props: {
    // Rendering in context of the cart or pre-cart
    isCart: Boolean,
    hasCart: Boolean,
    hideSelection: Boolean,
    skuDate: {
      type: Object,
      required: true
    },
    // The Unit label representing the inventory items
    unit: {
      type: String,
      default: 'Ads'
    }
  },

  data() {
    return { SkuStatus };
  },

  computed: {
    textQuantityAvailable() {
      return this.skuDate.available_quantity >= SKU_UNLIMITED
        ? 'Unlimited'
        : this.skuDate.available_quantity || 'No';
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables.scss';

.booking-date-popover {
  font-size: 16px;
  text-align: initial;
}
</style>
