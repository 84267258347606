<template>
  <div id="summary-side-bar">
    <div class="rounded min-w-18 border shadow-2 text-md">
      <div class="text-center pad-xl border-bottom">
        <span class="flex justify-center font-semibold pb-4">
          Campaign Name
        </span>
        <el-input
          v-model="campaignName"
          class="themed"
          placeholder="Give this campaign a name"
        />
      </div>
      <div
        v-if="showSummary"
        class="cart-summary p-6 border-bottom leading-loose"
      >
        <el-button
          v-if="showSummaryExpansion"
          type="plain"
          class="margin-bottom w-full themed is-plain"
        >
          Expand Summary
        </el-button>
        <div class="flex justify-between">
          <div class="font-semibold">Estimated Views</div>
          <div class="text-dark-silver">{{ totalViews | number(0) }}</div>
        </div>
        <div class="flex justify-between">
          <div class="font-semibold">Enrollment</div>
          <div class="text-dark-silver">{{ totalEnrollment | number(0) }}</div>
        </div>
        <div v-if="dateRange" class="flex justify-between">
          <div class="font-semibold">Date Range</div>
          <div class="text-dark-silver">{{ dateRange }}</div>
        </div>
        <div>
          <div class="font-semibold">
            Media Mix
          </div>
          <div class="flex justify-center">
            <media-mix class="w-88" :cart="cart" />
          </div>
        </div>
      </div>
      <div class="discounts p-6">
        <discounts :cart="cart" />
        <div class="action">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Discounts from './Discounts';
import MediaMix from '@/components/Core/Charts/MediaMix';
import SummaryMixin from '@/components/AdShop/Mixins/SummaryMixin';

export default {
  components: {
    Discounts,
    MediaMix
  },

  mixins: [SummaryMixin],

  props: {
    cart: { type: Object, required: true },
    showSummary: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showSummaryExpansion: false
    };
  },

  computed: {
    campaignName: {
      get: function() {
        return this.$store.getters['adShop/campaignName'];
      },
      set: function(newValue) {
        this.$store.commit('adShop/setCampaignName', newValue);
      }
    }
  }
};
</script>
