<template>
  <el-card
    class="product-card cursor-pointer max-w-xxs p-0 hover:opacity-75"
    :body-style="{ padding: '0px' }"
    @click.native="
      $router.push({
        name: 'adShop.product',
        params: { shopSlug, cart_id: $route.params.cart_id, product_id: id }
      })
    "
  >
    <div>
      <div v-if="photoUrl" class="product-image" :style="productImageStyle" />

      <div class="p-3 flex justify-between">
        <!-- Left Side: Labels -->
        <div class="flex flex-col">
          <div class="text-md">
            <strong>{{ name }}</strong>
          </div>
          <div class="text-xs text-dark-silver">
            <div>{{ mediumName }}</div>
            <div>{{ propertyName }}</div>
            <div>{{ collectionName }}</div>
          </div>
        </div>

        <!-- Right Side: Price Info -->
        <div class="flex flex-col items-end text-xs text-dark-silver">
          <p class="whitespace-no-wrap">Starting at</p>
          <b>{{ startingPrice | currency }}</b>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    collectionName: { type: String, default: null },
    propertyName: { type: String, default: null },
    mediumName: { type: String, default: null },
    photoUrl: {
      type: String,
      default: () => null
    },
    startingPrice: {
      type: Number,
      required: true
    }
  },
  computed: {
    shopSlug() {
      return this.$store.getters['adShop/slug'];
    },
    productImageStyle() {
      return {
        'background-image': `url('${this.photoUrl}')`
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.product-image {
  width: 100%;
  padding-top: 85%;
  background-color: $color-charcoal;
  background-size: cover;
}

.pricing {
  text-align: right;
  font-size: 0.8em;

  strong {
    color: $color-charcoal;
  }
}
</style>
