<template>
  <!-- internal nodes -->
  <li class="tree-menu-node cursor-pointer">
    <div
      :class="{ 'themed-color': isSelected }"
      class="flex w-full justify-between text-sm px-4 py-2"
      @click="handleClick"
    >
      <span>
        {{ title }}
      </span>
      <span
        v-if="showToggleArrow"
        class="inline-block transition-all rotate-90"
        :class="{ 'transition-all rotate-180': isMenuOpened }"
      >
        <icon :icon="angleUp" />
      </span>
    </div>

    <transition-group tag="ul" name="fadeLeft" class="animate-position">
      <template v-if="showChildren">
        <tree-menu-node
          v-for="(value, index) in children"
          :key="`child-${index}`"
          class="ml-3"
          :title="index"
          :children="value"
          :parent-path="path"
          :active-path="activePath"
          @select="$emit('select', $event)"
        />
      </template>
    </transition-group>
  </li>
</template>

<script>
import _ from 'lodash';
import { angleUp } from '@/vendor/icons';

export default {
  name: 'TreeMenuNode',
  props: {
    children: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    parentPath: {
      type: Array,
      required: true
    },
    activePath: {
      type: Array,
      required: true
    }
  },
  data() {
    const isMenuOpened = this.checkMenu();
    return {
      path: [...this.parentPath, this.title],
      angleUp,
      isMenuOpened
    };
  },
  computed: {
    hasChildren() {
      return Object.keys(this.children).length > 0;
    },
    hasMultipleChildren() {
      return this.checkForMultipleChildren(this.children);
    },
    isRoot() {
      return this.parentPath.length === 0;
    },
    isSelected() {
      return _.isEqual(this.path, this.activePath);
    },
    showToggleArrow() {
      return this.hasMultipleChildren && this.isRoot;
    },
    showChildren() {
      return this.hasMultipleChildren && (!this.isRoot || this.isMenuOpened);
    }
  },

  watch: {
    activePath() {
      this.isMenuOpened = this.checkMenu();
    }
  },
  methods: {
    handleClick() {
      this.$emit('select', this.path);
      this.isMenuOpened = !this.isMenuOpened;
    },

    checkMenu() {
      return (
        this.activePath.includes(this.parentPath[0]) ||
        this.activePath[0] === this.title
      );
    },

    checkForMultipleChildren(menuTree) {
      if (!menuTree) {
        return false;
      }

      let menuItems = Object.keys(menuTree);

      if (menuItems.length >= 2) {
        return true;
      }

      for (let item of menuItems) {
        if (this.checkForMultipleChildren(menuTree[item])) {
          return true;
        }
      }

      return false;
    }
  }
};
</script>

<style scoped>
.tree-menu-node {
  padding: 0 !important;
}
</style>
