<template>
  <cart-discount-mutation>
    <template slot-scope="{ addDiscountToCart, removeDiscountFromCart }">
      <div v-if="discountCodeApplied && discountAmount" class="text-right mb-4">
        <div class="mb-4">
          <div class="flex justify-between text-md">
            <span>Subtotal</span>
            <span>{{ cartTotalBasePrice | currency }}</span>
          </div>
        </div>
        <div class="flex mb-4 w-full">
          <div
            class="themed-color mr-2"
            @click="removeDiscount(removeDiscountFromCart, cart.id)"
          >
            >
            <icon
              v-if="!removingDiscount"
              class="cursor-pointer text-dark-silver hover:text-red"
              :icon="trashIcon"
            />
            <loading-icon v-else />
          </div>
          <div class="flex flex-grow justify-between text-green">
            <span>{{ discountCodeApplied }}</span>
            <div>
              <span
                v-if="discount.type === discountTypes.PERCENT.name"
                class="mr-2"
              >
                ({{ parseFloat(discount.value).toFixed(0)
                }}{{ discountTypes.PERCENT.value }} Off)
              </span>
              <span>- ${{ discountAmount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mb-4">
        <div class="flex justify-between mb-4 text-md">
          <span>Subtotal</span>
          <span>{{ cartTotalBasePrice | currency }}</span>
        </div>
      </div>
      <div class="flex justify-between font-semibold mb-4 text-md">
        <span>Total</span>
        <span>{{ cartTotalAdjustedPrice | currency }}</span>
      </div>
      <el-button
        class="button-white-gray w-full mb-5"
        @click="showPromoCodeDialog = true"
      >
        Add Promo Code
      </el-button>
      <el-dialog
        title="Add Promo Code"
        :visible.sync="showPromoCodeDialog"
        width="20%"
        :append-to-body="true"
        center
      >
        <el-input
          v-model="promoCodeForm.promoCode"
          placeholder="Enter Code"
          @keypress.enter="
            applyDiscount(addDiscountToCart, cart.id, promoCodeForm.promoCode)
          "
        ></el-input>
        <div slot="footer" class="flex w-full">
          <el-button
            class="button-white-gray"
            @click="showPromoCodeDialog = false"
          >
            Cancel
          </el-button>
          <el-button
            class="themed"
            type="primary"
            @click="
              applyDiscount(addDiscountToCart, cart.id, promoCodeForm.promoCode)
            "
          >
            <template>
              <span v-if="!applyingDiscount">
                Apply
              </span>
              <loading-icon v-else />
            </template>
          </el-button>
        </div>
      </el-dialog>
    </template>
  </cart-discount-mutation>
</template>

<script>
import { DiscountTypes } from '@/constants';
import { trashEmpty as trashIcon } from '@/vendor/icons/index';
import { CartDiscountMutation } from '@/components/Mutations';
import LoadingIcon from '@/components/Core/Loading/LoadingIcon';

export default {
  name: 'Discounts',

  components: {
    CartDiscountMutation,
    LoadingIcon
  },

  props: {
    cart: { type: Object, required: true }
  },

  data() {
    return {
      promoCodeForm: {
        promoCode: null
      },
      showPromoCodeDialog: false,
      applyingDiscount: false,
      removingDiscount: false,
      trashIcon
    };
  },

  computed: {
    cartTotalAdjustedPrice() {
      return this.cart.total_adjusted_price;
    },

    cartTotalBasePrice() {
      return this.cart.total_base_price;
    },

    discount() {
      // Right now we're just handling the most frequent use case - a single discount
      return this.cart.cartDiscounts[0].discount;
    },

    discountAmount() {
      if (this.cart.cartDiscounts === null || this.discount === null) {
        return null;
      }

      if (this.discount.type === DiscountTypes.PERCENT.name) {
        // Percent Off
        return parseFloat(
          (this.cart.total_base_price * this.discount.value) / 100
        ).toFixed(2);
      } else {
        // Amount Off
        return parseFloat(this.discount.value).toFixed(2);
      }
    },

    discountTypes() {
      return DiscountTypes;
    },

    discountCodeApplied() {
      return this.cart.cartDiscounts[0] != null
        ? this.cart.cartDiscounts[0].discount.code
        : null;
    }
  },

  methods: {
    async applyDiscount(mutator, cartId, code) {
      this.applyingDiscount = true;
      try {
        await mutator(cartId, code);
        this.applyingDiscount = false;
        this.showPromoCodeDialog = false;
      } catch (e) {
        this.applyingDiscount = false;
        throw new Error(e.message);
      }
    },

    async removeDiscount(mutator, cartId) {
      this.removingDiscount = true;
      try {
        await mutator(cartId);
        this.removingDiscount = false;
      } catch (e) {
        this.removingDiscount = false;
        throw new Error(e.message);
      }
    }
  }
};
</script>
