<template>
  <header-layout>
    <router-link :to="linkTo" class="flex items-center h-full text-3xl">
      <icon :icon="flytedeskLogo" />
    </router-link>
    <div class="h-full flex items-center justify-end text-md">
      <template v-if="!$store.getters['auth/isAuthenticated']">
        <a class="navbar-item" @click="signIn">Log In</a>
        <a class="navbar-item" @click="signUp">Sign Up</a>
      </template>
      <template v-else>
        <a class="navbar-item is-active">Shop</a>
        <account-info-button class="navbar-item" />
      </template>
    </div>
    <el-dialog
      width="70%"
      :visible.sync="showDialog"
      append-to-body
      :close-on-click-modal="false"
      :title="isSignUp ? 'Sign Up' : 'Log In'"
    >
      <transition name="fade" mode="out-in">
        <ad-shop-query v-if="isSignUp" :buyer-id="'0'">
          <template slot-scope="{ isLoading, data }">
            <loader-menu v-if="isLoading" />
            <sign-up-flow
              v-else-if="data && data.supplier"
              :supplier-id="data.supplier.id"
              @signInRequest="signIn"
              @signedIn="showDialog = false"
            />
            <div v-else class="text-red">
              Which publisher were you trying to sign up with? Please contact us
              for help
            </div>
          </template>
        </ad-shop-query>
        <sign-in-flow
          v-else
          @signUpRequest="signUp"
          @signedIn="showDialog = false"
        />
      </transition>
    </el-dialog>
  </header-layout>
</template>

<script>
import { flytedeskLogo } from '@/vendor/icons';

import AccountInfoButton from '@/components/Auth/AccountInfoButton';
import HeaderLayout from './HeaderLayout';
import { LoaderMenu } from '@/components/Core/Loaders';
import SignInFlow from '@/components/AdShop/Cart/SignInFlow';
import SignUpFlow from '@/components/AdShop/Cart/CreateAccountFlow';
import { AdShopQuery } from '@/components/Queries';

export default {
  components: {
    AccountInfoButton,
    AdShopQuery,
    HeaderLayout,
    LoaderMenu,
    SignInFlow,
    SignUpFlow
  },
  props: {
    linkTo: {
      type: Object,
      default: () => ({
        name: 'home'
      })
    }
  },
  data() {
    return {
      flytedeskLogo,
      showDialog: false,
      isSignUp: false
    };
  },
  methods: {
    signIn() {
      this.showDialog = true;
      this.isSignUp = false;
    },
    signUp() {
      this.showDialog = true;
      this.isSignUp = true;
    }
  }
};
</script>
