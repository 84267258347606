<script>
import { getSupplierCustomerList } from '@/graphql/customer/queries.graphql';
import { QueryMixin } from '@/components/Core/Helpers';

export default {
  mixins: [QueryMixin],

  query() {
    return {
      query: getSupplierCustomerList,
      variables: {
        supplierId: this.$store.getters['auth/supplierId']
      }
    };
  }
};
</script>
