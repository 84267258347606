<template>
  <div class="flex justify-around items-center">
    <media-mix class="mr-3 w-88" :cart="cart" />
    <div class="min-w-18">
      <key-value v-if="dateRange" :value="dateRange">Date Range:</key-value>
      <key-value :value="totalViews | number(0)">Estimated Views:</key-value>
      <key-value :value="totalEnrollment | number(0)">Enrollment:</key-value>
      <key-value :value="totalBudget | currency">Total:</key-value>
    </div>
  </div>
</template>

<script>
import KeyValue from './KeyValue';
import MediaMix from '@/components/Core/Charts/MediaMix';
import SummaryMixin from '@/components/AdShop/Mixins/SummaryMixin';

export default {
  name: 'SimpleCartSummary',

  components: {
    KeyValue,
    MediaMix
  },

  mixins: [SummaryMixin],

  props: {
    cart: {
      type: Object,
      required: true
    }
  },

  computed: {
    totalBudget() {
      if (this.cart == null) {
        return 0;
      }

      return this.cart.total_adjusted_price;
    }
  }
};
</script>
