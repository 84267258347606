<template>
  <style-creator>
    .themed-wrapper /deep/ .themed.el-button { border-color: #{{ color }};
    color: white; } .themed-wrapper /deep/ .themed.el-button.el-button--primary
    { background-color: #{{ color }}; border-color:
    {{ convertToRgba(color, 0.5) }}; color: white; }
    .themed.el-button.el-button--primary { background-color: #{{ color }};
    border-color: {{ convertToRgba(color, 0.5) }}; color: white; }
    .themed.el-button.el-button--primary:hover { background-color:
    {{ convertToRgba(color, 0.5) }}; } .themed-wrapper /deep/
    .themed.el-button.el-button--primary:hover { background-color:
    {{ convertToRgba(color, 0.5) }}; } .themed-wrapper /deep/
    .themed.el-button.el-button--primary.is-disabled { background-color:
    {{ convertToRgba(color, 0.5) }}; } .themed-wrapper /deep/
    .themed.el-button.el-button--primary.is-disabled:hover { background-color:
    {{ convertToRgba(color, 0.5) }}; } .themed-wrapper /deep/
    .themed.el-button.is-plain { color: #{{ color }}; } .themed-wrapper /deep/
    .themed.el-button.is-plain:hover, .themed-wrapper /deep/
    .themed.el-button.is-plain:focus { border-color: #{{ color }}; color: #{{
      color
    }}
    !important; } .themed-wrapper /deep/ .el-input .el-input__inner:focus,
    .themed-wrapper /deep/ .el-input .el-textarea__inner:focus, .themed-wrapper
    /deep/ .el-textarea .el-input__inner:focus, .themed-wrapper /deep/
    .el-textarea .el-textarea__inner:focus, .themed-wrapper /deep/
    .el-date-editor .el-input__inner:focus, .themed-wrapper /deep/
    .el-date-editor .el-textarea__inner:focus { border-color: #{{
      color
    }}
    !important; } .themed-wrapper /deep/ .el-date-editor.el-input__inner:focus,
    .themed-wrapper /deep/ .el-date-editor.el-input__inner:active,
    .themed-wrapper /deep/ .el-date-editor.el-input__inner:hover,
    .themed-wrapper /deep/ .el-date-editor.el-input__inner.is-active:focus,
    .themed-wrapper /deep/ .el-date-editor.el-input__inner.is-active:active,
    .themed-wrapper /deep/ .el-date-editor.el-input__inner.is-active:hover {
    border-color: #{{ color }}; } .themed-wrapper /deep/
    .themed.el-range-editor.is-active { border-color: #{{ color }}; }
    .themed-wrapper /deep/ .themed.el-radio .el-radio__input
    .el-radio__inner:hover { border-color: #{{ color }}; } .themed-wrapper
    /deep/ .themed.el-radio.is-checked .el-radio__input .el-radio__inner:hover {
    border-color: #{{ color }}; } .themed-wrapper /deep/
    .themed.el-radio.is-checked .el-radio__input.is-checked .el-radio__inner {
    background-color: #{{ color }}; border-color: #{{ color }}; }
    .themed-wrapper /deep/ .themed.el-radio.is-checked .el-radio__label { color:
    #{{ color }}; } .el-date-range-picker .el-date-table
    td.current:not(.disabled) span, .el-date-picker .el-date-table
    td.current:not(.disabled) span { background: #{{ color }}; }
    .el-date-range-picker .el-date-table__row td.available:hover,
    .el-date-picker .el-date-table__row td.available:hover { color: #{{
      color
    }}; } .el-date-range-picker .el-date-table__row td.available.in-range div,
    .el-date-picker .el-date-table__row td.available.in-range div { background:
    ${convertToRgba(color, 0.3)}; } .el-date-range-picker .el-date-table__row
    td.available.in-range.start-date span, .el-date-range-picker
    .el-date-table__row td.available.in-range.end-date span, .el-date-picker
    .el-date-table__row td.available.in-range.start-date span, .el-date-picker
    .el-date-table__row td.available.in-range.end-date span { background-color:
    #{{ color }}; } .el-date-range-picker .el-date-table__row td.disabled div,
    .el-date-picker .el-date-table__row td.disabled div { border-radius: 0;
    margin: 0; background: #f5f7fa; } .el-date-range-picker .el-date-table__row
    td.disabled.end-date div span, .el-date-range-picker .el-date-table__row
    td.disabled.start-date div span, .el-date-picker .el-date-table__row
    td.disabled.end-date div span, .el-date-picker .el-date-table__row
    td.disabled.start-date div span { background: #CDD4E3; color: white; }
    .el-date-table td.today span { color: #{{ color }}; } .el-date-range-picker
    .el-date-table__row td.disabled.today span, .themed-wrapper .el-date-picker
    .el-date-table__row td.disabled.today span { color: #{{ color }}; }
    el-select-dropdown .popper__arrow { display: none; }
    .el-select-dropdown__item.selected { color: #{{ color }}; }
    .el-select-dropdown__item.hover { background-color:
    {{ convertToRgba(color, 0.3) }}; } .flatpickr-day { background: none
    !important; border: none; position: relative; } .flatpickr-day::after {
    position: absolute; content: ""; top: 15%; left: 15%; width: 70%; height:
    70%; border-radius: 50%; z-index: -1; }
    .flatpickr-day:not(.flatpickr-disabled)::after { border: 1px solid #{{
      color
    }}; } .flatpickr-day.selected::after { background: #{{ color }} !important;
    } .flatpickr-calendar .flatpickr-day.selected::after { background: #{{
      color
    }}; } .themed-wrapper a.themed { color: #{{ color }}; } .themed-color {
    color: #{{ color }}; }
  </style-creator>
</template>

<script>
import StyleCreator from './StyleCreator';

export default {
  name: 'AdshopStyledComponent',

  components: {
    StyleCreator
  },

  computed: {
    color() {
      return this.$store.getters['adShop/adShopThemeColor'] || '5FAEE1';
    }
  },

  methods: {
    convertToRgba(colour, opacity) {
      let r, g, b;
      r = colour.charAt(0) + '' + colour.charAt(1);
      g = colour.charAt(2) + '' + colour.charAt(3);
      b = colour.charAt(4) + '' + colour.charAt(5);

      r = parseInt(r, 16);
      g = parseInt(g, 16);
      b = parseInt(b, 16);
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
  }
};
</script>

<style scoped></style>
