<template>
  <div class="page-payment">
    <cart-query @data="onCartData" />
    <ad-shop-query :buyer-id="buyerId" @data="adShop = $event" />
    <supplier-customer-query
      v-if="takeoverBuyerId"
      :buyer-id="takeoverBuyerId"
      @data="onCustomer($event.customer)"
    />

    <buyer-customer-query
      v-else-if="adShop"
      :supplier-id="adShop.supplier.id"
      @data="onCustomer($event.customer)"
    />

    <!-- Payment Method Select & Form -->
    <div v-if="customer && cart && adShop" class="flex justify-between">
      <template v-if="existingOrder && !isSubmitting">
        <div v-if="takeoverBuyerId" class="text-center w-full">
          <h2>Order Already Exists</h2>
          <div class="mt-3">
            It appears you already have created an order for this cart.<br />
            We apologize for any inconvenience while creating your order.
            <br />
            <br />
            Please click the button below to continue to the Order Review page.
          </div>
          <div class="mt-3">
            <el-button
              v-if="!isSubmitting"
              class="button-blue"
              @click="redirectToSubmitted"
            >
              Review Order {{ existingOrder.ref }}
            </el-button>
            <loading-button v-else button-class="w-48" />
          </div>
        </div>
        <div v-else class="text-center w-full">
          <h2>Campaign Already Exists</h2>
          <div class="mt-3">
            It appears you already have created a campaign for this cart.<br />
            We apologize for any inconvenience while creating your campaign.
            <br />
            <br />
            Please click the button below to continue to the Campaign Review
            page.
          </div>
          <div class="mt-3">
            <el-button
              v-if="!isSubmitting"
              class="button-blue"
              @click="redirectToSubmitted"
            >
              Review Campaign {{ existingOrder.campaign.ref }}
            </el-button>
            <loading-button v-else button-class="w-48" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex-grow mr-8">
          <h2 class="mb-6">Checkout</h2>
          <h4 class="font-semibold">Payment Settings</h4>
          <span class="text-md">
            These settings determine the cadence this order will be invoiced,
            and when payment is due.
          </span>

          <div class="flex items-center my-8">
            <h4 class="mr-4 font-semibold">
              Payment type
            </h4>

            <!-- DEV-6024: if credit cards are disabled, force invoice payment method -->
            <el-select
              v-if="isCreditCardAllowed"
              v-model="paymentMethod"
              placeholder="Select a Payment Method"
              class="w-72"
            >
              <el-option
                :label="
                  isInvoiceAllowed
                    ? 'Pay by Invoice'
                    : 'Request alternate payment method'
                "
                :value="PaymentMethodName.INVOICE"
              />
              <el-option
                label="Credit Card"
                :value="PaymentMethodName.CREDIT"
              />
            </el-select>
          </div>

          <transition-group name="fadeLeft" tag="div">
            <div v-if="isCreditCardPayment" :key="'credit-payment-method'">
              <form-credit-payment
                ref="creditPaymentForm"
                key="credit-payment-template"
                class="mt-2"
                :address="form.billingAddress"
              />
            </div>

            <div v-else :key="'alternate-payment-method'">
              <el-form>
                <custom-el-form-item
                  label="Message"
                  label-class="font-semibold text-md"
                  prop="invoice_message"
                >
                  <el-input
                    v-model="form.invoice_message"
                    type="textarea"
                    rows="5"
                    placeholder="Enter any notes you have regarding your payment request"
                    class="themed"
                  />
                </custom-el-form-item>
              </el-form>
            </div>
          </transition-group>

          <el-form class="margin-top-xxl">
            <custom-el-form-item
              label="Additional Order Notes"
              label-class="font-semibold text-md"
            >
              <el-input
                v-model="form.notes"
                class="margin-top-xs"
                placeholder="Enter any notes about this campaign we should know"
                rows="6"
                type="textarea"
              />
            </custom-el-form-item>
          </el-form>

          <div class="campaign-contacts mt-5">
            <h3>
              Who should the publisher reach out to regarding this campaign?
            </h3>
            <div class="primary-contact-form mt-5">
              <h4>Primary Contact</h4>
              <edit-contact-form v-model="form.primaryContact" />
            </div>

            <div class="billing-contact-form mt-5">
              <h4>Billing Contact</h4>
              <edit-contact-form v-model="form.billingContact" />
            </div>
          </div>
        </div>

        <!-- Summary -->
        <summary-side-bar class="mt-6" :cart="cart" :show-summary="false">
          <cart-mutation :cart-id="cart.id">
            <template slot-scope="{ submitPaymentRequest }">
              <div class="flex-1">
                <div class="mt-5">
                  <el-checkbox v-model="hasAcceptedPolicies">
                    I agree to the
                    <a
                      href="https://www.flytedesk.com/terms-and-conditions"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>
                    <template v-if="adShop.policiesFile">
                      and
                      <a :href="adShop.policiesFile.url" target="_blank">
                        Publisher Policies
                      </a>
                    </template>
                  </el-checkbox>
                </div>
                <div class="mt-5">
                  <el-button
                    v-if="!isSubmitting"
                    :disabled="!canSubmitCheckout"
                    class="themed w-full"
                    type="primary"
                    @click="
                      isSupplierCheckout
                        ? (isSupplierCheckoutConfirmationVisible = true)
                        : submitRequest(submitPaymentRequest)
                    "
                  >
                    Submit Request
                  </el-button>
                  <loading-button v-else class="w-full" />
                </div>

                <!-- Supplier Checkout Confirmation Modal -->
                <confirm-dialog
                  v-if="isSupplierCheckoutConfirmationVisible"
                  title="Confirm Purchase for Customer"
                  width="40em"
                  confirm-class="themed"
                  confirm-text="Continue"
                  @confirm="submitRequest(submitPaymentRequest)"
                  @close="isSupplierCheckoutConfirmationVisible = false"
                >
                  <div v-if="customer" class="text-center">
                    <div>
                      You are currently placing an order for
                      <strong> {{ customer.buyer.buyer_company }} </strong>.
                    </div>
                    <div>Would you like to continue?</div>
                  </div>
                  <div v-else>
                    The customer you are trying to place an order was not found.
                    Please try refreshing the page.
                  </div>
                </confirm-dialog>
              </div>
            </template>
          </cart-mutation>
        </summary-side-bar>
      </template>
    </div>
    <loading-page v-else />
  </div>
</template>

<script>
import CartMutation from '@/components/Mutations/AdShop/CartMutation';
import ConfirmDialog from '@/components/Core/ConfirmDialog';
import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import EditContactForm from '@/components/Core/Settings/EditContactForm';
import FormCreditPayment from '@/components/AdShop/Cart/FormCreditPayment';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import SummarySideBar from './SummarySideBar';
import {
  AdShopQuery,
  BuyerCustomerQuery,
  CartQuery,
  SupplierCustomerQuery
} from '@/components/Queries';

import { trashEmpty as trashIcon } from '@/vendor/icons/index';
import { PaymentMethodName } from '@/constants';

export default {
  components: {
    EditContactForm,
    AdShopQuery,
    BuyerCustomerQuery,
    CartMutation,
    CartQuery,
    ConfirmDialog,
    CustomElFormItem,
    FormCreditPayment,
    LoadingButton,
    LoadingPage,
    SupplierCustomerQuery,
    SummarySideBar
  },

  data() {
    return {
      adShop: null,
      cart: null,
      customer: null,

      PaymentMethodName,
      paymentMethod: PaymentMethodName.CREDIT,

      isSubmitting: false,
      isSupplierCheckoutConfirmationVisible: false,

      form: {
        campaign_name: this.$store.getters['adShop/campaignName'],
        notes: '',
        invoice_message: '',
        billingAddress: {},
        billingContact: {},
        primaryContact: {}
      },
      promoCodeForm: {
        promoCode: null
      },
      hasAcceptedPolicies: false,

      // Icons
      trashIcon
    };
  },

  computed: {
    existingOrder() {
      return this.cart.orders && this.cart.orders.length > 0
        ? this.cart.orders[0]
        : null;
    },
    isCreditCardAllowed() {
      return this.adShop.allow_credit_card_payment;
    },
    isInvoiceAllowed() {
      // If credit card payments are disabled, invoices are always allowed, otherwise default to customer preference
      return (
        !this.isCreditCardAllowed ||
        this.customer.can_pay_by_alternate_method ||
        this.takeoverBuyerId
      );
    },
    isCreditCardPayment() {
      return (
        this.isCreditCardAllowed &&
        this.paymentMethod === PaymentMethodName.CREDIT
      );
    },
    takeoverBuyerId() {
      return this.$store.getters['adShop/takeoverBuyerId'];
    },

    buyerId() {
      return this.takeoverBuyerId || this.$store.getters['auth/buyerId'];
    },

    canSubmitCheckout() {
      if (this.hasAcceptedPolicies) {
        if (
          this.$can('pub_place_order_for_buyer') ||
          this.$can('buyer_manage_campaign')
        ) {
          return true;
        }
      }

      return false;
    },
    cartTotalAdjustedPrice() {
      if (this.cart == null) {
        return 0;
      }

      return this.cart.total_adjusted_price;
    },

    isSupplierCheckout() {
      return this.$store.getters['auth/isSupplier'] && !!this.takeoverBuyerId;
    }
  },

  methods: {
    onCustomer(customer) {
      this.customer = customer;
      this.form = {
        ...this.form,
        billingAddress: customer.buyer.billingAddress,
        primaryContact: customer.buyerPrimaryContact,
        billingContact: customer.buyerBillingContact
      };
    },
    async submitRequest(submitPaymentRequest) {
      this.isSupplierCheckoutConfirmationVisible = false;

      if (!this.buyerId) {
        throw new Error('Cannot resolve buyer id for purchase.');
      }

      let variables = {
        buyerId: this.buyerId,
        cartId: this.cart.id,
        form: {
          ...this.form,
          campaign_name: this.$store.getters['adShop/campaignName']
        }
      };

      this.isSubmitting = true;

      // check the payment type
      if (this.isCreditCardPayment) {
        let result;

        try {
          result = await this.$refs.creditPaymentForm.submit();
        } catch (e) {
          this.$message.error(e);
          this.isSubmitting = false;
          return;
        }

        if (result.error) {
          this.$message.error(result.error);
          this.isSubmitting = false;
          throw new Error(result.error);
        }

        // add the payment data to the variables
        variables.form = {
          stripeToken: result.stripeToken,
          ...result.form,
          ...variables.form
        };
      }

      let result;

      try {
        result = await submitPaymentRequest(
          this.isCreditCardPayment
            ? PaymentMethodName.CREDIT
            : PaymentMethodName.INVOICE,
          variables
        );

        if (result) {
          this.clearCart(
            result.checkoutWithInvoice || result.checkoutWithCreditCard
          );
          this.trackPurchase();
          this.redirectToSubmitted();
        }
      } catch (e) {
        console.error(e);
        this.clearCart(null);
        await this.resetStore();
        this.isSubmitting = false;
      }
    },
    trackPurchase() {
      if (this.$gtag) {
        try {
          let order = this.$store.getters['adShop/submittedOrder'];

          let data = {
            transaction_id: order.ref,
            affiliation: this.adShop.supplier.display_name,
            value: order.total,
            currency: 'USD',
            items: order.ads.map(ad => ({
              id: ad.ref,
              name: ad.orderProduct.product_name,
              category: ad.orderProduct.mediumType.value,
              variant: ad.orderProduct.product_variant_name,
              quantity: ad.orderLineItem.quantity,
              price: ad.orderLineItem.buyer_price
            }))
          };

          this.$gtag.purchase(data);
        } catch (e) {
          console.error(e);
        }
      }
    },
    onCartData(cart) {
      this.cart = cart;

      if (this.existingOrder) {
        this.clearCart(this.existingOrder);
      }
    },
    async resetStore() {
      // XXX: We need to refresh the cached orders, and buyer's suppliers list (maybe other things??) so lets just re-grab everything for now
      // until we properly implement the cache update
      await this.$store.dispatch('auth/reset');
    },
    clearCart(order) {
      // Set submittedOrder in Vuex Store
      this.$store.commit('adShop/setSubmittedOrder', order);

      this.$store.commit('adShop/setAdShopCartId', null);
      this.$store.commit('adShop/setCampaignName', null);
    },
    redirectToSubmitted() {
      this.isSubmitting = true;

      this.$router.push({
        name: 'adShop.orderSubmitted',
        params: {
          shopSlug: this.$store.getters['adShop/slug']
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.discount-applied {
  color: $color-green;
}

.discount-amount {
  text-align: right;
}

.remove-discount {
  margin-left: 0.5em;
}

.dialog-footer {
  display: flex;
  width: 100%;

  > * {
    flex: 50%;
  }
}

.total {
  padding-bottom: 1.5em;
  font-weight: bold;
}

.subtotal {
  padding-top: 1em;
}

.discount-footer {
  display: flex;
  width: 100%;
}

.el-button {
  &.save {
    color: white;
  }
}

/deep/ .el-form-item__label {
  margin-bottom: -5px;
}
</style>
