<template>
  <div>
    <div class="logo text-center">
      <icon class="flytedesk-logo" :icon="flytedeskLogoIcon" />
    </div>
    <div class="subtitle text-center">
      Connect to your flytedesk account to book a campaign with this supplier.
      Your account will allow you to track campaign progress, upload creatives,
      and view verifications.
    </div>
    <el-form
      ref="createAccountForm"
      label-position="top"
      label-width="100px"
      :model="createAccountForm"
      :rules="rules"
    >
      <div class="flex -m-3 margin-less">
        <el-form-item
          class="block p-3 flex-1"
          label="First Name"
          prop="buyer_first_name"
        >
          <el-input
            v-model="createAccountForm.buyer_first_name"
            name="first_name"
            placeholder="First Name"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="block p-3 flex-1"
          label="Last Name"
          prop="buyer_last_name"
        >
          <el-input
            v-model="createAccountForm.buyer_last_name"
            name="last_name"
            placeholder="Last Name"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="Email" prop="email">
        <el-input
          v-model="createAccountForm.email"
          v-mask.email
          name="email"
          placeholder="Email"
        ></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input
          v-model="createAccountForm.password"
          type="password"
          placeholder="Password"
        ></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" prop="confirm_password">
        <el-input
          v-model="createAccountForm.confirm_password"
          type="password"
          placeholder="Password"
        ></el-input>
      </el-form-item>
      <br />
      <h4 class="text-center">Company Information</h4>
      <el-form-item label="Company Name" prop="buyer_company">
        <el-input
          v-model="createAccountForm.buyer_company"
          placeholder="Company Name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Company Address" prop="primaryAddress.address">
        <el-input
          v-model="createAccountForm.primaryAddress.address"
          placeholder="Address"
        ></el-input>
      </el-form-item>
      <el-form-item label="City" prop="primaryAddress.city">
        <el-input
          v-model="createAccountForm.primaryAddress.city"
          placeholder="City"
        ></el-input>
      </el-form-item>
      <div class="flex -m-3 margin-less">
        <el-form-item
          class="block p-3 flex-1"
          label="State"
          prop="primaryAddress.state"
        >
          <select-state
            v-model="createAccountForm.primaryAddress.state"
            placeholder="State"
          ></select-state>
        </el-form-item>
        <el-form-item
          class="block p-3 flex-1"
          label="Zip/Postal Code"
          prop="primaryAddress.zip_code"
        >
          <el-input
            v-model="createAccountForm.primaryAddress.zip_code"
            v-mask.zipcode
            placeholder="Zip/Postal Code"
          ></el-input>
        </el-form-item>
      </div>
      <div class="flex -m-3" style="margin-top: -1.7em">
        <el-form-item
          class="block p-3 flex-1"
          label="Phone Number"
          prop="buyer_phone"
        >
          <el-input
            v-model="createAccountForm.buyer_phone"
            v-mask.phone
            placeholder="Phone Number"
          ></el-input>
        </el-form-item>
        <el-form-item
          class="block p-3 flex-1"
          label="Business Type"
          prop="business_type"
        >
          <el-input
            v-model="createAccountForm.business_type"
            placeholder="Select business type"
          ></el-input>
        </el-form-item>
      </div>
      <div class="footer-info text-center">
        By clicking "Create Account" you agree to flytedesk's
        <a href="https://flytedesk.com/terms-and-conditions" target="_blank"
          >Terms & Conditions</a
        >
      </div>

      <el-alert
        v-if="formError"
        type="error"
        :title="formError"
        class="mt-4"
        :closable="false"
      />
      <login-mutation @data="onData" @error="formError = $event">
        <template v-slot="{ isSaving, createBuyerAccount }">
          <div class="mt-3 text-center">
            <el-button
              v-if="!isSaving"
              class="button-blue w-full"
              @click="createAccount(createBuyerAccount)"
            >
              Create Account
            </el-button>
            <loading-button v-else class="w-full" />
          </div>
        </template>
      </login-mutation>
    </el-form>

    <div class="subtitle text-center footer-info">
      Already have an account?
      <a @click="$emit('signInRequest')">Sign In</a>
    </div>
  </div>
</template>

<script>
import { flytedeskLogo as flytedeskLogoIcon } from '@/vendor/icons';

import SelectState from '@/components/Core/SelectState.vue';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import { LoginMutation } from '@/components/Mutations';

export default {
  components: {
    LoadingButton,
    LoginMutation,
    SelectState
  },
  props: {
    supplierId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      createAccountForm: {
        email: '',
        password: '',
        confirm_password: '',
        buyer_company: '',
        primaryAddress: {
          address: '',
          address2: '',
          city: '',
          state: '',
          zip_code: ''
        },
        buyer_first_name: '',
        buyer_last_name: '',
        supplier_id: this.supplierId,
        business_type: '',
        buyer_accept_terms: true
      },
      formError: null,
      rules: {
        email: [{ required: true, message: 'Email required', trigger: 'blur' }],
        password: [
          { required: true, message: 'Password required', trigger: 'blur' }
        ],
        buyer_company: [
          {
            required: true,
            message: 'Please input company name',
            trigger: 'blur'
          }
        ],
        buyer_first_name: [
          { required: true, message: 'First name required', trigger: 'blur' }
        ],
        buyer_last_name: [
          { required: true, message: 'Last name required', trigger: 'blur' }
        ],
        confirm_password: [
          {
            required: true,
            validator: this.validatePasswordConfirmation,
            trigger: 'blur'
          }
        ]
      },
      flytedeskLogoIcon
    };
  },

  methods: {
    async onData() {
      this.$refs.createAccountForm.resetFields();

      await this.$store.dispatch('auth/getUser');

      if (this.$store.getters['auth/isAuthenticated']) {
        this.$emit('signedIn');
      } else {
        this.$message.error(
          'Failed to log in. Please try logging in using your credentials.'
        );
        this.$emit('signUpRequest');
      }
    },
    validatePasswordConfirmation(rule, value, callback) {
      if (value !== this.createAccountForm.password) {
        callback(new Error('Passwords do not match'));
      } else {
        callback();
      }
    },
    async createAccount(createBuyerAccount) {
      // Validation
      this.$refs.createAccountForm.validate(async valid => {
        if (valid) {
          await createBuyerAccount(this.createAccountForm);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.subtitle {
  margin-bottom: 1.3em;
}

/deep/ .el-form-item__label {
  margin-bottom: -15px;
}

.footer-info {
  margin-top: 1em;
}

.margin-less {
  margin-bottom: -1em;
}

.flytedesk-logo {
  color: $color-blue;
  font-size: 2.7em;
  margin-bottom: 0.5em;
}
</style>
