<template>
  <div class="tree-menu">
    <p class="title">Categories</p>
    <ul>
      <li
        class="tree-menu-node leaf text-md"
        :class="{ 'themed-color': isRootPathSelected }"
        @click="$emit('select', [])"
      >
        All
      </li>
      <tree-menu-node
        v-for="(node, index) in menu"
        :key="`child-${index}`"
        :title="index"
        :children="node"
        :parent-path="[]"
        :active-path="selected"
        @select="$emit('select', $event)"
      />
    </ul>
  </div>
</template>

<script>
import TreeMenuNode from './TreeMenuNode';

export default {
  components: {
    TreeMenuNode
  },
  props: {
    menu: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isRootPathSelected() {
      return this.selected.length === 0;
    }
  },
  methods: {
    handleSubmenuClick(submenu) {
      let activeIndex = submenu.indexPath[submenu.indexPath.length - 1];
      this.$refs.menu.activeIndex = activeIndex;
      submenu.activeIndex = activeIndex;
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.title {
  font-weight: bold;
}

.tree-menu-node {
  border-bottom: 1px $color-gray solid;
  padding: 0.5em 1em;
  min-width: 10em;
}

li {
  cursor: pointer;
}
</style>
