<template>
  <div class="flex justify-end -mx-3 -mt-3 mb-3">
    <div class="block p-3">
      <el-date-picker
        class="date-range-picker themed"
        :value="dateRange"
        type="daterange"
        range-separator="To"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        format="MM/dd/yyyy"
        :picker-options="pickerOptions"
        @input="$emit('input', $event)"
      >
      </el-date-picker>
    </div>
    <div class="block p-3">
      <el-select
        class="select-sort-by themed"
        :value="sortBy"
        placeholder="Sort By"
        @input="$emit('sort', $event)"
      >
        <el-option
          v-for="item in sortByOptions"
          :key="`item-${item.id}`"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TopBar',
  props: {
    dateRange: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: null
    },
    sortByOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.date-range-picker {
  width: 16em;
}

.select-sort-by {
  width: 10em;
}
</style>
