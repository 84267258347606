<template>
  <div id="product-browser" class="mt-8 w-full">
    <fetch-browse-product
      :rate-class="cart.rate_class"
      :selected-path="selectedPath"
      :start-date="startDate"
      :end-date="endDate"
      :sort-by="sortBy"
      :loading.sync="isLoadingProducts"
      @data="adShop = $event"
    />

    <transition name="fade" mode="out-in">
      <div class="flex flex-col">
        <top-bar
          :date-range="[startDate, endDate]"
          :sort-by="sortBy"
          :sort-by-options="sortByOptions"
          @sort="sortBy = $event"
          @input="onFilter"
        />
        <div class="flex justify-between">
          <tree-menu
            class="mr-4"
            :menu="menu"
            :selected="selectedPath"
            @select="onSelectPath"
          />
          <div class="flex-grow">
            <transition-group
              v-if="adShop && adShop.browseProducts.length > 0"
              tag="div"
              name="fadeRight"
              class="flex flex-wrap animate-position"
            >
              <product-card
                v-for="browseProduct of adShop.browseProducts"
                :id="browseProduct.product.id"
                :key="`product-${browseProduct.product.id}`"
                class="block w-1/3 mr-8 mb-8"
                :name="browseProduct.product.name"
                :collection-name="browseProduct.product.collection.name"
                :property-name="browseProduct.product.collection.property.name"
                :medium-name="
                  browseProduct.product.collection.property.medium.name
                "
                :photo-url="optimizedFile(browseProduct.product.primaryPhoto)"
                :starting-price="Number(browseProduct.starting_price)"
              />
            </transition-group>
            <loader-menu-content-columns v-else-if="isLoadingProducts" grid />
            <div v-else class="text-dark-silver text-lg mt-32 text-center">
              <div>
                There are no products available between
                {{ startDate | date }} and {{ endDate | date }}.
              </div>
              <div>
                Please adjust the filter to find available products.
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FetchBrowseProduct from './FetchBrowseProduct.vue';
import { LoaderMenuContentColumns } from '@/components/Core/Loaders';
import ProductCard from './ProductCard.vue';
import TopBar from '@/components/AdShop/PageStorefront/TopBar';
import TreeMenu from './TreeMenu.vue';
import { optimizedFile } from '@/utils/helpers';

export default {
  components: {
    FetchBrowseProduct,
    LoaderMenuContentColumns,
    ProductCard,
    TopBar,
    TreeMenu
  },
  props: {
    cart: {
      type: Object,
      required: true
    }
  },
  data() {
    let selectedPath = (this.$route.query.selectedPath || '')
      .split('|')
      .filter(a => !!a);

    return {
      adShop: null,
      isLoadingProducts: false,
      sortBy: 'PRICE_DESC',
      sortByOptions: [
        {
          id: 'PRICE_ASC',
          label: 'Price: Low to High'
        },
        {
          id: 'PRICE_DESC',
          label: 'Price: High to Low'
        }
      ],
      selectedPath,
      startDate: undefined,
      endDate: undefined
    };
  },
  computed: {
    menu() {
      if (
        this.adShop &&
        this.adShop.navigationTree &&
        !Array.isArray(this.adShop.navigationTree)
      ) {
        return this.adShop.navigationTree;
      }

      return {};
    }
  },
  methods: {
    optimizedFile,
    onFilter(filter) {
      if (filter) {
        [this.startDate, this.endDate] = filter;
      } else {
        this.startDate = undefined;
        this.endDate = undefined;
      }
    },
    onSelectPath(selectedPath) {
      this.selectedPath = selectedPath;

      // Set the selected path in the URL
      let currentPath = { ...this.$route };
      currentPath.query = {
        ...currentPath.query,
        selectedPath: selectedPath.join('|')
      };

      this.$router.push(currentPath);
    }
  }
};
</script>

<style scoped lang="scss">
.section {
  padding-top: 1rem;
}
</style>
