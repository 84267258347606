<script>
import moment from 'moment';
import { dateDb } from '@/utils/filters';
import FlyteMultiPicker from '@/components/Core/FlytePicker/FlyteMultiPicker';

export default {
  mixins: [FlyteMultiPicker],
  props: {
    value: {
      type: Array,
      required: true
    },

    minDate: {
      type: [String, Object],
      default: () => {
        return dateDb();
      }
    },

    quantityLimit: {
      type: Object,
      default: () => {
        return {
          increment: 1,
          min: 1,
          max: null,
          unit: 'days'
        };
      }
    },

    insertionDates: {
      type: Array,
      default: null
    },

    // A callback function to validate each date to see if it is allowed to be an end date
    fnAllowEndDate: {
      type: Function,
      default: null
    }
  },

  computed: {
    selectedStartDate() {
      return this.value && this.value[0] ? moment(this.value[0]) : null;
    },

    isSelectingEndDate() {
      return this.selectedStartDate && !this.value[1];
    },

    availableDates() {
      if (this.insertionDates) {
        let dates = {};

        for (let date of this.insertionDates) {
          dates[dateDb(date)] = true;
        }

        return dates;
      } else {
        return null;
      }
    },

    /**
     * Returns a map of dates in the future that are allowed
     */
    allowedEndDates() {
      let dates = {};

      // Set the current date as 1 day before so we will count the actual current date as the first date
      // in the algorithm below
      let currDate = (this.selectedStartDate || moment(this.minDate))
        .clone()
        .subtract(1, 'day');
      let increment = 1;
      let min = 0;
      let max = 365;
      let unit = 'days';

      if (this.quantityLimit) {
        increment = this.quantityLimit.increment;
        min = this.quantityLimit.min;
        max = this.quantityLimit.max;
        unit = this.quantityLimit.unit.toLowerCase();

        // If max is not specified, default to 1 year
        if (!max || max < min) {
          switch (unit) {
            case 'weeks':
              max = 52 / increment;
              break;

            case 'days':
            default:
              max = 365 / increment;
              break;
          }
        }
      }

      // Set the initial offset for the minimum end date
      currDate.add(increment * (min - 1), unit);

      for (let i = min; i <= max; i++) {
        // Check the next end date to see if it is allowed
        currDate.add(increment, unit);

        let dateStr = dateDb(currDate);

        // If the date does not pass the external validation check
        // skip it
        if (this.fnAllowEndDate) {
          let result = this.fnAllowEndDate(
            this.selectedStartDate,
            currDate,
            dateStr
          );

          // a -1 response means all future end dates from here are not allowed
          if (result === -1) {
            break;
          } else if (!result) {
            // a false response means this end date is not allowed
            continue;
          }
        }

        if (dateStr) {
          dates[dateStr] = true;
        }
      }

      return dates;
    }
  },

  mounted() {
    this.$on('input', this.onInput);

    // Set null values to undefined as the DatePicker doesn't handle null values very well
    if (this.value) {
      if (this.value[0] === null || this.value[1] === null) {
        this.$emit('input', [
          this.value[0] || undefined,
          this.value[1] || undefined
        ]);
      }
    } else {
      this.$emit('input', []);
    }

    this.$on('date-render', this.onRenderAvailableDate);
    this.$on('date-click', this.onDateClick);
  },

  methods: {
    onInput() {
      // Inform the onDateClick check the input was recently changed
      // so we don't unselect the date
      this.recentlyChanged = true;

      this.$nextTick(() => {
        this.syncConfig();
      });
    },
    syncConfig() {
      this.$emit('update:config', {
        ...this.config,
        minDate: this.isSelectingEndDate
          ? dateDb(this.selectedStartDate)
          : dateDb(this.minDate)
      });
    },
    onDateClick(date) {
      // If this date was not recently changed, and the date clicked was the selected start
      // date, that mean the user is trying to deselect the start date
      if (
        !this.recentlyChanged &&
        this.selectedStartDate &&
        dateDb(date) === dateDb(this.selectedStartDate)
      ) {
        // reset the date selection
        this.$emit('input', []);
      }

      // Unflag the recent change
      this.recentlyChanged = false;
    },
    onRenderAvailableDate(date) {
      let dateStr = dateDb(date.date);

      if (this.isSelectingEndDate) {
        if (!this.allowedEndDates[dateStr]) {
          date.el.className += ' flatpickr-disabled';
        }
      } else {
        if (this.availableDates && !this.availableDates[dateStr]) {
          date.el.className += ' flatpickr-disabled';
        }
      }
    }
  }
};
</script>
