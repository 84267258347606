<template>
  <div class="page-storefront max-w-2xl mx-auto">
    <cart-query>
      <template v-slot="{ isLoading, data: cart }">
        <loading-page v-if="isLoading" />
        <product-browser v-else-if="cart" :cart="cart" />
        <el-alert v-else type="error" title="Ad Shop Error">
          Failed to load your cart for the Ad Shop. Please contact us for more
          information
        </el-alert>
      </template>
    </cart-query>
  </div>
</template>

<script>
import { CartQuery } from '@/components/Queries';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import ProductBrowser from '@/components/AdShop/PageStorefront/ProductBrowser.vue';

export default {
  components: {
    CartQuery,
    LoadingPage,
    ProductBrowser
  }
};
</script>
