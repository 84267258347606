<template>
  <div
    id="edit-styling-button"
    class="edit-styling-card card bg-white relative p-3 leading-none z-30 overflow-hidden transition-all"
    :class="active ? 'w-100 h-28' : 'card-circle button-circle'"
  >
    <el-tooltip :disabled="!isDisabled" effect="light">
      <template #content>
        <div class="w-40">
          <a
            href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
            target="_blank"
            >Learn more about user permission roles
          </a>
        </div>
      </template>
      <div
        :class="{ 'is-disabled opacity-50': isDisabled }"
        class="absolute top-0 right-0 p-3 button-white-blue button-circle"
        @click="!isDisabled && $emit('edit')"
      >
        <icon :icon="pencilIcon" class="icon-lg" />
      </div>
    </el-tooltip>

    <div class="header">
      <transition appear name="fade" leave-active-class="none">
        <span v-if="showButtons" class="font-bold">Ad Shop Styling</span>
      </transition>
    </div>

    <transition appear name="fadeDown" leave-active-class="none">
      <div v-if="showButtons" class="flex z-15 mt-8 flex -mx-2">
        <el-button class="mx-2 flex-1 button-white" @click="$emit('cancel')">
          Cancel
        </el-button>
        <el-button
          class="mx-2 flex-1 button-green"
          @click="$emit('publish-changes')"
        >
          Publish Changes
        </el-button>
      </div>
    </transition>
  </div>
</template>

<script>
import { pencil as pencilIcon } from '@/vendor/icons/index';

/* EditStylingButton
 *
 * Props:
 *   'active' if true displays card
 *
 * Emits:
 *   'edit' - to edit changes
 *   'publish-changes' when Publish Changes button clicked
 *   'cancel' when Cancel button clicked
 */
export default {
  props: {
    active: Boolean,
    isDisabled: Boolean
  },
  data() {
    return {
      pencilIcon,
      showButtons: false
    };
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          this.showButtons = true;
        }, 200);
      } else {
        this.showButtons = false;
      }
    }
  }
};
</script>
