<template>
  <div class="non-contiguous-booking text-md relative">
    <inventory-query
      :filter="{
        sku: variant.sku
      }"
      @data="onInventory"
    />

    <div class="font-semibold mb-2">Dates</div>

    <template v-if="skuInventory">
      <div class="flex">
        <flyte-multi-picker
          v-if="availableDates.length > 0"
          ref="multipicker"
          v-model="selectedDates"
          manual-popover
          :count="1"
          :config="pickerOptions"
          :first-date="firstDate"
          @date-click="onDateClicked"
          @hide-popover="addLineItem"
          @date-render="onInventoryRenderDate"
        >
          <template #hover="{ date }">
            <booking-date-popover
              has-cart
              :is-cart="isCart"
              :sku-date="getSkuDate(date.date)"
              :unit="variant.unit.name"
            />
          </template>

          <div ref="popoverSlot" slot="popover" class="w-64">
            <div class="flex justify-between items-center">
              <span>Number of {{ variant.unit.name }}</span>
              <div
                class="flex justify-between items-center w-1/2"
                :class="{ 'is-sku-overselected': isSkuOverSelected }"
              >
                <el-input
                  v-if="currentSelectedDate"
                  v-mask.integer="orderQuantityMask"
                  :value="selectedQuantity"
                  autofocus
                  class="mr-4"
                  @input="selectedQuantity = parseInt($event, 10) || 1"
                  @keyup.native="checkKey"
                />
                <icon
                  :icon="trashEmpty"
                  class="hover:text-red transition-all transition-d-.2 text-dark-silver cursor-pointer"
                  @click="removeLineItemAtIndex()"
                />
              </div>
            </div>
            <el-alert
              v-if="isSkuOverSelected"
              title=""
              type="error"
              class="mt-3 text-center"
              :closable="false"
            >
              Product is overbooked
            </el-alert>
          </div>
        </flyte-multi-picker>

        <!-- Per Date Configurator -->
        <div class="dates pl-4">
          <div v-if="dates.length > 0" class="mb-2">
            <span class="font-semibold">Selected</span>
          </div>
          <transition-group name="fade" class="animate-position">
            <div
              v-for="(productDate, index) in dates"
              :key="
                `date-${productDate.start_date + '-' + productDate.end_date}`
              "
              class="border-bottom margin-bottom pad-bottom w-full"
            >
              <div class="flex justify-between items-center">
                <icon
                  class="hover:text-red text-dark-silver cursor-pointer"
                  :icon="trashEmpty"
                  @click="removeLineItemAtIndex(index)"
                />

                <div
                  class="flex flex-col px-3 cursor-pointer transition-all transition-d-.2 hover:opacity-60"
                  @click="openQuantityPopOver(productDate.start_date)"
                >
                  <div class="font-semibold text-dark-gray">
                    {{ productDate.start_date | date }}
                    <template v-if="isMultipleDayProductDate(productDate)">
                      <span> - </span>
                      <span>{{ productDate.end_date | date }}</span>
                    </template>
                  </div>
                  <div class="text-dark-silver flex">
                    <span>Qty: {{ productDate.quantity }}</span>
                    <el-tooltip
                      v-if="isSelectedDateOverbooked(productDate.start_date)"
                      effect="light"
                    >
                      <div class="ml-3 text-red">
                        <icon :icon="warningIcon" />
                      </div>
                      <template #content>
                        <div class="text-red">
                          This product is overbooked
                        </div>
                      </template>
                    </el-tooltip>
                  </div>
                </div>

                <!-- Slot for Content to the right of each productDate. Usually used for line price.  -->
                <div class="font-semibold text-dark-gray self-start">
                  <slot class="price" :product-date="productDate" />
                </div>
              </div>
              <div
                v-if="notBookableDate(productDate)"
                class="mt-3 cursor-pointer"
                @click="removeLineItemAtIndex(index)"
              >
                <el-alert
                  type="error"
                  :title="
                    'Date is past fulfillment deadline.\nClick here to remove'
                  "
                  :closable="false"
                />
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </template>
    <template v-else>
      <loader-box :height="20" />
    </template>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { attention as warningIcon, trashEmpty } from '@/vendor/icons';
import { RateType } from '@/constants';
import { remove } from '@/utils/helpers';
import { dateDb, datetimeDb } from '@/utils/filters';
import { cloneDeep, map } from 'lodash';

import BookingDatePopover from '@/components/AdShop/Booking/BookingDatePopover';
import FlyteMultiPicker from '@/components/Core/FlytePicker/FlyteMultiPicker';
import InventoryBookingMixin from '@/components/AdShop/Booking/InventoryBookingMixin';
import InventoryQuery from '@/components/Queries/Booking/InventoryQuery';

export default {
  components: {
    BookingDatePopover,
    FlyteMultiPicker,
    InventoryQuery
  },
  mixins: [InventoryBookingMixin],
  props: {
    // Dates available to be booked
    availableDates: {
      type: Array,
      required: true
    }
  },

  data() {
    const selectedDates = map(this.dates, 'start_date');

    return {
      currentSelectedDate: '',
      defaultSelectedDate: null,
      isDateInitialized: false,
      selectedDates,
      selectedQuantity: 1,
      shouldAddLineItem: true,

      // Icons
      trashEmpty,
      warningIcon
    };
  },

  computed: {
    rateType() {
      let rateSheet = this.variant.rateSheets.find(
        rs => rs.type !== RateType.IMPRESSIONS.value
      );

      if (rateSheet) {
        return rateSheet.type;
      } else {
        return RateType.PER_DAY.value;
      }
    },

    selectedSkuDate() {
      return this.getSkuDate(this.currentSelectedDate);
    },

    firstDate() {
      const sorted = [...this.availableDates].sort((a, b) => {
        return moment(a) - moment(b);
      });
      return dateDb(sorted[0]);
    },

    pickerOptions() {
      return {
        enable: this.availableDates,
        minDate: this.firstDate
      };
    },

    orderQuantityMask() {
      let max = this.canOverbook
        ? undefined
        : this.selectedSkuDate.available_quantity;

      return { min: 1, max };
    },

    isSkuOverSelected() {
      if (this.selectedSkuDate) {
        return (
          this.selectedQuantity +
            // If this is the cart the selected dates are the same as the cart date quantities (so don't count twice)
            (this.isCart
              ? 0
              : this.getCartDateBooking(this.selectedSkuDate.date).quantity) >
          this.selectedSkuDate.available_quantity
        );
      } else {
        return false;
      }
    }
  },

  watch: {
    dates() {
      let filteredSelectedDates = this.selectedDates.filter(selectedDate => {
        let compareDate = moment(selectedDate);
        return this.dates.find(d => compareDate.isSame(d.start_date, 'day'));
      });

      if (filteredSelectedDates.length !== this.selectedDates) {
        this.selectedDates = filteredSelectedDates;
      }
    }
  },

  methods: {
    notBookableDate(productDate) {
      return !this.availableDates.includes(productDate.start_date);
    },

    checkKey(e) {
      const keyCode = e.key || e.keyCode;
      if (parseInt(keyCode, 10) === 13 || keyCode === 'Enter') {
        this.$refs.multipicker.closePopover();
      }
    },

    onDateClicked(date, selected) {
      // If this will be overbooked by adding 1 ad, then do not show popover
      // or add to selected dates if user is not authorized to overbook
      if (!this.canOverbook && this.isSelectedDateOverbooked(date)) {
        return;
      }

      // If the date was selected, add this date as a line item
      // and open the popover
      if (selected) {
        this.currentSelectedDate = dateDb(date);
        this.$nextTick(() => {
          this.addLineItem();
          this.openQuantityPopOver(date);
        });
      } else {
        // If the date was unselected, we want to keep it selected (user cannot remove selection via calendar)
        // and open the popover so they can modify the already selected date
        this.selectedDates.push(date);
        this.$nextTick(() => {
          this.openQuantityPopOver(date);
        });
      }
    },

    isMultipleDayProductDate(productDate) {
      return (
        moment(productDate.start_date).diff(productDate.end_date, 'days') > 0
      );
    },

    findDateInDates(date) {
      return this.dates.find(d => moment(d.start_date).isSame(date, 'day'));
    },

    findDateInDatesIndex(date) {
      return this.dates.findIndex(d =>
        moment(d.start_date).isSame(date, 'day')
      );
    },

    openQuantityPopOver(date) {
      this.currentSelectedDate = dateDb(date);
      let pickedDate = this.findDateInDates(this.currentSelectedDate);

      // If the selected Dates gets desync'd w/ our added list of dates
      // then we want to make sure we add it before opening the pop over.
      // Yes, this is whole component is very convoluted and should be re-written entirely
      if (!pickedDate) {
        this.addLineItem();

        return this.$nextTick(() => {
          this.openQuantityPopOver(date);
        });
      }

      if (pickedDate) {
        this.selectedQuantity = pickedDate.quantity;

        this.$refs.multipicker.openPopover(date);
      } else {
        this.$message.error('The date selected is not available');
      }
    },

    sortDates(dates) {
      return cloneDeep(dates).sort(
        (a, b) => moment(a.start_date) - moment(b.start_date)
      );
    },

    addDateToDates(date) {
      const dates = [...this.dates];
      const startDate = moment(date || this.currentSelectedDate);

      dates.push({
        type: this.rateType,
        start_date: datetimeDb(startDate.startOf('day')),
        end_date: datetimeDb(moment(startDate).endOf('day')),
        quantity: this.selectedQuantity
      });

      this.$emit('update:dates', this.sortDates(dates)); // Make sure these are sorted
      this.selectedQuantity = 1;
    },

    // This method is called when "hide-popover" event is fired
    addLineItem() {
      if (this.shouldAddLineItem) {
        const selectedProductDateIndex = this.findDateInDatesIndex(
          this.currentSelectedDate
        );

        // If we've selected an existing productDate, then let's update quantity
        if (selectedProductDateIndex >= 0) {
          const tempDates = cloneDeep(this.dates);
          tempDates[selectedProductDateIndex].quantity = this.selectedQuantity;
          this.$emit('update:dates', tempDates);
          this.selectedQuantity = 1;
        } else {
          this.addDateToDates();
        }
      }
    },

    removeLineItemAtIndex(index) {
      const updatedDates = [...this.dates];
      let dateToRemove;

      // Get the index of the current selected date if no index is supplied
      if (index >= 0) {
        dateToRemove = updatedDates[index];
      } else {
        dateToRemove = this.findDateInDates(this.currentSelectedDate);
      }

      const selectedDateToRemove = this.selectedDates.findIndex(d =>
        moment(d).isSame(dateToRemove.start_date, 'day')
      );

      this.selectedDates.splice(selectedDateToRemove, 1);
      remove(updatedDates, dateToRemove);

      // Close the popover, but do not add a line item on popover closing
      // This ensures we don't call "addLineItem" when the popover is closed
      this.shouldAddLineItem = false;
      this.$refs.multipicker.closePopover();

      // Allow adding line items again when the popover closes
      this.$nextTick(() => {
        this.shouldAddLineItem = true;
      });

      this.$emit('update:dates', updatedDates);
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables.scss';

.dates {
  max-height: 26em;
  overflow-y: auto;
}

.is-sku-overselected {
  /deep/ .el-input__inner {
    background: lighten($color-red, 30%);
    color: $color-red;
    border-color: $color-red;
  }
}
</style>
