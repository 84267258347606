<template>
  <div class="settings-pane">
    <page-overlay
      cover="40em"
      location="right"
      manual-close
      @click-outside="onCancel"
    >
      <ad-shop-terms-dialog
        v-if="showTermsDialog"
        @change="form.is_enabled = $event"
        @close="showTermsDialog = false"
      />

      <div class="p-8">
        <div class="header">Ad Shop Settings</div>
        <div>
          <div class="form-section">
            <p class="label">Enable/Disable</p>
            <custom-el-switch
              v-model="form.is_enabled"
              green-red
              active-text="Your ad shop is enabled"
              inactive-text="Your ad shop is disabled"
              @change="onAdShopToggle"
            />
            <p
              class="cursor-pointer pt-3 hover:text-blue text-dark-silver text-xs underline"
              @click="showTermsDialog = true"
            >
              Terms & Conditions
            </p>
          </div>

          <div class="form-section">
            <p class="label">Allow Credit Card Payments?</p>
            <custom-el-switch
              v-model="form.allow_credit_card_payment"
              green-red
              active-text="Customers can pay via Credit Card"
              inactive-text="Customers may not pay via Credit Card"
            />
          </div>

          <div
            v-if="$can('pub_modify_organization_settings')"
            class="form-section"
          >
            <p class="label">Require Verifications For Ad Shop Orders?</p>
            <custom-el-switch
              v-model="form.require_verifications"
              green-gray
              active-text="Verifications are required"
              inactive-text="Verifications are optional"
            />
          </div>

          <div class="form-section">
            <p class="label">Policies</p>
            <div class="form-input">
              <p class="label">
                Upload a file that outlines your standard policies or terms and
                conditions. Ad Shop buyers will be able to view and agree to
                these policies during checkout.
              </p>

              <div class="w-64">
                <upload-box
                  v-model="form.policiesFile"
                  accept="application/pdf"
                  class="margin-top-xs"
                  item-class="text-center"
                  :multiple="false"
                />
              </div>
            </div>
          </div>

          <div class="form-section">
            <div class="label">Ad Shop Profile Photo</div>
            <div class="form-input">
              <div class="label">
                Choose whether you'd like your ad shop profile photo to appear
                on insertion orders and invoices generated from your billing
                page.
              </div>
              <custom-el-switch
                v-model="form.use_logo_on_billing_docs"
                green-gray
                active-text="Show my logo on billing documents"
                inactive-text="Show my logo on billing documents"
              />
            </div>
          </div>

          <div class="form-section">
            <p class="label">Questions?</p>
            <p>
              For help setting up and using your ad shop, visit our
              <a href="#">support page</a> or email us at
              <a href="#">help@flytedesk.com</a>
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <template v-if="!loading">
          <el-button class="cancel" @click="onCancel">Cancel</el-button>
          <el-button
            class="save"
            type="success"
            :disabled="!hasChanges"
            @click="onSave"
            >Save Changes
          </el-button>
        </template>
        <loading-button v-else />
      </div>
    </page-overlay>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { download as downloadIcon, upload as uploadIcon } from '@/vendor/icons';

import AdShopTermsDialog from '@/components/Core/AdShopTermsDialog';
import CustomElSwitch from '@/components/Custom/CustomElSwitch';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import PageOverlay from '@/components/Core/PageOverlay';
import UploadBox from '@/components/Core/UploadBox';

export default {
  components: {
    AdShopTermsDialog,
    CustomElSwitch,
    LoadingButton,
    PageOverlay,
    UploadBox
  },
  props: {
    adShop: {
      type: Object,
      required: true
    },
    loading: Boolean
  },
  data() {
    return {
      uploadIcon,
      downloadIcon,
      form: {
        is_enabled: false,
        use_logo_on_billing_docs: false,
        allow_credit_card_payment: true,
        require_verifications: true,
        policiesFile: undefined,
        ...cloneDeep(this.adShop)
      },
      showTermsDialog: false
    };
  },

  computed: {
    areAdShopTermsAccepted() {
      return this.$store.getters['auth/areAdShopTermsAccepted'];
    },
    hasChanges() {
      return (
        this.form.is_enabled !== this.adShop.is_enabled ||
        this.form.allow_credit_card_payment !==
          this.adShop.allow_credit_card_payment ||
        this.form.require_verifications !== this.adShop.require_verifications ||
        this.form.policiesFile?.id !== this.adShop.policiesFile?.id ||
        this.form.use_logo_on_billing_docs !==
          this.adShop.use_logo_on_billing_docs
      );
    }
  },

  methods: {
    onCancel() {
      // If changes have occurred display confirmation box
      if (this.hasChanges) {
        this.$messageBox
          .confirm(
            "You've made some changes. Would you like to discard those changes?",
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          )
          .then(() => {
            this.$emit('cancel');
          })
          .catch(() => {});
      } else {
        this.$emit('cancel');
      }
    },
    onAdShopToggle() {
      if (this.form.is_enabled && !this.areAdShopTermsAccepted) {
        this.showTermsDialog = true;
        this.form.is_enabled = false;
      }
    },
    onSave() {
      this.$emit('save', {
        ...this.form,
        policies_file_id: this.form.policiesFile?.id || null
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.content {
  padding: 2em;
}

.header {
  font-size: 1.5em;
  font-weight: bolder;
}

.form-section {
  padding: 1.5em 0;

  & + .form-section {
    border-top: 2px $color-gray solid;
  }

  & > .label {
    font-weight: bold;
    font-size: 0.8em;
    margin-bottom: 1em;
  }

  .form-input {
    & + .form-input {
      margin-top: 1em;
    }

    .label {
      font-size: 0.8em;

      & + * {
        margin-top: 0.5em;
      }
    }
  }
}

.el-upload {
  .upload-icon {
    font-size: 67px;
    color: #c0c4cc;
    margin: 40px 0 16px;
    line-height: 50px;
  }

  .file-pdf-link {
    margin-top: 0.25em;
  }
}

.footer {
  display: flex;
  padding: 2em;

  .el-button {
    width: 10em;

    &.save {
      margin-left: auto;
      background-color: $color-green;
      color: white;
    }
  }
}
</style>
