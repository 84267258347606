<template>
  <!-- Fulfillment Method Selection -->
  <el-form class="fulfillment">
    <custom-el-form-item
      label="Fulfillment"
      class="fulfillment-title text-md font-semibold"
    >
      <div
        v-for="fulfillmentMethod in fulfillmentMethods"
        :key="`fulfillment-method-${fulfillmentMethod.id}`"
        class="fulfillment-option margin-bottom"
      >
        <div class="fulfillment-box">
          <el-radio-group
            :value="value"
            class="w-full"
            @input="$emit('input', $event)"
          >
            <el-radio
              :key="`fulfillment-method-${fulfillmentMethod.id}`"
              :label="fulfillmentMethod"
              class="radio-inline-block w-full themed"
              @change="$emit('input', $event)"
            >
              <div class="inline-block w-full text-dark-gray">
                <div class="flex -m-3">
                  <div class="block p-3 flex-1">
                    <div class="font-sm font-semibold">
                      {{ fulfillmentMethod.display_name }}
                    </div>
                    <div class="description whitespace-pre-wrap">
                      {{ fulfillmentMethod.description }}
                    </div>
                  </div>
                  <div class="block p-3 flex-1 relative">
                    <div class="divider">
                      <div class="font-sm font-semibold">Due:</div>
                      <div class="description">
                        {{ fulfillmentMethod.deadline_days }} business days
                        before run date
                      </div>
                    </div>
                  </div>

                  <div class="block p-3 fulfillment-price">
                    <div class="font-semibold themed-color">
                      +{{ fulfillmentMethod.price | currency }}
                    </div>
                  </div>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </custom-el-form-item>
  </el-form>
</template>

<script>
import CustomElFormItem from '@/components/Custom/CustomElFormItem';

export default {
  components: {
    CustomElFormItem
  },
  props: {
    fulfillmentMethods: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      default: null
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/scss/_variables';

.fulfillment-option {
  border: 1px solid $color-gray;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
}

.description {
  color: $color-dark-silver;
  margin-top: 0.5em;
  line-height: 1.2em;
}

.divider {
  border-left: 2px solid $color-gray;
  height: 100%;
  padding-left: 2em;
}
</style>
