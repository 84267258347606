<template>
  <div class="ad-shop-product">
    <div class="flex justify-between">
      <!-- Product Images -->
      <div class="w-5/12 pr-4 image-carousel images-section">
        <el-carousel height="450px">
          <el-carousel-item v-for="url in productImageUrls" :key="`url-${url}`">
            <img :src="url" alt="product" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="w-7/12 product-details">
        <!-- Product Name and Description -->
        <div class="mb-6">
          <h1 class="product-name">{{ product.name }}</h1>
          <p class="product-description mt-2 whitespace-pre-wrap text-md">
            {{ product.description }}
          </p>
        </div>

        <template v-if="availableProductVariants.length > 0">
          <!-- Product Variant Select -->
          <el-select
            v-model="selectedProductVariant"
            class="product-variant-select w-full themed"
            placeholder="Unavailable"
            value-key="id"
          >
            <el-option
              v-for="productVariant in availableProductVariants"
              :key="`variant-${productVariant.id}`"
              :label="productVariant.name"
              :value="productVariant"
            />
          </el-select>

          <div class="text-md text-dark-silver mt-3">
            <el-tooltip effect="light">
              <div>Product SKU: {{ selectedProductVariant.sku }}</div>
              <template #content>
                <sku-tooltip-content />
              </template>
            </el-tooltip>
          </div>

          <div class="mt-6">
            <template v-if="isBookable">
              <transition-group name="fade" class="animate-position">
                <contiguous-booking
                  v-if="isContiguous"
                  :key="'contiguous-booking-' + selectedProductVariant.id"
                  :available-dates="bookableDates"
                  :cart="cart"
                  :dates.sync="dates"
                  :product="product"
                  :rate-types="rateTypes"
                  :variant="selectedProductVariant"
                />

                <!-- Product Variant Date Selection: Non-Contiguous (Select individual dates) -->
                <non-contiguous-booking
                  v-else
                  :key="'non-contiguous-booking'"
                  :cart="cart"
                  :variant="selectedProductVariant"
                  :dates.sync="dates"
                  :available-dates="bookableDates"
                >
                  <template slot-scope="{ productDate }">
                    {{
                      calculateProductVariantDatePrice(productDate) | currency
                    }}
                  </template>
                </non-contiguous-booking>

                <!-- Fulfillment Method Selection -->
                <ad-shop-fulfillment
                  v-if="fulfillmentMethods.length > 0"
                  :key="'ad-shop-fulfillment'"
                  v-model="selectedFulfillmentMethod"
                  :fulfillment-methods="fulfillmentMethods"
                  class="mt-6"
                />

                <div :key="'total-product-price'" class="mt-6 text-md">
                  Total product price:
                  <strong>{{ totalPrice | currency }}</strong>
                </div>

                <div :key="'add-to-cart-btn'" class="mt-2">
                  <cart-mutation :cart-id="cart.id">
                    <template slot-scope="{ addProductVariantGroupToCart }">
                      <div v-if="hasUnbookableDates" class="my-3">
                        <el-alert
                          type="error"
                          :closable="false"
                          title="Please remove all unbookable dates or change your fulfillment method"
                        />
                      </div>
                      <el-button
                        v-if="!addingToCart"
                        type="primary"
                        :disabled="!addToCartReady"
                        class="w-full themed"
                        @click="addToCart(addProductVariantGroupToCart)"
                      >
                        Add to cart
                      </el-button>
                      <loading-button v-else />
                    </template>
                  </cart-mutation>
                </div>

                <div :key="'cart-alerts'" class="mt-2">
                  <el-alert
                    v-if="successMessage"
                    type="success"
                    title=""
                    class="text-md"
                  >
                    <span class="mr-1">{{ successMessage }}</span>
                    <router-link
                      :to="{
                        name: 'adShop.cart',
                        params: { cart_id: $route.params.cart_id }
                      }"
                    >
                      View Cart
                    </router-link>
                    <span class="mx-1">or</span>
                    <router-link
                      :to="{
                        name: 'adShop',
                        params: { cart_id: $route.params.cart_id }
                      }"
                    >
                      Keep Shopping
                    </router-link>
                  </el-alert>

                  <el-alert
                    v-if="errorMessage"
                    type="error"
                    :title="errorMessage"
                  />
                </div>
              </transition-group>
            </template>

            <template v-else>
              <el-alert
                type="error"
                :title="
                  'There are no Booking Options available for this product as a ' +
                    RateClassLabel[cart.rate_class]
                "
                :closable="false"
                show-icon
                center
              />
            </template>
          </div>
        </template>
        <template v-else>
          <el-alert type="error" title="" :closable="false">
            There are no variants available for a
            {{ RateClassLabel[cart.rate_class] }}
          </el-alert>
        </template>
      </div>
    </div>

    <div v-if="showAdditionalFilesSection">
      <hr class="divider" />
      <h2 class="additional-info-title margin-top-xl">Additional Info</h2>
      <div class="flex">
        <div
          v-if="isAdditionalInfo"
          class="additional-info margin-top"
          :class="isAdditionalFiles ? 'pad-right-xxl w-1/2 border-right' : ''"
        >
          {{ product.additional_information }}
        </div>
        <div
          v-if="isAdditionalFiles"
          class="additional-info margin-top flex"
          :class="isAdditionalInfo ? 'pad-left-xxl w-1/2' : ''"
        >
          <div
            v-for="file in product.additionalFiles"
            :key="`file-id-${file.id}`"
          >
            <file-item
              v-if="showFile(file)"
              :file="file"
              enable-preview
              :can-download="false"
              :can-delete="false"
              :show-filename="false"
              class="margin-right box-shadow--sm"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { filter, intersection, map, orderBy, reduce } from 'lodash';
import {
  FeatureFlags,
  RateClassesAllowed,
  RateClassLabel,
  RateType,
  ScheduleUnit
} from '@/constants';
import { formatGraphQLError, getImageType } from '@/utils/helpers';

import AdShopFulfillment from '@/components/AdShop/PageStoreProduct/AdShopFulfillment';
import BookableDatesMixin from '@/components/AdShop/Cart/BookableDatesMixin';
import ContiguousBooking from '@/components/AdShop/Booking/ContiguousBooking';
import FileItem from '@/components/Core/FileItem';
import LoadingButton from '@/components/Core/Loading/LoadingButton';
import NonContiguousBooking from '@/components/AdShop/Booking/NonContiguousBooking';
import { CartMutation } from '@/components/Mutations';
import SkuTooltipContent from '@/components/Core/Tooltips/SkuTooltipContent';

export default {
  components: {
    SkuTooltipContent,
    AdShopFulfillment,
    CartMutation,
    ContiguousBooking,
    FileItem,
    LoadingButton,
    NonContiguousBooking
  },

  mixins: [BookableDatesMixin],

  props: {
    cart: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      RateClassLabel,
      selectedProductVariant: this.product.variants[0],
      selectedFulfillmentMethod: null,
      dates: [],
      errorMessage: false,
      successMessage: false,

      // loading statuses
      addingToCart: false
    };
  },

  computed: {
    isBookable() {
      return this.rateTypes.length > 0;
    },

    bookableDates() {
      return this.getBookableDates(
        this.product.collection.schedule.upcomingDates,
        this.selectedFulfillmentMethod
      );
    },

    hasUnbookableDates() {
      for (let date of this.dates) {
        if (!this.bookableDates.includes(date.start_date)) {
          return true;
        }
      }

      return false;
    },

    availableProductVariants() {
      // Variants are guaranteed to be available if this product is selected (would not show up in ad shop otherwise)
      return this.product.variants.filter(
        v =>
          v.is_bookable &&
          v.is_bookable_ad_shop &&
          intersection(v.enabled_rate_classes, this.allowedRateClasses).length >
            0
      );
    },

    productImageUrls() {
      return this.product.photos.map(photo => photo.url);
    },

    /**
     * TODO This will need to be re-factored when adding in rates by date range
     *
     * Retrieves all the Rates for each Rate Type in the selected Rate Class
     */
    rateTypes() {
      let rateTypes = [];

      if (!this.selectedProductVariant) {
        return [];
      }

      for (const rateSheet of this.selectedProductVariant.rateSheets) {
        // Select only the rates that are available (non-zero pricing)
        let availableRates = filter(rateSheet.rates, r => r.price > 0);

        // Find the Best Rate Class out of all the available pricing
        let bestRateClass = this.pickBestRateClass(
          map(availableRates, 'rate_class')
        );

        // The Best rates available for this Buyer based on their selected rate class
        let bestAvailableRates = filter(
          rateSheet.rates,
          r => r.rate_class === bestRateClass
        );

        if (bestAvailableRates.length > 0) {
          rateTypes.push({
            type: rateSheet.type,
            rateClass: bestRateClass,
            minPrice: reduce(
              bestAvailableRates,
              (min, r) => Math.min(r.price, min),
              Infinity
            ),
            rates: bestAvailableRates
          });
        }
      }

      // Or the rate types
      return orderBy(rateTypes, r =>
        r.type === RateType.IMPRESSIONS.value ? 1 : -1
      );
    },

    /**
     * The available fulfillment methods for this product variant
     */
    fulfillmentMethods() {
      const ids =
        this.selectedProductVariant &&
        this.selectedProductVariant.attributes['Fulfillments'];

      if (!ids) {
        return [];
      }

      return this.product.fulfillmentMethods.filter(f => {
        return ids.indexOf(f.id) !== -1;
      });
    },

    totalPrice() {
      return this.dates.reduce((sum, g) => {
        return sum + this.calculateProductVariantDatePrice(g);
      }, 0);
    },

    /**
     * Is this component showing a contiguously scheduled product.
     */
    isContiguous() {
      return (
        this.product.collection.property.scheduleType.value === 'Contiguous'
      );
    },

    bookingType() {
      if (this.dates.length > 0) {
        return this.dates[0].type;
      } else {
        return null;
      }
    },

    isAdditionalInfo() {
      return this.product.additional_information;
    },

    isAdditionalFiles() {
      return this.product.additionalFiles.length > 0;
    },

    /**
     * The Rate Sheet that applies to the currently selected options.
     *
     * This will be unique based on the Rate Class, Product Variant, Booking Type and Quantity
     */
    rateSheet() {
      let rateSheet = this.selectedProductVariant.rateSheets // TODO For now, we just grab the default as we are not worrying about quantity
        .find(s => s.is_default && s.type === this.bookingType);

      if (!rateSheet) {
        console.error(
          'Cannot resolve rate sheet for booking type',
          this.bookingType,
          this.selectedProductVariant.rateSheets
        );
      }

      return rateSheet;
    },

    /**
     * Is the user's selection ready to be added to the cart.
     */
    addToCartReady() {
      return this.dates.length > 0 && !this.hasUnbookableDates;
    },

    /**
     * Returns an array of all allowed Rate Classes for this Buyer's Rate Class
     * Ordered by the Highest Priority Rate Class
     */
    allowedRateClasses() {
      return RateClassesAllowed[this.cart.rate_class];
    },

    fulfillmentFee() {
      if (this.selectedFulfillmentMethod) {
        return +this.selectedFulfillmentMethod.price;
      } else {
        return 0;
      }
    },

    /**
     * return the most applicable Rate for the current product / options
     */
    rate() {
      for (let rateClass of this.allowedRateClasses) {
        let rate = this.getRate(rateClass);

        if (rate) {
          return rate;
        }
      }

      return null;
    },

    showAdditionalFilesSection() {
      return this.isAdditionalInfo || this.isAdditionalFiles;
    }
  },

  watch: {
    product() {
      this.setDefaults();
    }
  },

  async created() {
    this.setDefaults();
  },

  methods: {
    /**
     * Given an array of Rate Classes, chooses the highest priority rate class available for this Buyer's Rate Class
     *
     * @param rateClasses
     */
    pickBestRateClass(rateClasses) {
      // allowed Rate Classes are ordered by priority (highest first)
      for (let rateClass of this.allowedRateClasses) {
        // If this rate class exists in the given list, this must be the best highest priority rate class available
        if (rateClasses.includes(rateClass)) {
          return rateClass;
        }
      }

      return null;
    },

    getRate(rateClass) {
      if (this.rateSheet) {
        return this.rateSheet.rates.find(r => r.rate_class === rateClass);
      } else {
        return null;
      }
    },

    setDefaults() {
      // Set Default Product Variant
      let variants = this.availableProductVariants;
      this.selectedProductVariant = variants[0];

      // Set Default Fulfillment Method
      let fulfillmentMethods = this.fulfillmentMethods;
      this.selectedFulfillmentMethod = fulfillmentMethods[0];
    },

    async addToCart(addProductVariantGroupToCart) {
      this.successMessage = false;
      this.errorMessage = false;

      if (this.dates.length === 0) {
        this.$message.error(
          'Please complete the form to add this product to the cart'
        );
        return;
      }

      const params = {
        productVariantId: this.selectedProductVariant.id,
        fulfillmentMethodId:
          this.selectedFulfillmentMethod && this.selectedFulfillmentMethod.id,
        dates: this.dates.map(d => ({
          start_date: d.start_date,
          end_date: d.end_date,
          type: d.type,
          quantity: d.quantity
        }))
      };

      try {
        this.addingToCart = true;
        await addProductVariantGroupToCart(params);
        this.successMessage = `Added ${this.selectedProductVariant.name} to your cart! Feel free to add more from this product.`;

        this.clearSelections();
      } catch (e) {
        this.errorMessage = formatGraphQLError(e);
      } finally {
        this.addingToCart = false;
      }
    },

    clearSelections() {
      this.dates = [];
    },

    calculateProductVariantDatePrice(productDate) {
      if (!this.rate) {
        this.$message.error(
          'This product is not available to the current rate class.'
        );
        return 0;
      }

      const schedule = this.product.collection.schedule;

      const pricePerOrder =
        Number(this.rate.price) / this.rate.quantity / this.rateSheet.quantity +
        (this.$feature(FeatureFlags.FULFILLMENT_FEE_QUANTITY)
          ? 0
          : this.fulfillmentFee);

      const runningDays =
        moment(productDate.end_date).diff(
          moment(productDate.start_date),
          'days'
        ) + 1;

      let totalPrice = 0;

      switch (productDate.type) {
        case RateType.PER_QUANTITY.value:
        case RateType.PER_DAY.value:
          totalPrice = runningDays * pricePerOrder * productDate.quantity;
          break;
        case RateType.FLAT_RATE.value:
          totalPrice = pricePerOrder;
          break;
        case RateType.PER_INTERVAL.value:
          totalPrice =
            ((pricePerOrder * runningDays) /
              (schedule.quantityLimit.increment *
                (schedule.quantityLimit.unit === ScheduleUnit.WEEKS ? 7 : 1))) *
            productDate.quantity;
          break;
        case RateType.IMPRESSIONS.value:
          totalPrice = productDate.quantity * pricePerOrder;
          break;
        default:
          throw new Error('Unknown Rate Type: ' + productDate.type);
      }

      if (this.$feature(FeatureFlags.FULFILLMENT_FEE_QUANTITY)) {
        const numberOfInsertions =
          productDate.type === RateType.PER_QUANTITY.value
            ? 1
            : productDate.quantity;
        const fulfillmentFeeTotal = this.fulfillmentFee * numberOfInsertions;

        totalPrice += fulfillmentFeeTotal;
      }

      return totalPrice;
    },

    showFile(file) {
      const allowedTypes = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'tiff',
        'bmp',
        'mp4',
        'ogg',
        'webM'
      ];
      const type = getImageType(file);

      return allowedTypes.includes(type);
    }
  }
};
</script>
