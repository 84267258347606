<template>
  <div class="max-w-2xl mx-auto pt-4">
    <cart-query @data="cart = $event" />
    <ad-shop-product-query
      v-if="cart"
      :product-id="$route.params.product_id"
      @data="product = $event.adShop.product"
    />

    <transition name="fade" mode="out-in" duration="200">
      <ad-shop-product v-if="product" :product="product" :cart="cart" />
      <loader-image-content-columns v-else />
    </transition>
  </div>
</template>

<script>
import AdShopProduct from '@/components/AdShop/PageStoreProduct/AdShopProduct';
import { AdShopProductQuery, CartQuery } from '@/components/Queries';
import { LoaderImageContentColumns } from '@/components/Core/Loaders';

export default {
  components: {
    AdShopProduct,
    AdShopProductQuery,
    CartQuery,
    LoaderImageContentColumns
  },
  data() {
    return {
      cart: null,
      product: null
    };
  }
};
</script>
