<template>
  <section class="shop-header">
    <ad-shop-query :buyer-id="buyerId" @data="setAdShop">
      <template slot-scope="{ isLoading }">
        <transition name="fade" mode="out-in">
          <div v-if="isLoading" :key="'loading-shop-header'">
            <loader-box :height="11" />
            <div class="py-8 max-w-2xl mx-auto">
              <loader-heading :thickness="2.5" />
            </div>
          </div>
          <div v-else-if="adShop" :key="'loaded-shop-header'" class="result">
            <ad-shop-mutation :ad-shop="adShop">
              <template slot-scope="{ loading, updateAdShop }">
                <header-photo
                  :editable="isOwner"
                  :photo-file="adShop.headerPhotoFile"
                  :editing="editingStyle"
                  @edit-styling="editingStyle = true"
                  @cancel="onCancelEditingStyles"
                  @publish-changes="onPublishAdShop(updateAdShop, adShop)"
                  @edit-settings="editingSettings = true"
                  @change="onHeaderPhotoChange"
                />

                <shop-info
                  :editing="editingStyle"
                  :ad-shop="adShop"
                  rate-class="LOCAL"
                  @change="onShopInfoChange"
                />

                <settings-pane
                  v-if="editingSettings"
                  :ad-shop="adShop"
                  :loading="loading"
                  @cancel="editingSettings = false"
                  @save="onPublishAdShop(updateAdShop, $event)"
                />
              </template>
            </ad-shop-mutation>
          </div>
          <div v-else class="text-red font-bold text-center pad-xxl">
            Ad Shop was not found
          </div>
        </transition>
      </template>
    </ad-shop-query>
  </section>
</template>

<script>
import { FileUpload } from '@/utils/fileUpload.js';
import { cloneDeep } from 'lodash';

import HeaderPhoto from '@/components/AdShop/ShopHeader/HeaderPhoto/HeaderPhoto';
import SettingsPane from '@/components/AdShop/SettingsPane/SettingsPane';
import ShopInfo from '@/components/AdShop/ShopHeader/ShopInfo/ShopInfo';
import { AdShopMutation } from '@/components/Mutations';
import { AdShopQuery } from '@/components/Queries';
import { LoaderBox, LoaderHeading } from '@/components/Core/Loaders';

export default {
  components: {
    AdShopMutation,
    AdShopQuery,
    HeaderPhoto,
    LoaderBox,
    LoaderHeading,
    SettingsPane,
    ShopInfo
  },
  data() {
    return {
      editingSettings: false,
      editingStyle: false,
      adShop: null
    };
  },
  computed: {
    buyerId() {
      return this.$store.getters['auth/buyerId'];
    },
    currentSupplierId() {
      return this.$store.getters['auth/supplierId'];
    },
    isOwner() {
      if (this.adShop == null) {
        return false;
      }

      return this.adShop.supplier.id === this.currentSupplierId;
    }
  },
  methods: {
    onCancelEditingStyles() {
      this.editingStyle = false;
      this.adShop = cloneDeep(this.originalAdShop);
    },
    /**
     * @param updateAdShop
     * @param data current local state of AdShop settings
     */
    async onPublishAdShop(updateAdShop, data) {
      this.editingStyle = false;

      if (data.headerPhotoFile?.raw instanceof File) {
        const files = await new FileUpload(data.headerPhotoFile.raw).upload();
        data.header_photo_id = files[0].id;
      }

      if (data.profilePhotoFile?.raw instanceof File) {
        const files = await new FileUpload(data.profilePhotoFile.raw).upload();
        data.profile_photo_id = files[0].id;
      }

      await updateAdShop({
        ...data,
        supplier_id: this.currentSupplierId
      });

      this.editingSettings = false;

      this.$message.success('Your Ad Shop settings have been saved!');
    },
    setAdShop(data) {
      this.originalAdShop = data;
      this.adShop = cloneDeep(data);
      this.$store.commit('adShop/setAdShopThemeColor', this.adShop.theme_color);
    },

    onHeaderPhotoChange(file) {
      this.adShop.headerPhotoFile = file;
    },

    onShopInfoChange(data) {
      this.adShop = {
        ...this.adShop,
        ...data
      };

      if (this.adShop.theme_color) {
        this.$store.commit(
          'adShop/setAdShopThemeColor',
          this.adShop.theme_color
        );
      }
    }
  }
};
</script>
