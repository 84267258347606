<template>
  <div id="photo-upload-button">
    <el-upload
      :disabled="isDisabled"
      class="profile-photo-upload"
      action="/this-should-not-happen"
      :show-file-list="false"
      :on-change="change"
      :auto-upload="false"
    >
      <el-tooltip :disabled="!isDisabled" placement="bottom" effect="light">
        <template #content>
          <div class="w-40">
            <a
              href="https://intercom.help/flytedesk/articles/3102562-assigning-team-roles"
              target="_blank"
              >Learn more about user permission roles
            </a>
          </div>
        </template>
        <div
          v-if="!photoExists"
          class="add header-photo-button"
          :class="{ 'is-disabled': isDisabled }"
          @click="!isDisabled && $emit('editing')"
        >
          <el-button class="camera-button" circle>
            <icon :icon="cameraIcon" />
          </el-button>
          <div class="font-bold mt-2 text-sm text-white">Add Header Photo</div>
        </div>

        <el-popover
          v-else
          v-model="showPopover"
          popper-class="update-popover"
          @show="!isDisabled && $emit('editing')"
        >
          <div
            slot="reference"
            class="update header-photo-button"
            :class="{ 'is-disabled': isDisabled }"
          >
            <el-button class="camera-button" circle>
              <icon :icon="cameraIcon" />
            </el-button>
            <div class="label">Update Header Photo</div>
          </div>
        </el-popover>
      </el-tooltip>
    </el-upload>
  </div>
</template>

<script>
import { camera as cameraIcon } from '@/vendor/icons';

/* PhotoUploadButton
 *
 * Props:
 *   'photo-exists' used to switch between Update or Add
 *
 * Emits:
 *   'editing' when add/update/remove clicked
 *   'click-upload-photo' on add or update option clicked
 *   'header-photo-updated' on remove option clicked
 */
export default {
  name: 'PhotoUploadButton',
  props: {
    isDisabled: Boolean,
    photoExists: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      cameraIcon,
      showPopover: false
    };
  },
  methods: {
    change(file) {
      this.showPopover = false;
      this.$emit('change', file);
    }
  }
};
</script>

<style lang="scss">
.update-popover {
  width: auto;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}
</style>

<style scoped lang="scss">
@import '~@/scss/_variables';

.header-photo-button {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  /deep/ .camera-button {
    display: flex;
    margin: auto;
    background-color: white;
    color: $color-blue;
    padding: 0.5em;
    pointer-events: none;
  }

  .label {
    margin-top: 0.5em;
    color: white;
    font-size: 0.9em;
    font-weight: bold;
  }

  &.is-disabled {
    cursor: not-allowed;
  }
}
</style>
