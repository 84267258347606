<script>
import { merge } from 'lodash';
import { colors } from '@/tailwind';

import BaseChart from '@/components/Core/Charts/BaseChart';
import { ArcElement, DoughnutController, Legend, Tooltip } from 'chart.js';

export default {
  extends: BaseChart,
  data() {
    let textColor = colors['dark-silver'];

    return {
      chartType: 'doughnut',
      chartOptions: merge(
        {
          cutoutPercentage: 70,
          legend: {
            display: false
          },
          layout: {
            padding: 10
          },
          plugins: {
            tooltip: {
              backgroundColor: '#FFF',
              callbacks: {
                labelTextColor() {
                  return textColor;
                }
              },
              borderColor: 'rgba(0,0,0,.2)',
              borderWidth: 0,
              bodyFont: {
                color: textColor
              },
              titleFont: {
                color: textColor
              },
              footerFont: {
                color: textColor
              }
            }
          },
          responsive: true
        },
        this.options
      ),
      toRegister: [DoughnutController, ArcElement, Legend, Tooltip]
    };
  }
};
</script>
